import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, Grid, Typography, ListItem} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "./HgBilling.css";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter, Link } from "react-router-dom";
import SingleVenueBilling from "./component/SingleVenueBilling.js";
import VenueGroupBilling from "./component/VenueGroupBilling";
import EntertainerBilling from "./component/EntertainerBilling.js"
import AgentBilling from "./component/AgentBilling.js";
import { ProfileType } from "../../types/enum";
import Loading from "../../components/loadingSpinner/Loading"

const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("hgBillingStore", "profileStore", "bookingRequestStore")
@withRouter
@observer
class HgBillingV2 extends Component{
    constructor(props){
        super(props);
    };


    componentDidMount = () => {
      this.props.handleLoading(true)
        this.props.hgBillingStore.getCustomerAccess().then(() => {
          this.props.handleLoading(false)
        })
    }
    state = {

    };

   
    selectPlan = (value, type) => {
        this.props.hgBillingStore.postCheckout(value, type).then((response) => {
            window.open(response.url, '_system')
        })
    }
    selecFreePlan = () => {
      this.props.hgBillingStore.cancelSubscription().then(async () => {
        await this.loadAcessFunction()
        window.location.reload();
      })
    }
    loadAcessFunction = async () => {
      await this.props.hgBillingStore.loadAccess()
          this.props.bookingRequestStore.getBookingCountThisMonth()
      
  }

    render(){
        var currentProfile = this.props.profileStore.getCurrentProfile();
        return (
           <Fragment>
            {/* <Loading showed={this.props.isLoading} /> */}
            {currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.hasOrganisation ? 
             <SingleVenueBilling selecFreePlan={this.selecFreePlan} selectPlan={this.selectPlan}/>: null}
              {currentProfile?.type === ProfileType.Staff.ordinal && currentProfile?.hasOrganisation ? 
             <VenueGroupBilling selectPlan={this.selectPlan}/>: null}
                {currentProfile?.type === ProfileType.Agent.ordinal ? 
             <AgentBilling selecFreePlan={this.selecFreePlan} selectPlan={this.selectPlan}/>: null}
            {currentProfile?.type === ProfileType.Musician.ordinal ? 
             <EntertainerBilling selecFreePlan={this.selecFreePlan} selectPlan={this.selectPlan}/>: null}
             
           </Fragment>
        );
    }
}

export default withStyles(styles) (HgBillingV2);
