import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../img/powered_by_hg_logo.png";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import EmbedGigGridDetails from "./details/EmbedGigGridDetails"
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import moment from "moment";
import Grid from '@material-ui/core/Grid';
import popUpIcon from "../../img/popup-open-icon.png";
import "./EmbedGigGrid.css";
import { ProfileType } from "../../types/enum";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxText = 500;
const blankBackground = "/hero2.jpg";

import { Button,IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
@inject("profileStore", "bookingRequestStore", "reportStore","templateStore", "hgBillingStore")
@withRouter
@observer
class Embed extends Component {
    state = {
        profile: null,
        finishLoading: false, 
        performData: null,
        openDialogDetails: false,
        data: {},
        isFreePlan: false
    };

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
    }

    componentDidMount() {
        this.loadProfile();
        document.body.className = "bodyStyle"
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }
    loadProfile = () => {
        const param = this.props.match.params.id;

        this.props.reportStore.clearDateRangeFilter();
        // this.props.reportStore.setFilterType("week");
        this.props.hgBillingStore.getProfileAccess(param).then((access) => {
            
                this.props.profileStore.getPublicProfileById(param).then(result => {
                    if(!access || access?.isFreePlan){
                        this.setState({
                            ...this.state, 
                            isFreePlan: true,
                            profile: result
                        })
                    }else{
                        this.setState({
                            ...this.state, 
                            profile: result
                        })
                        
                        this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhocGigGuideEmbed(result.id).then((upcomings) => {
                            this.setState({
                                upcomings: upcomings, 
                                finishLoading: true,
                                profile: result, 
                                isFreePlan: false,
                                performData: this.processUpcomingGigs(upcomings)
                            });
                        });
                    }

                });
            }
        )
    };
    processUpcomingGigs = upcomingGigs => {
        let result = [];
        if (upcomingGigs && upcomingGigs.length > 0)
        {
            upcomingGigs = upcomingGigs.sort((a,b) => (a.dateStart > b.dateStart) ? 1 : ((b.dateStart > a.dateStart) ? -1 : 0))
            for (let i = 0; i < upcomingGigs.length; i++) {
                let dateStart = upcomingGigs[i].dateStart

                let data = {
                    eventId: upcomingGigs[i].id, 
                    year: dateStart.format("YYYY"), 
                    month: dateStart.format("MMMM"),
                    date: dateStart.format("D"),
                    day: dateStart.format('dddd').substring(0,3),
                    time:
                        moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                        " - " +
                        moment(upcomingGigs[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                    startTimeString:
                        moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma"),
                    title: upcomingGigs[i].title ? upcomingGigs[i].title : upcomingGigs[i].act.actName + '@' + upcomingGigs[i].venueName, 
                    venueName: upcomingGigs[i].venueName,
                    venueAddress: upcomingGigs[i].venueAddress,
                    bookingGenresString: upcomingGigs[i].bookingGenresString,
                    actGenreString: upcomingGigs[i].act && upcomingGigs[i].act.actGenres ? upcomingGigs[i].act.actGenres : [],
                    venueType: upcomingGigs[i].venue && upcomingGigs[i].venue.profileVenueTypes && upcomingGigs[i].venue.profileVenueTypes.map(profileVenueType => profileVenueType.venueType.name).join(', '), 
                    venueCover: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImagesNew ? upcomingGigs[i].venue.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    venueCoverAlt: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImages && upcomingGigs[i].venue.backgroundImages.length > 0 ? upcomingGigs[i].venue.backgroundImages[0].fileName : "Venue Cover", 
                    profileImageCover:  upcomingGigs[i].act && upcomingGigs[i].act.newProfileActImageAct ? upcomingGigs[i].act.newProfileActImageAct.url : BASE_URL + "hero2.jpg", 
                    profileImageCoverAlt:  upcomingGigs[i].act && upcomingGigs[i].act.newProfileActImageAct ? upcomingGigs[i].act.profileImage.fileName : "Act Profile", 
                    venueLatitude: upcomingGigs[i].venue && upcomingGigs[i].venue.latitude ? upcomingGigs[i].venue.latitude : 0, 
                    venueLongitude: upcomingGigs[i].venue && upcomingGigs[i].venue.longitude ? upcomingGigs[i].venue.longitude : 0, 
                    venueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? upcomingGigs[i].venue.bio : '', 
                    shortVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? this.truncateText(upcomingGigs[i].venue.bio) : '', 
                    expandableVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio && upcomingGigs[i].venue.bio.length > maxText ? true : false, 
                    expandVenueBio: false, 
                    bookTableUrl: upcomingGigs[i].venue && upcomingGigs[i].venue.bookTableUrl ? upcomingGigs[i].venue.bookTableUrl : "", 
                    ticketUrl: upcomingGigs[i].ticketUrl ? upcomingGigs[i].ticketUrl : "", 
                    actName: upcomingGigs[i].act.actName,
                    actGenres: upcomingGigs[i].act && upcomingGigs[i].act.profileGenres && upcomingGigs[i].act.profileGenres.map(profileGenre => profileGenre.genre.name).join(', '), 
                    actCover: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImagesNew ? upcomingGigs[i].act.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    actCoverAlt: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImages && upcomingGigs[i].act.backgroundImages.length > 0 ? upcomingGigs[i].act.backgroundImages[0].fileName : "Act Cover", 
                    actBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? upcomingGigs[i].act.bio : '',
                    actId: upcomingGigs[i].act && upcomingGigs[i].act.id ? upcomingGigs[i].act.id : '',
                    shortActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? this.truncateText(upcomingGigs[i].act.bio) : '', 
                    expandableActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio && upcomingGigs[i].act.bio.length > maxText ? true : false, 
                    expandActBio: false
                };
                result.push(data);
                
            }
            
        }
        return result
    };

    truncateText = text => {
        if(text.length > maxText) {
            return text.substring(0, maxText) + '...';
        }
        else {
            return text;
        }
    }

    manipulatePerformData = (index, type) => {
        const { performData } = this.state;
        let tempData = performData;

        if(type === 'act') {
            tempData[index].expandActBio = !tempData[index].expandActBio;
        }
        else {
            tempData[index].expandVenueBio = !tempData[index].expandVenueBio;
        }

        this.setState({
            ...this.state, 
            performData: tempData
        })
    }

    handleCloseDialogDetails = () => {
        this.setState({
            openDialogDetails: false,
            data: null
        })
    }
    handleOpenDialogDetails = (data) => {
        const widthScreen = window.innerWidth
        if(widthScreen > 720){
            this.setState({
                openDialogDetails: true,
                data: data
            })
        }else{
            window.open(`${BASE_URL}profile/${data?.actId}`)
        }
    }

    render() {
        const { profile, performData, isFreePlan } = this.state;

        const queryString = this.props.location.search;
        const params = new URLSearchParams(queryString);
        
        // const headerBgColor = params.get("headerBgColor") ? "#" + params.get("headerBgColor") : "#262626";
        // const headerFtColor = params.get("headerFtColor") ? "#" + params.get("headerFtColor") : "#fff";
        // const dateBgColor = params.get("dateBgColor") ? "#" + params.get("dateBgColor") : "#ff0c9c";
        // const dateFtColor = params.get("dateFtColor") ? "#" + params.get("dateFtColor") : "#000000";
        const tableBgColor = params.get("tableBgColor") ? "#" + params.get("tableBgColor") : "#ff0c9c";
        const tableFtColor = params.get("tableFtColor") ? "#" + params.get("tableFtColor") : "#fff";
        const hideToolbar = params.get("hideToolbar") || false;
        const enabledTransparent = params.get("enabledTransparent") || "false";
        const tableWidgetBgColor = params.get("tableWidgetBgColor") ? "#" + params.get("tableWidgetBgColor") : "transparent";

        // const hideOrderUber = params.get("hideOrderUber") || false;
        // const hideShareButton = params.get("hideShareButton") || false;
        const toolbarState = JSON.parse(hideToolbar);
        let user = "";

        if (profile) {
            user =
                profile.typeString === "act"
                    ? profile.actName
                    : profile.typeString === "agent"
                        ? profile.agencyName || profile.profileName
                        : profile.typeString === "venue"
                            ? profile.profileName
                            : profile.firstName + " " + profile.lastName;
        }
        return (
            <Fragment className={enabledTransparent === "true" ? "bodyStyle" : ""} style={{backgroundColor : enabledTransparent === "true" ? "" : tableBgColor }}>
                    <div style={{display:isFreePlan? "none" : "block"}} className={!toolbarState ? "gig-table-container-grid" : ""}>
                        <Grid  container justifyContent="space-between" m={1} spacing={1}>
                                {performData && performData.map((data, index) => {
                                    if(profile?.type === ProfileType.Act.ordinal || profile?.type == ProfileType.Musician.ordinal) {
                                        return (
                                                <Grid item xs={12} md={6} lg={3}  className="gridLayout">
                                                    <div className="gridItem">
                                                        <Card className="imageItem" onClick={() => this.handleOpenDialogDetails(data)}>
                                                            <CardActionArea>
                                                                <img className="imageStyle" src={profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueCover : data.profileImageCover ? data.profileImageCover : blankBackground} alt={profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueCoverAlt :data.profileImageCoverAlt}></img>
                                                                <div style={{backgroundColor: "red"}} className="topcorner">
                                                                    <div style={{fontSize:"19px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>{`${data.day.toUpperCase()} ${data.date}`}</div>
                                                                    <div style={{fontSize:"15px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>{data.month}</div>
                                                                    <div style={{fontSize:"11px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor,}}>{data.startTimeString}</div>

                                                                </div>
                                                                <div className="bottomText">
                                                                    <span style={{fontSize:"22px", fontFamily:"Raleway", fontWeight:"bold",marginBottom:5}}>{profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueName : data.actName}</span>
                                                                   
                                                                </div>
                                                                    <img className="bottomRightCorner" src={popUpIcon}
                                                                    alt="popup" width={30} height={30}></img>
                                                                <div className="bottomSection"></div>
                                                            </CardActionArea>
                                                        </Card>
                                                    </div>
                                                </Grid>
                                          
                                        );
                                    }
                                    else {
                                        return (
                                            <Grid item xs={12} md={6} lg={3} className="gridLayout">
                                                <div className="gridItem">
                                                    <Card className="imageItem" onClick={() => this.handleOpenDialogDetails(data)}>
                                                        <CardActionArea>
                                                            <img className="imageStyle" src={profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueCover : data.profileImageCover ? data.profileImageCover : blankBackground} alt={profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueCoverAlt : data.profileImageCoverAlt}></img>
                                                            <div className="topcorner" style={{backgroundColor: tableWidgetBgColor}}>
                                                                <div style={{fontSize:"19px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor}}>{`${data.day.toUpperCase()} ${data.date}`}</div>
                                                                <div style={{fontSize:"15px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor}}>{data.month}</div>
                                                                <div style={{fontSize:"11px", fontFamily:"Raleway", fontWeight:"bold",color: tableFtColor}}>{data.startTimeString}</div>

                                                            </div>
                                                            <div className="bottomText">
                                                                <span style={{fontSize:"20px", fontFamily:"Raleway", fontWeight:"bold"}}>{profile.type === ProfileType.Musician.ordinal || profile.type === ProfileType.Act.ordinal ? data.venueName : data.actName}</span>
                                                               
                                                            </div>
                                                                <img className="bottomRightCorner" src={popUpIcon}
                                                                alt="popup" width={30} height={30}></img>
                                                            <div className="bottomSection"></div>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                            </Grid>
                                        );
                                    }
                                })}

                    </Grid>
                    </div>
                    <div style={{height:"100%", display:isFreePlan? "block": "none" }}>
                    <h2>This feature is only available with the Hot Giggity Pro Plan.</h2>   
                    </div>
                    <div className="embed-footer-grid">
                        <span>
                            <img className="imageFooter" onClick={() => window.open("https://hotgiggity.com", "_system")} alt="hotgiggity logo" src={Logo} height="40px" />
                        </span>
                    </div>
                 <EmbedGigGridDetails
                    data={this.state.data}
                    profile={this.state.profile}
                    bookingGenresString={this.state.data?.bookingGenresString}
                    actGenreString={this.state.data?.actGenreString}
                    open={this.state.openDialogDetails}
                    tableBgColor={tableBgColor}
                    tableFtColor={tableFtColor}
                    enabledTransparent={enabledTransparent}
                    tableWidgetBgColor={tableWidgetBgColor}
                    onClose={this.handleCloseDialogDetails}
                    fullWidth={true}
                    handleShare={this.handleShare}
                />
            </Fragment>
        );
       
    }
}

export default compose(withWidth())(Embed);
