import React, { Component, Fragment } from "react";
import { Link,Redirect } from "react-router-dom";

import { inject, observer } from "mobx-react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "../../components/modal/ConfirmationModal.js";

import XEROIcon from "../../img/xero_logo.png";
import XeroComponent from "./component/xeroComponent.js"
import MyobComponent from "./component/myobComponent.js"
import xero_agent from "../../xero_agent.js";
import { Grid, Button, Tooltip,Divider } from "@material-ui/core";

import jwtDecode from "jwt-decode";

import "./AccountIntegration.css";
const MYOB_KEY = process.env.REACT_APP_MYOB_KEY;
const MYOB_REDIRECT_URL = process.env.REACT_APP_MYOB_REDIRECT_URL;

const XERO_KEY = process.env.REACT_APP_XERO_CLIENT_ID;
const XERO_REDIRECT_URL = process.env.REACT_APP_XERO_REDIRECT_URI;

const QUICKBOOKS_CLIENT_ID = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
const QUICKBOOKS_REDIRECT_URL = process.env.REACT_APP_QUICKBOOKS_REDIRECT_URL;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ENABLE_QUICKBOOKS = process.env.REACT_APP_ENABLE_QUICKBOOKS === "true";
import Loading from "../../components/loadingSpinner/Loading";
import Select from "react-select";
import { ProfileType } from "../../types/enum.js";
import { withRouter } from "react-router-dom";

@inject("userStore", "profileStore", "templateStore", "commonStore", "googleCalendarStore","myobStore", "hgBillingStore")
@withRouter
@observer
class AccountIntegrations extends Component {
    state = {
        selectedOption: "",
        options: [],
        showCalendar: false,
        googleCalendarActive: false,
        showUnlinkModal: false,
        showSubmitCalendar: false,
        panelMYOBExpanded: false,
        panelXEROExpanded: false,
        panelGoogleExpanded: false,
        myobActive:false,
        myobAccountName: "",
        showStep3Myob: false,
        showMyobDisconnectModal: false,
        selectCompanyPhase: false,
        selectedMYOBCompany : "",
        selectedMYOBOptions: [],
        showStep3XERO: false,
        XEROActive : false,
        isOrganisationOwner: false,
        hasOrganisation: false
    };

    componentDidMount = () => {
        var options = [];
        var selectedOptions = "";
        const { googleCalendarCode } = this.props.googleCalendarStore;
        const { myobToken, companyUrl } = this.props.myobStore;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        var isOrganisationOwner = currentProfile?.isOwnOrganization
      
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        if(currentProfile && currentProfile?.activateMYOBAccess && (this.props.hgBillingStore.billingAccess?.isProPlan)){   
            if(!currentProfile?.myobCompanyUri){    
                this.props.myobStore.getMYOBListCompany(currentProfile?.id).then(result => {
                    this.setState({
                        ...this.state,
                        selectCompanyPhase: true,
                        selectedMYOBOptions : this.props.myobStore.companyUrl,
                        selectedMYOBCompany : currentProfile?.myobCompanyUri,
                        myobActive:true,
                        myobAccountName: currentProfile?.myobCompanyName,
                        showStep3Myob: true,
                        panelMYOBExpanded: true,
                        isOrganisationOwner : isOrganisationOwner,
                        hasOrganisation: currentProfile?.isOwnOrganization
                    });   
                })
               
            }else{
                var options = []
                options.push({ value: currentProfile?.myobCompanyUri, label: currentProfile?.myobCompanyName })
                this.setState({
                    ...this.state,
                    selectCompanyPhase: false,
                    selectedMYOBOptions : options,
                    selectedMYOBCompany : currentProfile?.myobCompanyUri,
                    myobActive:true,

                    myobAccountName: currentProfile?.myobCompanyName,
                    showStep3Myob: true,
                    panelMYOBExpanded: false,
                    isOrganisationOwner : isOrganisationOwner ,
                    hasOrganisation: currentProfile?.isOwnOrganization
                });
            }
            // if(myobToken || !(currentProfile?.outGoingInvoiceMYOB &&
            //     currentProfile?.inGoingInvoiceMYOB)){
            //     this.setState({
            //         ...this.state,
            //         myobActive:true,
            //         myobAccountName: currentProfile?.myobCompanyName,
            //         showStep3Myob: true
            //     });
            // }else{
            //     this.setState({
            //         ...this.state,
            //         myobActive:true,
            //         myobAccountName: currentProfile?.myobCompanyName,
            //         showStep3Myob: true
            //     });
            // }
         
        }else{
            this.setState({
                ...this.state,
                myobActive:false,
                myobAccountName: "",
                showStep3Myob: false,
                isOrganisationOwner : isOrganisationOwner ,
                hasOrganisation: currentProfile?.isOwnOrganization
            });
        }
        
        // this.props.userStore.getQuickBooks();
    };

    expandMYOB = () => {
        const {panelMYOBExpanded} =this.state
        if(this.props.hgBillingStore.billingAccess?.isProPlan){
            this.setState({
                ...this.state,
                panelMYOBExpanded: !panelMYOBExpanded,
            });
        }else{
            this.props.redirectMembership();
        }
       
    }

    expandXERO = () => {
        const {panelXEROExpanded} =this.state
        if(this.props.hgBillingStore.billingAccess?.isProPlan){
            this.setState({
                ...this.state,
                panelXEROExpanded: !panelXEROExpanded,
            });
        }else{
            this.props.redirectMembership();
        }
       
    }
    handleConnectXero = async () => {
        await xero_agent.auth.connect().then((result) => {
            console.log(result)
        })
    }
    handleOpenMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: true });
    handleCloseMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: false });
    handleDisconnectMYOB = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        this.props.handleLoading(true)
        this.props.myobStore.disconnect(currentProfile?.id).finally(() => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.handleLoading(false)
                this.setState({
                    ...this.state,
                    showMyobDisconnectModal: false,
                    showStep3Myob: false,
                    myobActive: false
                });
            })
         
        });
    }
    handleChangeMYOBCompany= selectedOption => {
        this.setState({ selectedMYOBCompany : selectedOption.value });
    };
    handleSelectCompany = () => {
        const { selectedMYOBOptions, selectedMYOBCompany  } = this.state
        this.props.handleLoading(true)
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        var selectedOptions = selectedMYOBOptions.filter(x=>x.value === selectedMYOBCompany)[0]

        this.props.myobStore
        .handleSelectCompany({ profileId:currentProfile?.id,myobCompanyUri: selectedOptions.value, myobCompanyName: selectedOptions.label})
        .finally(response => {
                this.props.profileStore.loadProfiles().finally(() => {
                    this.props.handleLoading(false)
                    this.setState({
                        selectCompanyPhase: false,
                        selectedMYOBOptions : [selectedOptions],
                        selectedMYOBCompany : selectedOptions.value
                    });
                })
        });
    }
    render() {
        const { currentUser } = this.props.userStore;
        const { currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        const { isOrganisationOwner,hasOrganisation } = this.state;
        let hasSubscribe = false;
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentProfile?.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
            <Fragment>
                 {
                         ( process.env.REACT_APP_ENABLE_MYOB !== "true") &&
                                    <Grid item md={12} style={{ marginTop: 40, textAlign: "center" }}>Coming soon</Grid>
                 }
                  {
                         ((currentProfile?.type === ProfileType.Staff.ordinal && currentProfile?.hasOrganisation && !currentProfile?.isOwnOrganization && !currentProfile?.allowInvoices) && ( process.env.REACT_APP_ENABLE_MYOB === "true" && this.props.hgBillingStore.billingAccess?.isProPlan)) &&
                                    <Grid item md={12} style={{ marginTop: 40, textAlign: "center",fontSize:"1.1rem" }}>Pro integrations are only accessible by the venue account owner.</Grid>
                 }
                {/* {
                       (currentProfile?.type === ProfileType.Musician.ordinal) && process.env.REACT_APP_GOOGLE_CALENDAR_ACTIVATE === "true" &&
                       (
                        <Grid item md={12} style={{ marginTop: 25 }}>
                            <Grid container spacing={0}>
                            
                                <GoogleCalendarComponent handleLoading={this.props.handleLoading}/>
                            </Grid>
                          
                            
                            
                        </Grid>
                        
                       )
                } */}
              {
                   ((currentProfile?.type === ProfileType.Staff.ordinal && (isOrganisationOwner || !hasOrganisation)) || (currentProfile?.type === ProfileType.Agent.Staff && !currentProfile?.isConsultant))  && !this.props.hgBillingStore.billingAccess?.isProPlan &&  process.env.REACT_APP_ENABLE_MYOB === "true" ?
          
                   <Typography variant="headline" component="h3">
                                       Integrations are only available with the {currentProfile?.type === ProfileType.Musician.ordinal ? "Entertainer" : currentProfile?.type === ProfileType.Agent.ordinal ? "Agent" : "Staff" } Pro plan   <Button variant="contained" style={{ marginBottom: 5}} color="primary" onClick={this.props.redirectMembership}>
                            Upgrade to Access
                        </Button>
                    </Typography> : null
            }
            
            {
                currentProfile?.type === ProfileType.Agent.ordinal && !currentProfile?.isConsultant && !currentProfile?.activateXeroAccess  &&  <MyobComponent handleLoading={this.props.handleLoading}/>
               
            
                
            }

            {
                (isOrganisationOwner || !hasOrganisation || currentProfile?.type != ProfileType.Staff.ordinal) && !currentProfile?.activateXeroAccess  && currentProfile?.type != ProfileType.Agent.ordinal  &&  <MyobComponent handleLoading={this.props.handleLoading}/>
               
            
                
            }

            {   
                    currentProfile?.type == ProfileType.Agent.ordinal && !currentProfile?.isConsultant &&  <XeroComponent handleLoading={this.props.handleLoading}/>
            }

{   
                    (isOrganisationOwner || !hasOrganisation || currentProfile?.type != ProfileType.Staff.ordinal) && !currentProfile?.activateMYOBAccess  &&  currentProfile?.type != ProfileType.Agent.ordinal && <XeroComponent handleLoading={this.props.handleLoading}/>
            }

            {
                   !isOrganisationOwner && hasOrganisation &&currentProfile?.type === ProfileType.Staff.ordinal ?
          
                   <div style={{ marginTop: 40, textAlign: "center",fontSize:"1.1rem" }}>
                          Pro integrations are only accessible by the venue account owner.
                   </div> : null
            }

             {
                    currentProfile?.type === ProfileType.Agent.ordinal && currentProfile?.isConsultant ?
          
                   <div style={{ marginTop: 40, textAlign: "center",fontSize:"1.1rem" }}>
                          Pro integrations are only available to the agency owner.
                   </div> : null
            }

            </Fragment>

            
        );
    }
}

export default AccountIntegrations;
