import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import SidebarUser from "./sidebar/SidebarUser.js";
import SidebarMenu from "./sidebar/SidebarMenu.js";
import moment from "moment";
import { Drawer, Hidden, ListItem, ListItemSecondaryAction, IconButton, List, Link, Tooltip, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import logo from "../img/hotgig-app-logo.png";
import { ProfileType } from "../types/enum";
import PrivateBookingOptionModal from "../components/adhocBooking/PrivateBookingOptionModal";
import "./Sidebar.css";
import { withRouter } from 'react-router-dom';
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const GIGPROMOTION_FEATURE = process.env.REACT_APP_GIGPROMOTION_FEATURE === "true";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
const ENABLE_XERO = process.env.REACT_APP_ENABLE_XERO === "true";
@inject("templateStore", "profileStore", "hgBillingStore","bookingRequestStore", "notificationStore", "conversationStore", "userStore")
@observer
// @withRouter
class Sidebar extends Component {
    templateStore = this.props.templateStore;
    constructor(props) {
        super(props);
        this.state = {
            openOptionModal: false,
            open: false,
            selectedAct: undefined,
            selectedVenue: undefined,
            events: [],
            openEventDialog: false,
            isEditEvent: false,
            isLoading: true,
            slotInformation: undefined
        };
    }
   
    handleDrawerToggle = () => {
        this.templateStore.toggleSidebar();
    };
    handleClickPrivateBooking = () => {
        if (this.props.profileStore.currentProfile?.type === ProfileType.Musician.ordinal || this.props.profileStore.currentProfile?.type === ProfileType.Agent.ordinal) {
            //window.location.href = `/privateBooking?date=${moment(new Date()).format("DD-MM-YYYY")}&act=`
            this.props.history.push(`/privateBooking?date=${moment(new Date()).format("DD-MM-YYYY")}&act=`)
        } else if (this.props.profileStore.currentProfile?.type === ProfileType.Staff.ordinal || this.props.profileStore.currentProfile?.type === ProfileType.Staff.ordinal){
            this.props.history.push(`/booking/new?actId=undefined&venueId=undefined&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`);
           // window.location.href = `/booking/new?actId=undefined&venueId=undefined&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`
        } else {
           // window.location.href = `/privateBooking`;
            this.props.history.push(`/privateBooking`);
        }
    };

    handleClickSlot = () => {
        if(!this.props.bookingRequestStore.limitBookingFeature) return
        const { currentProfile } = this.props.profileStore;
      
        if (currentProfile && currentProfile?.type === ProfileType.Musician.ordinal) {
            this.props.profileStore.getUserTotalActList(currentProfile?.id)
        }
        this.setState({
            ...this.state,
            openOptionModal: true
        });
    };
    handleClickVenueBooking = () => {
        if (this.props.profileStore.currentProfile?.type === ProfileType.Musician.ordinal || this.props.profileStore.currentProfile?.type === ProfileType.Staff.ordinal || this.props.profileStore.currentProfile?.type === ProfileType.Agent.ordinal){
            //window.location.href = `/booking/new?actId=undefined&venueId=undefined&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`;
            this.props.history.push(`/booking/new?actId=undefined&venueId=undefined&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`);
            
        } else {
            //window.location.href = `/privateBooking`;
            this.props.history.push(`/privateBooking`);
        }
    };
    handleClickPrivateEvent = () => {
        const { slotInformation } = this.state;
        if (slotInformation) {
            this.props.eventStore.reset();

            let start = slotInformation.start;
            let end = slotInformation.end;
            if (start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0) {
                this.props.eventStore.setAllDay(true);
            } else {
                this.props.eventStore.setAllDay(false);
            }

            this.props.eventStore.setFrom(slotInformation.start);
            this.props.eventStore.setTo(slotInformation.end);
            this.props.eventStore.setProfileId(this.props.profileStore.currentProfile?.id);
            this.setState({
                ...this.state,
                openEventDialog: true,
                isEditEvent: false,
                openOptionModal: false,
                slotInformation: undefined
            });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleCloseOptionModal = () => {
        this.setState({
            ...this.state,
            openOptionModal: false,
            slotInformation: undefined
        });
    };
    render() {
        const { currentProfileType, profile, currentProfile, actListTotal} = this.props.profileStore;
        const { paymentAccesses } = this.props.hgBillingStore;
        const { notifications, unread } = this.props.notificationStore;
        const { unreadMessage } = this.props.conversationStore;
        const { currentUser } = this.props.userStore;
        const type = currentProfile && currentProfile?.type ? currentProfile?.type : 1;
        const createBooking = (
            <PrivateBookingOptionModal
            open={this.state.openOptionModal}
            onClose={this.handleCloseOptionModal}
            privateEventHandler={this.handleClickPrivateEvent}
            privateBookingHandler={this.handleClickPrivateBooking}
            handleClickVenueBooking={this.handleClickVenueBooking}
            type={type}
            hidePrivateBooking={false}
            hideManageAvailability={true}
            hideActBooking={actListTotal > 0 ? false : true}
            />  
        )
        let agentMenus = [
            { icon: "mh-wave", name: "Dashboard", key: "dashboard", link: "/agent" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa fa-calendar-check", name: "Booking Station", link: "/booking", key: "booking" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/agent/gig", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: (currentProfile?.isConsultant ? "My Profile" :"My Agency Profile "), link: "/agent/edit", key: "myProfile" },

                    currentProfile && currentProfile?.isConsultant
                        ? {
                            icon: "fa fa-sitemap",
                            name: "My Agency",
                            link: "/agent/agency",
                            key: "agency"
                        }
                        : undefined,

                        currentProfile && !currentProfile?.isConsultant
                        ? {
                            icon: "fa fa-sitemap",
                            name: "My Consultants",
                            link: "/agent/consultant",
                            key: "consultant"
                        }
                        : undefined,

                    { icon: "mh-band", name: "My Act Roster", link: "/agent/actroster", key: "manage_actRoster" },
                    { icon: "fa fa-building", name: "My Venue Roster", link: "/agent/venues", key: "venues" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                    ENABLE_MYOB && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "MYOB Settings",
                        link: "/myobSetting",
                        key: "myobSetting"
                    }
                    : undefined,
                    ENABLE_XERO && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateXeroAccess && currentProfile.xeroTenantId
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "XERO Settings",
                        link: "/xeroSetting",
                        key: "xeroSetting"
                    }
                    : undefined,
                ]
            },
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/agent/calendar" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            // { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
            // { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
            // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
            ENABLE_SUBSCRIPTION && paymentAccesses != null && paymentAccesses.hasAgentPaymentAccess
                ? {
                    icon: "fa fa-tasks",
                    name: "Billing",
                    subMenu: [
                        { icon: "fa fa-dollar-sign", name: "Payment", key: "billing", link: "/billing/payment" },
                        { icon: "fa fa-dollar-sign", name: "Payment History", key: "paymentHistory", link: "/billing/paymentHistory" }
                    ]
                }
                : undefined,
            {
                icon: "fa fa-bullhorn",
                name: "Promote",
                color: "#65BCFF",
                subMenu: [
                    { icon: "fa fa-bullhorn", name: "Social Posts", key: "socialposts", link: "/socialposts" },
                ]
            },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
        ];
        let agentMenuFree = [
            { icon: "mh-wave", name: "Dashboard", key: "dashboard", link: "/agent" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa fa-calendar-check", name: "Booking Station", link: "/booking", key: "booking" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/agent/gig", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: (currentProfile?.isConsultant ? "My Profile" :"My Agency Profile "), link: "/agent/edit", key: "myProfile" },

                    currentProfile && currentProfile?.isConsultant
                        ? {
                            icon: "fa fa-sitemap",
                            name: "My Agency",
                            link: "/agent/agency",
                            key: "agency"
                        }
                        : undefined,

                        currentProfile && !currentProfile?.isConsultant
                        ? {
                            icon: "fa fa-sitemap",
                            name: "My Consultants",
                            link: "/agent/consultant",
                            key: "consultant"
                        }
                        : undefined,

                    { icon: "mh-band", name: "My Act Roster", link: "/agent/actroster", key: "manage_actRoster" },
                    { icon: "fa fa-building", name: "My Venue Roster", link: "/agent/venues", key: "venues" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                    ENABLE_MYOB && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "MYOB Settings",
                        link: "/myobSetting",
                        key: "myobSetting"
                    }
                    : undefined,
                    ENABLE_XERO && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateXeroAccess && currentProfile.xeroTenantId
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "XERO Settings",
                        link: "/xeroSetting",
                        key: "xeroSetting"
                    }
                    : undefined,
                ]
            },
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/agent/calendar" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
        ];

        let venueMenus = [
            
            { icon: "mh-wave", name: "Dashboard", key: "dashboard", link: "/staff" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            currentProfile && currentProfile?.allowBooking ?
            { icon: "fa fa-calendar-check", name: "Booking Station", link: "/booking", key: "booking" }
            : undefined,
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/staff/gig", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            currentProfile && currentProfile?.hasOrganisation ?
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Personal Profile", link: "/staff/edit", key: "myProfile" },
                    { icon: "fa fa-sitemap", name: "My Organisations", link: "/organisation", key: "organisation" },
                    { icon: "fa fa-building", name: "My Venues", link: "/venues", key: "venues" },
                    { icon: "mh-band", name: "My Act Roster", link: "/staff/acts", key: "manage_actRoster" },
                    { icon: "fa fas fa-user-secret", name: "My Agents", link: "/staff/agents", key: "myAgents" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                    ENABLE_MYOB && this.props.hgBillingStore.billingAccess?.isProPlan && ((currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri))
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "MYOB Settings",
                        link: "/myobSetting",
                        key: "myobSetting"
                    }
                    : undefined,
                    ENABLE_XERO && this.props.hgBillingStore.billingAccess?.isProPlan && ((currentProfile && currentProfile?.activateXeroAccess))
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "XERO Settings",
                        link: "/xeroSetting",
                        key: "xeroSetting"
                    }
                    : undefined,
                ]
            }
            :  {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Personal Profile", link: "/staff/edit", key: "myProfile" },
                    { icon: "fa fa-building", name: "My Venues", link: "/venues", key: "venues" },
                    { icon: "mh-band", name: "My Act Roster", link: "/staff/acts", key: "manage_actRoster" },
                    { icon: "fa fas fa-user-secret", name: "My Agents", link: "/staff/agents", key: "myAgents" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                    ENABLE_MYOB && this.props.hgBillingStore.billingAccess?.isProPlan && (currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri)
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "MYOB Settings",
                        link: "/myobSetting",
                        key: "myobSetting"
                    }
                    : undefined,
                    ENABLE_XERO && this.props.hgBillingStore.billingAccess?.isProPlan && (currentProfile && currentProfile?.activateXeroAccess && currentProfile.xeroTenantId)
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "XERO Settings",
                        link: "/xeroSetting",
                        key: "xeroSetting"
                    }
                    : undefined,
                ]
            },
           
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/staff/calendar" },
            currentProfile && currentProfile?.allowInvoices ?
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" }
            : undefined,
            // { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
            // { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
            // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
            currentProfile && currentProfile?.allowPromote
            ?  
            {
                icon: "fa fa-bullhorn",
                name: "Promote",
                color: "#65BCFF",
                subMenu: [
                    { icon: "fa fa-bullhorn", name: "Venue Promotions", key: "promotion", link: "/promotion" },
                    { icon: "fa fa-bullhorn", name: "Social Posts", key: "socialposts", link: "/socialposts" },
                    GIGPROMOTION_FEATURE ? { icon: "fa fa-bullhorn", name: "Gig Promotions", key: "gigPromotion", link: "/gig/promotion" } : undefined
                ]
            }
            : undefined,
            
            ENABLE_SUBSCRIPTION && paymentAccesses != null && paymentAccesses.hasVenuePaymentAccess
                ? {
                    icon: "fa fa-tasks",
                    name: "Billing",
                    subMenu: [
                        { icon: "fa fa-dollar-sign", name: "Payment", key: "billing", link: "/billing/payment" },
                        { icon: "fa fa-dollar-sign", name: "Payment History", key: "paymentHistory", link: "/billing/paymentHistory" }
                    ]
                }
                : undefined,
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
       ];

       let venueMenusFree = [
            
        { icon: "mh-wave", name: "Dashboard", key: "dashboard", link: "/staff" },
        { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
        currentProfile && currentProfile?.allowBooking ?
        { icon: "fa fa-calendar-check", name: "Booking Station", link: "/booking", key: "booking" }
        : undefined,
        { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/staff/gig", key: "myGigs" },
        { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
        { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
        currentProfile && currentProfile?.hasOrganisation ?
        {
            icon: "fa fa-tasks",
            name: "Manage",
            color: "#C62DEE",
            subMenu: [
                { icon: "fa fa-user", name: "My Personal Profile", link: "/staff/edit", key: "myProfile" },
                { icon: "fa fa-sitemap", name: "My Organisations", link: "/organisation", key: "organisation" },
                { icon: "fa fa-building", name: "My Venues", link: "/venues", key: "venues" },
                { icon: "mh-band", name: "My Act Roster", link: "/staff/acts", key: "manage_actRoster" },
                { icon: "fa fas fa-user-secret", name: "My Agents", link: "/staff/agents", key: "myAgents" },
                { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" }
            ]
        } :
        {
            icon: "fa fa-tasks",
            name: "Manage",
            color: "#C62DEE",
            subMenu: [
                { icon: "fa fa-user", name: "My Personal Profile", link: "/staff/edit", key: "myProfile" },
                { icon: "fa fa-building", name: "My Venues", link: "/venues", key: "venues" },
                { icon: "mh-band", name: "My Act Roster", link: "/staff/acts", key: "manage_actRoster" },
                { icon: "fa fas fa-user-secret", name: "My Agents", link: "/staff/agents", key: "myAgents" },
                { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" }
            ]
        },
       
        { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/staff/calendar" },
        currentProfile && currentProfile?.allowInvoices ?
        { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" }
        : undefined,
        // { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
        // { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
        // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
        currentProfile && currentProfile?.allowPromote
        ?  
        {
            icon: "fa fa-bullhorn",
            name: "Promote",
            color: "#65BCFF",
            subMenu: [
                { icon: "fa fa-bullhorn", name: "Venue Promotions", key: "promotion", link: "/promotion" },
                // { icon: "fa fa-bullhorn", name: "Social Posts", key: "socialposts", link: "/socialposts" },
                GIGPROMOTION_FEATURE ? { icon: "fa fa-bullhorn", name: "Gig Promotions", key: "gigPromotion", link: "/gig/promotion" } : undefined
            ]
        }
        : undefined,
        
      
        { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
   ];

        let musicianMenus = [
            { icon: "mh-wave", name: "Dashboard", link: "/musician", key: "dashboard" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/musician/gig", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":""},
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Personal Profile", link: "/musician/edit", key: "myProfile" },
                    { icon: "fa fa-users", name: "My Acts", link: "/myact", key: "myActs" },
                    { icon: "fa fas fa-music", name: "My Song List", link: "/musician/songlist", key: "mySongList" },
                    { icon: "fa fas fa-list-alt", name: "My Set Lists", link: "/musician/setlist", key: "mySetLists" },
                    { icon: "fa fas fa-user-secret", name: "My Agents", link: "/musician/agents", key: "myAgents" },
                    { icon: "fa fa-building", name: "My Venues", key: "venues", link: "/musician/venues" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                    ENABLE_MYOB && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateMYOBAccess && currentProfile?.myobCompanyUri
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "MYOB Settings",
                        link: "/myobSetting",
                        key: "myobSetting"
                    }
                    : undefined,
                    ENABLE_XERO && this.props.hgBillingStore.billingAccess?.isProPlan && currentProfile && currentProfile?.activateXeroAccess && currentProfile.xeroTenantId
                    ? {
                        icon: "fa fa-hand-holding-dollar",
                        name: "XERO Settings",
                        link: "/xeroSetting",
                        key: "xeroSetting"
                    }
                    : undefined,
                ]
            },
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/musician/calendar" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            // { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
            // { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
            // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
            {
                icon: "fa fa-bullhorn",
                name: "Promote",
                color: "#65BCFF",
                subMenu: [
                    { icon: "fa fa-bullhorn", name: "Social Posts", key: "socialposts", link: "/socialposts" },  
                ]
            },
            ENABLE_SUBSCRIPTION
                ? {
                    icon: "fa fa-tasks",
                    name: "Billing",
                    subMenu: [
                        { icon: "fa fa-dollar-sign", name: "Payment", key: "billing", link: "/billing/payment" },
                        { icon: "fa fa-dollar-sign", name: "Payment History", key: "paymentHistory", link: "/billing/paymentHistory" }
                    ]
                }
                : undefined,
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
            { icon: "fa fa-solid fa-door-open", name: "Refer a Venue", key: "referVenue", link: "https://hotgiggity.com/refer-a-venue", self: true }
        ];

        let productionMenus = [
            { icon: "mh-wave", name: "Dashboard", link: "/production", key: "dashboard" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/production/booking", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Personal Profile", link: "/production/edit", key: "myProfile" },
                    { icon: "fa fa-users", name: "My Acts", link: "/myact", key: "myActs" },
                    // { icon: "fa fas fa-music", name: "My Song List", link: "/production/songlist", key: "mySongList" },
                    // { icon: "fa fas fa-list-alt", name: "My Set Lists", link: "/production/setlist", key: "mySetLists" },
                    // { icon: "fa fas fa-user-secret", name: "My Agents", link: "/production/agents", key: "myAgents" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" }
                ]
            },
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/agent/booking" },
            //{ icon: "mh-microphone", name: "Venues", key: "venues" },
            //{ icon: "mh-band", name: "Musicians", key: "musicians" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
            { icon: "fa fa-edit", name: "Templates", key: "templates" },
            { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
            // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
             ];

        let djMenus = [
            { icon: "mh-wave", name: "Dashboard", link: "/dj", key: "dashboard" },
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/dj/booking", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Profile", link: "/dj/edit", key: "myProfile" },
                    { icon: "fa fa-users", name: "My Acts", link: "/myact", key: "myActs" },
                    { icon: "fa fas fa-music", name: "My Song List", link: "/dj/songlist", key: "mySongList" },
                    { icon: "fa fas fa-list-alt", name: "My Set Lists", link: "/dj/setlist", key: "mySetLists" },
                    { icon: "fa fas fa-user-secret", name: "My Agents", link: "/dj/agents", key: "myAgents" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" }
                ]
            },
            { icon: "fa fa-calendar", name: "Calendar", key: "calendar", link: "/agent/booking" },
            { icon: "mh-microphone", name: "Venues", key: "venues" },
            { icon: "mh-band", name: "Musicians", key: "musicians" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            { icon: "fa fa-file-alt", name: "Financial Reports", key: "reports", link: "/reports" },
            { icon: "fa fa-edit", name: "Templates", key: "templates" },
            { icon: "mh-guitar", name: "Hubtrade", key: "hubTrade", link: "/hubtrade" },
            // { icon: "fa fa-umbrella", name: "Insurance", key: "insurance", link: "/insurance" },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
        ];

        let adminMenus = [
            { icon: "mh-wave", name: "Dashboard", link: "/admin", key: "dashboard" },
            { icon: "fa fa-users", name: "Users",link: "/admin/users", key: "users" },
            { icon: "fa fa-users", name: "Legacy Users",link: "/admin/legacy", key: "legacyusers" },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true }
        ];

        let privateHireMenus = [
            { icon: "fa fa-search", name: "Connect (Search)", key: "search", link: "/search", color: "#F50D9A" },
            { icon: "fa fa-users", name: "My Favourite Acts", link: "/myact", key: "myActs" },
            { icon: "fa-solid fa-microphone", name: "My Gigs", link: "/privatehire/gig", key: "myGigs" },
            { icon: "fa fa-envelope", name: "Messages", link: "/messages", key: "messages", showBadge: unreadMessage > 0, badgeCount: unreadMessage, color:unreadMessage > 0?"white":"" },
            {
                icon: "fa fa-tasks",
                name: "Manage",
                color: "#C62DEE",
                subMenu: [
                    { icon: "fa fa-user", name: "My Personal Profile", link: "/privatehire/edit", key: "myProfile" },
                    { icon: "fa fa-cog", name: "Account Settings", link: "/account/setting", key: "accountSetting" },
                ]
            },
            { icon: "fa fa-bell", name: "Notifications", link: "/notifications", key: "notifications", showBadge: unread > 0, badgeCount: unread, color:unread > 0?"white":"" },
            { icon: "fa fa-dollar-sign", name: "Invoices", key: "invoices", link: "/invoice" },
            { icon: "fa fa-question-circle", name: "Support", key: "support", link: "https://hotgiggity.freshdesk.com/support/home", self: true },
       ];

        let renderMenus = () => {
            let menus = [];
            
            if (currentProfileType === 1) {
                menus = musicianMenus;
            }
       
            if(currentProfileType === 2 && (currentProfile?.isFreeUser || currentProfile?.isProPlan || this.props.hgBillingStore.billingAccess?.isBasicPlan || this.props.hgBillingStore.billingAccess?.isProPlan)){
                menus = agentMenus;
            }else if(currentProfileType === 2){
                menus = agentMenuFree;
            }
            
            if(currentProfileType === 3 && (currentProfile?.isFreeUser || currentProfile?.isProPlan || this.props.hgBillingStore.billingAccess?.isBasicPlan || this.props.hgBillingStore.billingAccess?.isProPlan)){
                menus = venueMenus;
            }else if(currentProfileType === 3){
                menus = venueMenusFree;
            }
            
            if (currentProfileType === 6) {
                menus = productionMenus;
            }
            if (currentProfileType === 7) {
                menus = djMenus;
            }
            if(currentProfileType === 8){
                menus = adminMenus;
            }
            if(currentProfileType === 9){
                menus = privateHireMenus
            }


           
            return menus.filter(x => x && x.name).map((menu, index) => {
                if (menu.key === this.props.templateStore.getActiveSideBarKey()) {
                    menu.active = false;
                   
                }

                if (menu.subMenu && menu.subMenu.length > 0) {
                    menu.subMenu = menu.subMenu.filter(y => y !== null && y !== undefined).map(sub => {
                        if (sub.key === this.props.templateStore.getActiveSideBarKey()) {
                            sub.active = false;
                        }
                        return sub;
                    });
                }
                return <SidebarMenu data={menu} key={index} />;
            });
        };

        let trialComponent = () => {
            var trialEnd = moment.unix(this.props.hgBillingStore.billingAccess?.trial_end)
            if(!this.props.hgBillingStore.billingAccess?.priceId){
                trialEnd = moment.unix(this.props.hgBillingStore.billingAccess?.trial_end).add(new Date().getTimezoneOffset(), "minutes")
            }
            const duration = moment.duration(trialEnd.diff(moment()));

            //Get Days
            const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
            const daysFormatted = days ? `${days} days ` : ''; // if no full days then do not display it at all
          
            //Get Hours
            const hours = duration.hours();
            const hoursFormatted = `${hours}h `;
          
            //Get Minutes
            const minutes = duration.minutes();
            const minutesFormatted = `${minutes}m`;

            const hoursMinutes = `${hoursFormatted} ${minutesFormatted}`
            if(moment() < trialEnd){
                return (
                    <Typography style={{textAlign:"center"}}>{`Your free trial expires in ${days > 0 ? daysFormatted : hoursMinutes}`}</Typography>
                )
            }else{
                return null
            }
           
        }
        const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

        let mobileDrawer = (
            <SwipeableDrawer
                // variant="temporary"
                anchor="left"
                onOpen={() => { }}
                open={this.templateStore.showSidebarToggleButton}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                classes={{ paper: "drawer-temporary drawer-mobile" }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <ListItem divider={true} classes={{ divider: "sidebar-divider" }} style={{ marginTop: "-20px", height: "65px" }}>
                    <img src={logo} alt="hotgiggity" style={{ maxWidth: "120px" }} />
                    <ListItemSecondaryAction className="logo-drawer-action">
                        <IconButton aria-label="Comments" onClick={this.handleDrawerToggle}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {
                        currentUser?.status != 50 || !currentProfile || currentProfile?.isCompleted === 0 || (currentProfileType === 3 &&  ((!currentProfile?.hasOrganisation && this.props.hgBillingStore.billingAccess?.isBasicPlan) || (currentProfile?.hasOrganisation && !currentProfile?.isFreeUser && !currentProfile?.isProPlan))) ? 
                        null :   
                        <ListItem>
                            <Tooltip title={!this.props.bookingRequestStore.limitBookingFeature ? "You have reached your limit for creating private bookings on the current plan. To create more bookings, please upgrade your plan." : ""}>
                            <Link href="#" onClick={this.handleClickSlot}  style={{ margin: "auto", borderRadius: 100, backgroundColor: "#ff0c9c", opacity: !this.props.bookingRequestStore.limitBookingFeature ? 0.3: 1, color: "#fff", height: 30, padding: "8px 15px 30px 15px" }}>
                                <i class="fa fa-sm fa-plus"></i>&nbsp;Create Booking
                            </Link>
                            </Tooltip>
                        </ListItem>
                }
               
                {createBooking}
                {renderMenus()}
            </SwipeableDrawer>
        );
        let withoutBookingmobileDrawer = (
            <SwipeableDrawer
                // variant="temporary"
                anchor="left"
                onOpen={() => { }}
                open={this.templateStore.showSidebarToggleButton}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                classes={{ paper: "drawer-temporary drawer-mobile" }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <ListItem divider={true} classes={{ divider: "sidebar-divider" }} style={{ marginTop: "-20px", height: "65px" }}>
                    <img src={logo} alt="hotgiggity" style={{ maxWidth: "120px" }} />
                    <ListItemSecondaryAction className="logo-drawer-action">
                        <IconButton aria-label="Comments" onClick={this.handleDrawerToggle}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {renderMenus()}
            </SwipeableDrawer>
        );



        const desktopDrawer = (
            <Hidden smDown implementation="css">
                <Drawer variant="permanent" open classes={{ paper: "drawer-permanent", }}>
                    <List className="menu-li" style={{marginBottom:200}}>
                        <SidebarUser />
                       {trialComponent()}
                        
                        {
                            currentUser?.status != 50  || !currentProfile || currentProfile?.isCompleted === 0 ||  (currentProfileType === 3 &&  ((!currentProfile?.hasOrganisation && this.props.hgBillingStore.billingAccess?.isBasicPlan))) ? 
                            null :  <ListItem>
                                <Tooltip title={!this.props.bookingRequestStore.limitBookingFeature ? "You have reached your limit for creating private bookings on the current plan. To create more bookings, please upgrade your plan." : ""}>
                                        <Link href="#" onClick={this.handleClickSlot} style={{ cursor:!this.props.bookingRequestStore.limitBookingFeature ? "not-allowed": "auto", opacity: !this.props.bookingRequestStore.limitBookingFeature ? 0.4: 1, margin: "auto", borderRadius: 100, backgroundColor: "#ff0c9c", color: "#fff", height: 30, padding: "8px 15px 30px 15px" }}>
                                            <i class="fa fa-sm fa-plus"></i>&nbsp;Create Booking
                                        </Link>
                                </Tooltip>
                                    </ListItem>
                        }
                       
                        {createBooking}
                        {renderMenus()}
                    </List>
                </Drawer>
            </Hidden>
             
        );
        const withoutBookingDesktopDrawer = (
            <Hidden smDown implementation="css">
                <Drawer variant="permanent" open classes={{ paper: "drawer-permanent" }}>
                    <List style={{marginBottom:200}} className="menu-li">
                        <SidebarUser />
                        {renderMenus()}
                    </List>
                </Drawer>
            </Hidden>
        );
        return (
            <Fragment>
                {/* force the mobile menu for the public profile page */}
                {currentProfile && currentProfile?.allowBooking && this.props.forceMobile && mobileDrawer}
                {currentProfile && !currentProfile?.allowBooking && this.props.forceMobile && withoutBookingmobileDrawer}
                {/* default desktop menu */}
                {currentProfile && currentProfile?.allowBooking && !this.props.forceMobile && desktopDrawer}
                {currentProfile && !currentProfile?.allowBooking && !this.props.forceMobile && withoutBookingDesktopDrawer}
                {!currentProfile && window.location.pathname.includes("/new") && desktopDrawer}
                {!this.props.forceMobile && <Hidden mdUp>{mobileDrawer}</Hidden>}
            </Fragment>
        );
    }
}
export default withRouter(Sidebar);