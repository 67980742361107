import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Select, MenuItem, Grid } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import "./AgentFilter.css";
import { Promise } from "bluebird";
import SelectField from "../fields/SelectField";
import { BookingType } from "../../types/enum";

const dateFormat = "DD-MM-YYYY";
const monthFormat = "MMM YYYY";

@inject("profileStore", "venueStore", "agentFilterStore", "agentActStore", "agentVenueStore")
@observer
class AgentFilter extends Component {
    postChangeInterval = undefined;

    constructor(props) {
        super(props);
        this.state = {
            dashboardFilter: "monthly",
            showDateRangePicker: false,
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month"),
            venues: [],
            acts: []
        };
    }    

    componentDidMount() {
        let { currentProfile } = this.props.profileStore;
        if (!currentProfile) {
            currentProfile = this.props.profileStore.getCurrentProfile();
        }
        this.props.agentActStore.resetAgentActs();
        Promise.all([
            this.props.agentActStore.getAgentActRosterForMyGigs(currentProfile?.id),
            this.props.agentVenueStore.getAgentVenueForMyGigs(),
            this.props.agentActStore.getAgentMyActsForMyGigs(currentProfile?.id),
        ]).then( response => {
            let actRoster = [...response[0], ...response[2]];
            let venues = response[1];
            if(actRoster){
                this.mapActToSuggestions(actRoster);
            }
            if (venues) {
                this.mapVenuesToSuggestions(venues);
            }
        })
    }

    mapActToSuggestions = actRosters => {
        let acts = [];

        actRosters.forEach(actRoster => {
            if(!acts.find(x => x.id === actRoster.actId)){
                // console.log("gading", actRoster.act)
                acts.push(actRoster.act);
            }
        });

        acts.sort((a, b) => (a.actNameString < b.actNameString ? -1 : 1))

        this.setState({
            ...this.state,
            acts: acts
        })
    }

    mapVenuesToSuggestions = venueRosters => {
        let venues = [];
        venueRosters.forEach(venueRoster => {
            if(!venues.find(x => x.id === venueRoster.venueId)){
                venues.push(venueRoster.venue);
            }
        });

        venues.sort((a, b) => (a.profileNameString < b.profileNameString ? -1 : 1))

        this.setState({
            ...this.state,
            venues: venues
        })
    };

    postFilterChange = () => {
        const { venueFilter, startDate, endDate, actFilter,bookingType } = this.props.agentFilterStore;
        var filterData = {
            venue: venueFilter,
            startDate: startDate,
            endDate: endDate,
            act: actFilter,
            bookingType: bookingType
        };

        if (this.postChangeInterval) {
            clearInterval(this.postChangeInterval);
        }

        this.postChangeInterval = setInterval(() => {
            this.props.postChangeEvent(filterData);
            clearInterval(this.postChangeInterval);
        }, 1000);
    };

    onChange = e => {
        this.props.agentFilterStore.setFilterType(e.target.value);
        this.postFilterChange();
    };

    onChangeAct = value => {
        this.props.agentFilterStore.setActFilter(value);
        this.postFilterChange();
    }

    onChangeBookingType = value => {
        this.props.agentFilterStore.setBookingType(value);
        this.postFilterChange();
    }

    onVenueChange = e => {
        this.props.agentFilterStore.setVenueFilter(e.target.value);
        this.postFilterChange();
    };

    onChangeStartDate = value => {
        const { endDate } = this.props.agentFilterStore;
        this.props.agentFilterStore.setStartDate(value);
        if (moment(value).endOf("day") > moment(endDate).endOf("day")) {
            this.props.agentFilterStore.setEndDate(value);
        }

        this.postFilterChange();
    };

    onChangeEndDate = value => {
        const { startDate } = this.props.agentFilterStore;
        this.props.agentFilterStore.setEndDate(value);
        if (moment(value).endOf("day") < moment(startDate).endOf("day")) {
            this.props.agentFilterStore.setStartDate(value);
        }
        this.postFilterChange();
    };

    onChangeMonth = value => {
        const startDate = moment(value).date(1);
        this.props.agentFilterStore.setStartDate(startDate);

        const endDate = moment(value).date(moment(value).daysInMonth());
        this.props.agentFilterStore.setEndDate(endDate);

        this.postFilterChange();
    };

    mapActSuggestions = () => {
        const { acts } = this.state;
        let suggestions = [{
            value: 0,
            label: "All Acts"
        }];

        acts.forEach(act => {
            suggestions.push({
                value: act.id,
                label: act.actName
            })
        })

        return suggestions;
    }

    mapBookingType = () => {
        return [{
                value: undefined,
                label: "All"
            },{
                value: BookingType.Normal.ordinal,
                label: "Venue Booking"
            },{
                value: BookingType.Adhoc.ordinal,
                label: "Function Booking"
            }
        ];
    }

    render() {
        const { venues } = this.state;
        const { showActFilter } = this.props;
        const { filterType, startDate, endDate, venueFilter, actFilter,bookingType } = this.props.agentFilterStore;

        return (
            <Grid container spacing={8}>
                <Grid xs = { 12 } md = {3} item className="filterHalf">
                        <SelectField
                            suggestions={this.mapBookingType()}
                            onChange={this.onChangeBookingType}
                            value={bookingType}
                            placeholder="Select Booking Type"
                            color='#A9A9A9'
                            classes={{
                                root: "pull-right dark-select slim-input",
                                icon: "text-white",
                                input: "text-primary"
                            }}
                        />
                    </Grid>
                {
                  showActFilter ? (
                    <Grid xs = { 12 } lg = {3} item className="filterHalf">
                        <SelectField
                            suggestions={this.mapActSuggestions()}
                            onChange={this.onChangeAct}
                            value={actFilter}
                            placeholder="Select Act"
                            color='#A9A9A9'
                            classes={{
                                root: "pull-right dark-select slim-input",
                                icon: "text-white",
                                input: "text-primary"
                            }}
                        />
                    </Grid>
                  ) : null
                }
                {venues &&
                    venues.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Venue"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={venueFilter}
                                onChange={this.onVenueChange}
                                inputProps={{
                                    color: "#A9A9A9"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All Venues</MenuItem>
                                {venues.map(venue => (
                                    <MenuItem key={venue.id} value={venue.id}>
                                        {venue.profileName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                <Grid item className="filterFull">
                    <Select
                        label="Filter"
                        className="dark-select slim-input"
                        fullWidth
                        value={filterType}
                        onChange={this.onChange}
                        inputProps={{
                            color: "primary"
                        }}
                        classes={{
                            root: "text-white",
                            icon: "text-white"
                        }}
                    >
                        {/* <MenuItem value="all">All</MenuItem> */}
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="week">This Week</MenuItem>
                        {/* <MenuItem value="month">This Month</MenuItem>
                        <MenuItem value="nextMonth">Next Month</MenuItem> */}
                        <MenuItem value="monthly">Month</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </Grid>
                {filterType === "custom" && (
                    <Grid item className="filterHalfd">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="From"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={startDate}
                                onChange={this.onChangeStartDate}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                format={dateFormat}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "custom" && (
                    <Grid item className="filterHalfd">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="to"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={endDate}
                                onChange={this.onChangeEndDate}
                                format={dateFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "monthly" && (
                    <Grid item className="filterFull">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                views={["year", "month"]}
                                emptyLabel="..."
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={startDate}
                                onChange={this.onChangeMonth}
                                format={monthFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                fullWidth
                                autoOk
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default AgentFilter;
