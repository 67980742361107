import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";

// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import agent from "../../agent";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import "./AccountSetting.css";

import { Grid, Typography, Button, TextField, IconButton, Paper, Tabs, Tab, Hidden, MenuItem } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import AccountIntegrations from "./AccountIntegrations";
import  Loading from "../../components/loadingSpinner/Loading";
import EditIcon from "@material-ui/icons/Edit";

import AccountDetails from "./AccountDetails";
import AccountEmail from "./AccountEmail";
import AccountPassword from "./AccountPassword";
import HgBilling from "../../pages/billing/HgBilling"
import PaymentHistory from "../billing/PaymentHistory";
import { ProfileType } from "../../types/enum";
import HgBillingV2 from "../../pages/billing/HgBillingV2"
import PaymentDetails from "../../pages/billing/PaymentDetails"

@inject("profileStore", "templateStore", "userStore", "authStore", "billingStore","googleCalendarStore","myobStore","xeroStore", "hgBillingStore")
@withRouter
@observer
class AccountSetting extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        passwordEditState: false,
        isDirty: false,
        password: "",
        newPassword: "",
        confirmPassword: "",
        showPassword: false,
        isPrivateHireLock: false,
        tab: 0,
        tabDisabled: false,
        isLoading: false,
        isRedirected: false
    };

    handleLoading = (value) => {
        this.setState({ isLoading: value });
    };
    componentDidMount() {
        this.props.userStore.getUserCognito()
        this.props.googleCalendarStore.setGoogleCalendarCode("");
        const { currentUser } = this.props.userStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.setState({
            firstName: currentUser.given_name,
            lastName: currentUser.family_name,
            email: currentUser.email,
            firstNameEditState: false,
            lastNameEditState: false,
            emailEditState: false,
            passwordEditState: false,
            isDirty: false,
            password: "",
            newPassword: "",
            confirmPassword: "",
            showPassword: false,
            membershipLock: false
        });

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.tab) {
            if (this.props.match.params.tab === "integration") {
                this.setState({
                    tab: 1
                });
            }
       
            if (this.props.match.params.tab === "lock") {
                this.setState({ tab: 3, tabDisabled: true });
            }
            if(this.props.match.params.tab === "privatehirelock"){
                this.setState({ tab: 4, tabDisabled: true,isPrivateHireLock: true });
            }

            if(this.props.match.params.tab === "checkoutsuccess" || this.props.match.params.tab === "checkoutcancelled"){
                this.setState({ tab: 3});
                // const query = new URLSearchParams(this.props.location.search);
                // const priceId = query.get('priceId')
                // const type = query.get('type')

                this.props.hgBillingStore.reloadAccessSynch()
            }
            if(this.props.match.params.tab === "addcardsuccess" || this.props.match.params.tab === "addcardcancelled"){
                this.setState({ tab: 4});
                this.props.hgBillingStore.reloadAccessSynch()
            }
            if(this.props.match.params.tab === "xero"){
                const query = new URLSearchParams(this.props.location.search);
                const token = query.get('code')
               
                if(token){
                    var codeStore = decodeURIComponent(token)
                    this.props.xeroStore.setXeroCode(token);
                    const body = {
                        code: codeStore,
                        profileId : currentProfile.id
                    };
                    this.setState({
                        isLoading: true,
                        
                    });
                    this.props.xeroStore.connectXero(body).then(result => {
                        this.props.profileStore.loadProfiles().finally(() => {
                            this.setState({
                                isLoading: false,
                                tab: 6
                            });
                        })
                    })
                    // return agent.MYOB.authorizeMYOB(body).then(result => {
                    //     console.log("diceritakan", result)
                    // });
                }else{
                    this.setState({ tab: 6});
                }
            }
            if(this.props.match.params.tab === "membership"){
                this.setState({ tab: 3});
              
            }
            if(this.props.match.params.tab === "myob"){
                const query = new URLSearchParams(this.props.location.search);
                const token = query.get('code')
               
                if(token){
                    var codeMyob = decodeURIComponent(token)
                    this.props.myobStore.setMyobCode(token);
                    const body = {
                        code: codeMyob
                    };
                    this.setState({
                        isLoading: true,
                        
                    });
                    this.props.myobStore.connectMyob(currentProfile.id, body).then(result => {
                        this.props.profileStore.loadProfiles().finally(() => {
                            this.setState({
                                isLoading: false,
                                tab: 6
                            });
                        })
                    })
                    // return agent.MYOB.authorizeMYOB(body).then(result => {
                    //     console.log("diceritakan", result)
                    // });
                }else{
                    this.setState({ tab: 6});
                }
              
            }
            if(this.props.match.params.tab === "googlecalendar"){
                const query = new URLSearchParams(this.props.location.search);
                const token = query.get('code')
                this.props.googleCalendarStore.setGoogleCalendarCode(token);
                this.setState({ tab: 6});
            }
        }
        this.props.authStore.setPassword("");
        this.props.authStore.setNewPassword("");
        this.props.authStore.setConfirmPassword("");
        this.handleLoading(true)
        this.props.hgBillingStore.getCustomerAccess().then(() => {
            this.handleLoading(false)
        })
    };

    handleChangeTabMembership = () =>{
        this.setState({ tab: 3});
    }
    handleChangeTab = (event, value) => {
        this.setState({ tab: value });
    };

    handleSelectedTab = (event, value) => {
        this.setState({ tab: value });
    };
    handleSelectChange = event => {
        this.setState({ tab:  event.target.value });
       
    }
    
    render() {
        const {subscriptions} = this.props.hgBillingStore
        const { currentProfileType } = this.props.profileStore;
        const profiles = this.props.profileStore.getCurrentProfile();
        var breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Account Settings" }];
        if(profiles?.type === ProfileType.PrivateHire.ordinal){
            breadcrumb = [{ name: "Account Settings" }];
        }
        return (
            <div>
                <Header role={this.props.templateStore.getTypeString(currentProfileType || 1)} />
                <Loading showed={this.state.isLoading}/>
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="My Account" />
                    
                    <Paper style={{ position: "relative" }}>
                    <div style={{padding:20}}>
                    <Grid container spacing={8}>
                            {
                                !this.state.isRedirected &&
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="headline" component="h3">
                                        Account Details
                                    </Typography>
                                    <Typography gutterBottom>
                                        Use this section to update your account settings.
                                    </Typography>
                                    {/* <Typography>
                                        <b>Tip:</b>
                                    </Typography>
                                    <Typography>You can reset your password by clicking 'Edit'.</Typography> */}
                                </Grid>
                            }
       


                        {
                            process.env.REACT_APP_DISABLE_BILLING_MOBILE !== "true" &&
                                <Grid item xs={12} lg={12}>
                                    <Hidden smDown>
                                        <Tabs value={this.state.tab} onChange={this.handleChangeTab}  indicatorColor="primary" textColor="primary">
                                                    <Tab label="Details" disabled={this.state.tabDisabled} />
                                                    <Tab label="Email" disabled={this.state.tabDisabled} />
                                                    <Tab label="Password" disabled={this.state.tabDisabled}  />
                                                    <Tab label="Membership" disabled={subscriptions?.parentUserId || this.state.tabDisabled || profiles?.type === ProfileType.PrivateHire.ordinal}/>
                                                    <Tab label="Payment Methods" disabled={subscriptions?.parentUserId || this.state.tabDisabled}/>
                                                    <Tab label="Billing History" disabled={subscriptions?.parentUserId || this.state.tabDisabled}/>
                                                    <Tab label="Pro Integrations" disabled={subscriptions?.parentUserId || this.state.tabDisabled} />
                                                    {/* <Tab label="Notifications" /> */}
                                                    {/* <Tab label="Subscription" /> */}
                                                </Tabs>
                                        <br />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <div style={{ padding: 8 }}>
                                            <TextField select value={this.state.tab} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                                <MenuItem key={0} value={0}>
                                                    Details
                                                </MenuItem>
                                                <MenuItem key={1} value={1}>
                                                    Email
                                                </MenuItem>
                                                <MenuItem key={2} value={2}>
                                                    Password
                                                </MenuItem>
                                                <MenuItem key={3} value={3}>
                                                    Membership
                                                </MenuItem>
                                                <MenuItem key={4} value={4}>
                                                    Payment Methods
                                                </MenuItem>
                                                <MenuItem key={5} value={5}>
                                                    Billing History
                                                </MenuItem>
                                                <MenuItem key={6} value={6}>
                                                    Pro Integrations
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Hidden>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                        {this.state.tab === 0 && (
                                                <AccountDetails />
                                            )}
                                            {this.state.tab === 1 && (
                                                <AccountEmail />
                                            )}
                                            {this.state.tab === 2 && (
                                                <AccountPassword />
                                            )}
                                            {this.state.tab === 3 && (
                                                <Grid container spacing={8}>
                                                    <HgBillingV2 handleLoading={this.handleLoading} isLoading={this.state.isLoading} isPrivateHireLock={this.state.isPrivateHireLock}/>
                                                </Grid>
                                            )}
                                             {this.state.tab === 4 && (
                                                <Grid container spacing={8}>
                                                    <PaymentDetails/>
                                                </Grid>
                                            )}
                                            {this.state.tab === 5 && (
                                                <Grid container spacing={8}>
                                                    <PaymentHistory handleLoading={this.handleLoading} isLoading={this.state.isLoading} />
                                                </Grid>
                                            )}
                                            
                                            {this.state.tab === 6 && (
                                                <Grid container spacing={8}>
                                                    <AccountIntegrations redirectMembership={this.handleChangeTabMembership} handleLoading={this.handleLoading} isLoading={this.state.isLoading}/>
                                                </Grid>
                                            )}
                                            {this.state.tab === 7 && (
                                                <Grid container spacing={8}>
                                                    <Text>If you are having issues accessing your account, please contact support@hotgiggity.com</Text>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                         }
                          {
                                    this.state.isRedirected && process.env.REACT_APP_DISABLE_BILLING_MOBILE === "true" &&
                                    <Grid>
                                          <h3>If you are having issues accessing your account, please contact support@hotgiggity.com</h3>
                                    </Grid>
                                }
                                           
                                {
                                    !this.state.isRedirected && process.env.REACT_APP_DISABLE_BILLING_MOBILE === "true" &&
                                    <Grid item xs={12} lg={12}>
                                          <Hidden smDown>
                                        <Tabs value={this.state.tab} onChange={this.handleChangeTab}  indicatorColor="primary" textColor="primary">
                                                    <Tab label="Details" disabled={this.state.tabDisabled} />
                                                    <Tab label="Email" disabled={this.state.tabDisabled} />
                                                    <Tab label="Password" disabled={this.state.tabDisabled}  />
                                                    <Tab label="Pro Integrations" disabled={this.state.tabDisabled} />
                                                </Tabs>
                                        <br />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <div style={{ padding: 8 }}>
                                                <TextField select value={this.state.tab} onChange={this.handleSelectChange} textColor="primary" fullWidth>
                                                    <MenuItem key={0} value={0}>
                                                        Details
                                                    </MenuItem>
                                                    <MenuItem key={1} value={1}>
                                                        Email
                                                    </MenuItem>
                                                    <MenuItem key={2} value={2}>
                                                        Password
                                                    </MenuItem>
                                                    <MenuItem key={3} value={3}>
                                                        Pro Integrations
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                        </Hidden>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                {this.state.tab === 0 && (
                                                    <AccountDetails />
                                                )}
                                                {this.state.tab === 1 && (
                                                    <AccountEmail />
                                                )}
                                                {this.state.tab === 2 && (
                                                    <AccountPassword />
                                                )}
                                                
                                                {this.state.tab === 3 && (
                                                    <Grid container spacing={8}>
                                                        <AccountIntegrations handleLoading={this.handleLoading} isLoading={this.state.isLoading}/>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                }
                        </Grid>
                    </div>
                       
                    </Paper>
                </MainContainer>
            </div>
        );
    }
}

export default AccountSetting;
