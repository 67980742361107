import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import hg_pro_logo from "../../img/hg_pro_logo.png"

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import WidgetTitleBar from "../../components/widget/WidgetTitleBar";
import PerformList from "../../components/widget/PerformList";

import WidgetContainer from "../../components/widget/WidgetContainer";
import SocialButton from "../../components/widget/SocialButton";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import ManagedActCard from "../../components/acts/ManagedActCard";
import classNames from "classnames";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import "./Profile.css";
import image from "../../helper/image";

import { Typography, Hidden, Paper, Grid, Tabs, Tab, Avatar, Button } from "@material-ui/core";
import { ProfileType } from "../../types/enum";
import StaffAgentInvitationModal from "../../components/venue/agentRoster/StaffAgentInvitationModal";
import FestivalRegistrationModal from "../../components/profile/festivalModal/FestivalRegistrationModal";
import MessageModal from "../../components/profile/messageModal/MessageModal";

@inject("profileStore", "templateStore", "agentActStore", "agentVenueStore", "bookingRequestStore", "reportStore", "userStore")
@withRouter
@observer
class AgentProfile extends Component {
    timeoutValue = null;

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
        this.state = {
            value: 0,
            backgroundImageIndex: 0,
            actExpanded: false,
            venueExpanded: false,
            screenSize: "",
            showInvitationModal: false,
            showFestivalModal: false,
            isCurrentUser: false,
            showMessageModal: false
        };
    }

    updateWindowDimensions = () => {
        let screenSize = "xs";
        let cardDisplay = 3;

        if (window.innerWidth >= 1920) {
            screenSize = "xl";
            cardDisplay = 8;
        } else if (window.innerWidth >= 1280) {
            screenSize = "lg";
            cardDisplay = 6;
        } else if (window.innerWidth >= 960) {
            screenSize = "md";
            cardDisplay = 6;
        } else if (window.innerWidth >= 600) {
            screenSize = "sm";
            cardDisplay = 4;
        }
        this.setState({ screenSize: screenSize, cardDisplay: cardDisplay });
    };

    componentDidMount = () => {
        const profile = this.props.profile || this.props.profileStore.profile;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if(currentProfile && currentProfile.userId === profile.userId) {
            this.setState({isCurrentUser: true})
        }

        if (profile && profile.backgroundImages) {
            for (let i = 0; i < profile.backgroundImages.length; i++) {
                // caches images, avoiding white flash between background replacements
                new Image().src = profile.backgroundImages[i].url1920;
            }
            this.changeBackground(0);
        }
        this.props.templateStore.showLoadingScreen();
        this.props.agentActStore.resetAgentActs();
        Promise.all([
            this.props.agentActStore.getAgentActRoster(profile.id),
            this.props.agentActStore.getAgentMyActs(profile.id),
            this.props.agentVenueStore.getAgentVenue(profile.id),
            this.props.bookingRequestStore.loadUpcomingGigsPublic(profile.id)
            // this.props.bookingRequestStore.loadUpcomingGigs(profile.id)
        ]).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });

        this.updateWindowDimensions();
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    changeBackground = index => {
        const profile = this.props.profile || this.props.profileStore.profile;
        this.setState({ backgroundImageIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.backgroundImageIndex + 1 < profile.backgroundImages.length ? this.state.backgroundImageIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    handleExpandAct = () => {
        this.setState({ actExpanded: true });
    };

    handleExpandVenue = () => {
        this.setState({ venueExpanded: true });
    };

    processUpcomingGigs = upcomingGigs => {
        let result = [];
        for (let i = 0; i < upcomingGigs.length; i++) {
            let dateStart = moment(upcomingGigs[i].dateStart);
            let data = {
                month: dateStart.format("MMMM"),
                date: dateStart.format("D"),
                day: dateStart.format("dddd"),
                venue: upcomingGigs[i].venueName,
                address: upcomingGigs[i].venueAddress,
                time:
                    moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                    " - " +
                    moment(upcomingGigs[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                actName: upcomingGigs[i].actName,
                actUrl: "/profile/" + upcomingGigs[i].actId,
                venueUrl: "/profile/" + upcomingGigs[i].venueId,
            };
            result.push(data);
        }
        return result;
    };

    showInvitationModal = () => {
        const { profile } = this.props;

        const { currentProfile } = this.props.profileStore;
        if (currentProfile.type === ProfileType.Staff.ordinal) {
            this.props.agentVenueStore.setInvitedProfile(profile);
        }
        this.setState({
            ...this.state,
            showInvitationModal: true
        });
    };

    hideInvitaionModal = () => {
        this.setState({
            ...this.state,
            showInvitationModal: false
        });
    };

    handleRegisterFestival = () => {
        const { currentUser } = this.props.userStore;
        const profile = this.props.profile || this.props.profileStore.profile;
        if (currentUser) {
            this.setState({
                ...this.state,
                showFestivalModal: true
            });
        } else {
            this.props.history.push(`/register?source=${profile.festivalCode}`);
        }
    };

    closeFestivalRegistraionModal = () => {
        this.setState({
            ...this.state,
            showFestivalModal: false
        });
    };

    showMessageModal = () => {
        this.setState({
            showMessageModal: true
        });
    };

    hideMessageModal = () => {
        this.setState({
            showMessageModal: false
        });
    };

    handleButtonMessage = (e) => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const profile = this.props.profile || this.props.profileStore.profile;
        if(this.state.isCurrentUser) {
            e.preventDefault();
            this.props.history.push('/messages/new');
        } else {
            if (currentProfile) {
                e.preventDefault();
                this.props.history.push({
                    pathname: '/messages/new',
                    state: { profile }
                })
            } else {
                this.setState({showMessageModal: true})
            }
        }
    }

    render() {
        const profile = this.props.profile || this.props.profileStore.profile;
        const { currentProfile } = this.props.profileStore;
        const { agentActs } = this.props.agentActStore;
        const { agentVenues } = this.props.agentVenueStore;
        let { upcomings } = this.props.bookingRequestStore;
        const { showInvitationModal, showFestivalModal } = this.state;

        let performData = this.processUpcomingGigs(upcomings);

        const showFestival = profile.festivalCode;
        

        let profileContent = (
            <div className="profile-content">
                <Grid container spacing={16}>
                    {showFestival && (
                        <Fragment>
                            <Grid item xs={12}>
                                <div className="standalone-widget-title">
                                    <WidgetTitleBar title={profile && profile.festivalTitle} />
                                </div>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <div
                                            className="festival-section"
                                            dangerouslySetInnerHTML={{ __html: profile && profile.festivalDescription }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="text-center">
                                        <Button color="primary" variant="raised" size="large" onClick={this.handleRegisterFestival}>
                                            ENTER NOW!
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <FestivalRegistrationModal agent={profile} open={showFestivalModal} closeHandler={this.closeFestivalRegistraionModal} />
                        </Fragment>
                    )}
                    <Grid item xs={12}>
                        <div className="standalone-widget-title">
                            <WidgetTitleBar title={profile && profile.fullName + "'s Acts"} showBadge={true} badgeValue={agentActs.length} />
                        </div>
                        <Grid container spacing={16}>
                            {agentActs &&
                                agentActs.map((agentAct, index) => {
                                    if (!this.state.actExpanded && index < this.state.cardDisplay) {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={agentAct.act.id} style={{ position: "relative" }}>
                                                {index === this.state.cardDisplay - 1 &&
                                                    agentActs.length > this.state.cardDisplay && (
                                                        <Tooltip id="tooltip-bottom" title="View all" placement="bottom">
                                                            <Grid container className="card-overlay" onClick={this.handleExpandAct}>
                                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                    <MoreHorizIcon />
                                                                </Grid>
                                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                    <Typography>View all</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Tooltip>
                                                    )}
                                                <ManagedActCard act={agentAct.act} type="act" hideActions />
                                            </Grid>
                                        );
                                    } else if (this.state.actExpanded) {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={agentAct.act.id}>
                                                <ManagedActCard act={agentAct.act} type="act" hideActions />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="standalone-widget-title">
                            <WidgetTitleBar title={profile && profile.fullName + "'s Venues"} showBadge={true} badgeValue={agentVenues.filter(x => x.venue).length} />
                        </div>
                        <Grid container spacing={16}>
                            {agentVenues &&
                                agentVenues.map((agentVenue, index) => {
                                    if (!this.state.venueExpanded && index < this.state.cardDisplay && agentVenue.venue) {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={agentVenue?.venue?.id} style={{ position: "relative" }}>
                                                {index === this.state.cardDisplay - 1 &&
                                                    agentVenues.length > this.state.cardDisplay && (
                                                        <Tooltip id="tooltip-bottom" title="View all" placement="bottom">
                                                            <Grid container className="card-overlay" onClick={this.handleExpandVenue}>
                                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                    <MoreHorizIcon />
                                                                </Grid>
                                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                    <Typography>View all</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Tooltip>
                                                    )}
                                                <ManagedActCard act={agentVenue?.venue} type="venue" hideActions />
                                            </Grid>
                                        );
                                    } else if (this.state.venueExpanded && agentVenue.venue) {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={agentVenue.venue.id}>
                                                <ManagedActCard act={agentVenue.venue} type="venue" hideActions />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );

        return (
            <div>
                <LoadingSpinner showed={this.props.templateStore.loadingScreenState} />
                <Header forceToggler />
                <Sidebar forceMobile />

                <div className="profile-header">
                    <div className="profile-header-information-container">
                        {profile &&
                            profile.profileImage &&
                            profile.showAvatar && (
                                <div className="profile-header-inner">
                                    <Avatar classes={{ root: "profile-avatar" }} src={image.getProfileImage(profile)} alt={profile.fullName} />
                                </div>
                            )}
                        <div className="profile-header-text profile-header-inner">
                            <Typography type="headline" component="h3">
                                {profile && profile.fullName} <img style={{display:this.props.isFreePlan ? "none":"inline"}} src={ hg_pro_logo } width="50px"/>
                            </Typography>
                            <Typography component="p">{profile.type === ProfileType.Agent.ordinal ? "Agent" : "Consultant"}</Typography>
                            <br />
                            {profile && (profile.city || profile.state) ? (
                                <Typography component="p">
                                    <LocationOnIcon classes={{ root: "profile-header-icon" }} />
                                    {profile.city && profile.state
                                        ? profile.city + ", " + profile.state
                                        : profile.city
                                            ? profile.city
                                            : profile.state
                                                ? profile.state
                                                : null}
                                </Typography>
                            ) : null}

                        </div>
                        <div className="profile-header-inner">
                            <SocialButton variant="webSite" urlData={profile && profile.webSite} />
                            <SocialButton variant="facebook" urlData={profile && profile.facebook} />
                            <SocialButton variant="twitter" urlData={profile && profile.twitter} />
                            <SocialButton variant="bandcamp" urlData={profile && profile.bandcamp} />
                            <SocialButton variant="soundcloud" urlData={profile && profile.soundcloud} />
                            <SocialButton variant="mixcloud" urlData={profile && profile.mixcloud} />
                            <SocialButton variant="youtube" urlData={profile && profile.youtube} />
                            <SocialButton variant="instagram" urlData={profile && profile.instagram} />
                            <SocialButton variant="googlePlus" urlData={profile && profile.googlePlus} />
                            <SocialButton variant="linkedIn" urlData={profile && profile.linkedIn} />
                            <SocialButton variant="iTunes" urlData={profile && profile.iTunes} />
                            <SocialButton variant="spotify" urlData={profile && profile.spotify} />
                        </div>
                        <div className="profile-header-inner">
                            {currentProfile && currentProfile.type === ProfileType.Staff.ordinal ? (
                                <Fragment>
                                    <Button variant="raised" color="primary" className="actionButton" onClick={this.showInvitationModal}>
                                        Invite Agent
                                    </Button>
                                    <StaffAgentInvitationModal open={showInvitationModal} onClose={this.hideInvitaionModal} onProfile={true}/>
                                </Fragment>
                                ) : null}
                              {currentProfile ?
                                <Button variant="raised" color="primary" className="actionButton" onClick={(e) => {
                                        this.handleButtonMessage(e)
                                        }} >
                                    Message
                                </Button>
                                : ""
                            }
                            <MessageModal profile={profile} open={this.state.showMessageModal} closeHandler={this.hideMessageModal} />
                        </div>
                        {profile &&
                            profile.backgroundImages &&
                            profile.backgroundImages.length > 1 && (
                                <div className="profile-header-inner-slider">
                                    {profile.backgroundImages.map((backgroundImage, i) => {
                                        return (
                                            <div
                                                key={i}
                                                index={i}
                                                className={classNames(
                                                    "background-image-control",
                                                    this.state.backgroundImageIndex === i ? "active" : "inactive"
                                                )}
                                                onClick={this.manualChangeBackground(i)}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                    {profile &&
                        profile.backgroundImages && (
                            <React.Fragment>
                                {profile.backgroundImages.map((backgroundImage, i) => {
                                    return (
                                        <div
                                            className={classNames(
                                                "profile-header-background-image",
                                                this.state.backgroundImageIndex === i ? "active" : "inactive"
                                            )}
                                            style={{ backgroundImage: "url('" + backgroundImage.url1920 + "')" }}
                                            key={i}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )}
                    {(!profile || !profile.backgroundImages || profile.backgroundImages.length === 0) && (
                        <React.Fragment>
                            <div
                                className={classNames("profile-header-background-image", "active")}
                                style={{ backgroundImage: "url('/hero2.jpg')" }}
                                key={0}
                            />
                        </React.Fragment>
                    )}
                </div>

                <div className="profile-body">
                    <Hidden smDown>
                        <div className="profile-sidebar">
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <div className="border-bottom-grid">
                                        <Grid container alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                    {profile && profile.fullName}'s Live Gigs
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: "50px" }}>
                                    <PerformList performData={performData} />
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="border-bottom-grid">
                                        <Typography type="headline" component="h4" className="profile-sidebar-title">
                                            {profile && profile.fullName}'s Bio
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="profile-bio-content" style={{ marginBottom: "50px" }}>
                                    <Typography component="div">
                                        <div className="bio-section" dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />{" "}
                                    </Typography>
                                </Grid>

                                
                                { profile.showContactPhone || profile.showEmail ? 
                                    <Grid item xs={12}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <div className="border-bottom-grid">
                                                    <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                        Contact
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} className="profile-sidebar-contact">
                                                <Grid container spacing={8}>
                                                    {profile && profile.showContactPhone &&
                                                        profile.contactPhone && (
                                                            <Grid item xs={12}>
                                                                <Typography component="div">
                                                                    <PhoneIcon />
                                                                    {profile && profile.contactPhone}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    {profile && profile.showEmail &&
                                                        profile.email && (
                                                            <Grid item xs={12}>
                                                                <Typography component="div">
                                                                    <EmailIcon />
                                                                    {profile && profile.email}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> : null
                                }
                            </Grid>
                        </div>

                        {profileContent}
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Paper style={{ width: "100%", backgroundColor: "#ff0c9c" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        centered
                                        fullWidth
                                    >
                                        <Tab icon={<PersonIcon />} label="Profile" />
                                        <Tab icon={<QueueMusicIcon />} label="Live Gigs" />
                                        <Tab icon={<ImportContactsIcon />} label="Bio" />
                                    </Tabs>
                                </Paper>
                            </Grid>

                            {this.state.value === 0 && (
                                <Grid item xs={12}>
                                    {profileContent}
                                </Grid>
                            )}
                            {this.state.value === 1 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Live Gigs"} showBadge={false}>
                                            <PerformList performData={performData} />
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                            {this.state.value === 2 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Bio"} showBadge={false} style={{backgroundColor:"#333"}}>
                                            <Typography component="div">
                                                <div className="bio-section" dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />
                                            </Typography>
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </div>
            </div>
        );
    }
}

export default AgentProfile;
