import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import promiseFinally from "promise.prototype.finally";

import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

import { Router, HashRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-theme.css";
import "typeface-roboto";
import "./index.css";

import { configure } from "mobx";
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from 'history';

import authStore from "./stores/authStore";
import rosterStore from "./stores/rosterStore";
import rosterProductionStore from "./stores/rosterProductionStore";
import commonStore from "./stores/commonStore";
import userStore from "./stores/userStore";
import profileStore from "./stores/profileStore";
import templateStore from "./stores/templateStore";
import generalProfileStore from "./stores/generalProfileStore";
import designProfileStore from "./stores/designProfileStore";
import socialProfileStore from "./stores/socialProfileStore";
import socialPromotionStore from "./stores/socialPromotionStore";
import privacyProfileStore from "./stores/privacyProfileStore";
import embedProfileStore from "./stores/embedProfileStore";
import financialProfileStore from "./stores/financialProfileStore";
import insuranceProfileStore from "./stores/insuranceProfileStore";
import memberProfileStore from "./stores/memberProfileStore";
import songListStore from "./stores/songListStore";
import setListStore from "./stores/setListStore";
import notificationStore from "./stores/notificationStore";
import agentMusicianStore from "./stores/agentMusicianStore";
import organisationStore from "./stores/organisationStore";
import organisationMemberStore from "./stores/organisationMemberStore";
import stateStore from "./stores/stateStore";
import regionStore from "./stores/regionStore";
import bookingRequestStore from "./stores/bookingRequestStore";
import venueStore from "./stores/venueStore";
import agentVenueStore from "./stores/agentVenueStore";
import eventStore from "./stores/eventStore";
import agentActStore from "./stores/agentActStore";
import negotiateStore from "./stores/negotiateStore";
import venueActStore from "./stores/venueActStore";
import lineupStore from "./stores/lineupStore";
import facebookStore from "./stores/facebookStore";
import agentConsultantStore from "./stores/agentConsultantStore";
import lineupMessageStore from "./stores/lineupMessageStore";
import invoiceStore from "./stores/invoiceStore";
import notificationSettingStore from "./stores/notificationSettingStore";
import embedGigStore from "./stores/embedGigStore";
import userInvitationStore from "./stores/userInvitationStore";
import reportStore from "./stores/reportStore";
import searchStore from "./stores/searchStore";
import actTypeStore from "./stores/actTypeStore";
import bookingHistoryStore from "./stores/bookingHistoryStore";
import billingStore from "./stores/billingStore";
import hgBillingStore from "./stores/hgBillingStore";

import signatureStore from "./stores/signatureStore";
import promotionStore from "./stores/promotionStore";
import staffFilterStore from "./stores/staffFilterStore";
import quickBooksStore from "./stores/quickBooksStore";
import lookupStore from "./stores/lookupStore";
import gigPromotionStore from "./stores/gigPromotionStore";
import userFestivalStore from "./stores/userFestivalStore";
import agentFilterStore from "./stores/agentFilterStore";
import venueMenuStore from "./stores/venueMenuStore";
import conversationStore from "./stores/conversationStore";
import googleCalendarStore from "./stores/googleCalendarStore";
import instagramStore from "./stores/instagramStore";
import myobStore from "./stores/myobStore"
import xeroStore from "./stores/xeroStore"
import xeroLogStore from "./stores/xeroLogStore"
import generalSocialPostStore from "./stores/generalSocialPostStore"
import  { API, Amplify } from "aws-amplify";
import Moment from "react-moment";
import awsconfig from "./aws-exports";

Moment.globalFormat = "DD-MM-YYYY HH:mm";

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
notificationStore.setRoutingStore(routingStore);

const stores = {
    routing: routingStore,
    authStore,
    rosterStore,
    commonStore,
    userStore,
    profileStore,
    templateStore,
    generalProfileStore,
    designProfileStore,
    socialProfileStore,
    socialPromotionStore,
    financialProfileStore,
    privacyProfileStore,
    embedProfileStore,
    memberProfileStore,
    songListStore,
    setListStore,
    notificationStore,
    agentMusicianStore,
    organisationStore,
    organisationMemberStore,
    stateStore,
    regionStore,
    bookingRequestStore,
    venueStore,
    agentVenueStore,
    eventStore,
    agentActStore,
    negotiateStore,
    venueActStore,
    lineupStore,
    rosterProductionStore,
    facebookStore,
    agentConsultantStore,
    lineupMessageStore,
    invoiceStore,
    notificationSettingStore,
    embedGigStore,
    userInvitationStore,
    reportStore,
    searchStore,
    actTypeStore,
    bookingHistoryStore,
    billingStore,
    hgBillingStore,
    promotionStore,
    lookupStore,
    signatureStore,
    staffFilterStore,
    quickBooksStore,
    insuranceProfileStore,
    gigPromotionStore,
    userFestivalStore,
    agentFilterStore,
    venueMenuStore,
    conversationStore,
    googleCalendarStore,
    instagramStore,
    myobStore,
    xeroStore,
    xeroLogStore,
    generalSocialPostStore
};

// For easier debugging
window._____APP_STATE_____ = stores;

promiseFinally.shim();

Amplify.configure(awsconfig);
API.configure();

// don't allow state modifications outside actions
configure({ enforceActions: "observed" });
const history = syncHistoryWithStore(browserHistory, routingStore);

const startAppMobile = () => {
    // on mobile we use HashRouter
    if (window.StatusBar) {
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.backgroundColorByHexString("#ff0c9c"); // => #333333
    }
    window.open = window.cordova.InAppBrowser.open;
    ReactDOM.render(
        <Provider {...stores}>
            <HashRouter history={history}>
                <App />
            </HashRouter>
        </Provider>,
        document.getElementById("root")
    );
};

const startAppWeb = () => {
    // on web we use BrowserRouter
    ReactDOM.render(
        <Provider {...stores}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>,
        document.getElementById("root")
    );
};

if (window.cordova) {
    // console.log("Running inside cordova");
    document.addEventListener("deviceready", startAppMobile, false);
} else {
    // console.log("Running outside cordova");
    startAppWeb();
}

// disable the service worker for development phase
// unregister();
