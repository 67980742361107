import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, TextField, Grid, Typography, Dialog, AppBar, Toolbar, MenuItem, InputAdornment, Switch } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Slide from "@material-ui/core/Slide";
import Instrument from "../controls/Instruments";
import Genre from "../controls/Genres";
import GigType from "../controls/GigTypes";
import Vibe from "../controls/Vibes";

import RichTextEditor from "../richTextEditor/RichTextEditor";

import InputLabel from "@material-ui/core/InputLabel";

import SelectField from "../fields/SelectField";

import NumberFormat from "react-number-format";

import Countries from "../../lookup/Country";
import { OrganisationType, ProfileType } from "../../types/enum";
import Loading from "../loadingSpinner/Loading";
import agent from "../../agent";

import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "react-google-maps";
import GooglePlaceAutoComplete from "../maps/GooglePlaceAutocomplete";

const GoogleMaps = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
            center={{
                lat: props.latitude,
                lng: props.longitude
            }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    ))
);

@inject("generalProfileStore", "userStore", "templateStore", "authStore", "organisationStore", "profileStore", "actTypeStore", "lookupStore", "billingStore", "hgBillingStore")
@withRouter
@observer
class General extends React.Component {
    state = {
        bioDialogOpen: false,
        festivalDescriptionDialogOpen: false,
        gearListDialogOpen: false,
        isLoading: true,
        actTypeSuggestion: []
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const { currentProfile } = this.props.profileStore;

        const {match} = this.props
      
        // set type
        this.props.generalProfileStore.reset();
        this.props.generalProfileStore.setType(this.props.templateStore.getType(this.props.type));
        if(this.props.type==="consultant"){
            this.props.generalProfileStore.setIsConsultant(true);
        }
        this.props.organisationStore.loadOrganisations();
        this.props.organisationStore.loadRegions();
        this.props.actTypeStore.loadActTypes();
        this.props.lookupStore.getVenueTypesSuggestion();
        var profId = undefined;
        if(this.props.profileId){
            profId = this.props.profileId
        }
        else if(!this.props.profileId && this.props.match && this.props.match.params && this.props.match.params.new){
            profId = this.props.match.params.new
        }
        this.props.generalProfileStore
        .loadInitialData(typeof profId === Number ? profId : this.props.profileId)
        .then(profile => {
            if (this.props.type === "venue" && profile === undefined) {
                this.props.generalProfileStore.setRegionId(this.props.regionId);
            }
            //set default value when create act
            if (window.location.pathname.includes("myact/create")) {
                this.props.generalProfileStore.setLineUpCount(1)
                this.props.generalProfileStore.setActTypeId(1)
                this.props.generalProfileStore.setActAdminId(currentProfile?.id)
            }

            
        
            if(this.props.match.params.role==="consultant"){
                this.props.profileStore.setProfileType(ProfileType.Agent.ordinal)
                this.props.generalProfileStore.setType(this.props.templateStore.getType(this.props.type));
                if(this.props.type==="agent"){
                    this.props.generalProfileStore.setIsConsultant(true);
                }
            }
        })
        .finally(() => {
            this.setState({ isLoading: false });
        });      
    }

    handleActNameChange = e => {
        this.props.generalProfileStore.setActName(e.target.value);
        if (this.props.generalProfileStore.id) this.props.setTitleAct("Edit Act " + e.target.value)
        else this.props.setTitleAct("Create Act " + e.target.value)
    }
    handleLineUpCountChange = value => this.props.generalProfileStore.setLineUpCount(value);
    handleActTypeChange = value => {
        this.props.generalProfileStore.setActTypeId(value)
    };
    handleProfileNameChange = e => this.props.generalProfileStore.setProfileName(e.target.value);
    handleEmailChange = e => this.props.generalProfileStore.setEmail(e.target.value);
    handleValidateEmail = e => this.props.generalProfileStore.validateEmail(e.target.value);
    handleFirstNameChange = e => this.props.generalProfileStore.setFirstName(e.target.value);
    handleLastNameChange = e => this.props.generalProfileStore.setLastName(e.target.value);
    handleStreetChange = e => this.props.generalProfileStore.setStreet(e.target.value);
    handleCityChange = e => this.props.generalProfileStore.setCity(e.target.value);
    handlePostalChange = e => this.props.generalProfileStore.setPostal(e.target.value);
    handleStateChange = e => this.props.generalProfileStore.setState(e.target.value);
    handleCountryChange = value => this.props.generalProfileStore.setCountryCode(value);
    handleAccountType = e => this.props.generalProfileStore.setAccountType(e.target.value);
    handleContactNameChange = e => this.props.generalProfileStore.setContactName(e.target.value);
    handleContactPhoneChange = e => this.props.generalProfileStore.setContactPhone(e.target.value);
    handleRegionIdChange = e => this.props.generalProfileStore.setRegionId(e.target.value);

    
    handleProductionTypeChange = value => this.props.generalProfileStore.setProductionType(value);
    handleGearListChange = value => this.props.generalProfileStore.setGearList(value);

    handleBioChange = value => this.props.generalProfileStore.setBio(value);
    handleFestivalDescriptionChange = value => this.props.generalProfileStore.setFestivalDescription(value);
    handleFestivalTitleChange = e => this.props.generalProfileStore.setFestivalTitle(e.target.value);

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        // submit
        const {match} = this.props
        const { currentUser } = this.props.userStore;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        var organisationType= ""
        if(match && match.params && match.params.type){
            organisationType = match.params.type
        }
        var isSubscribedCustomer = false
        if (currentUser.status === 21 ) {
            isSubscribedCustomer = true
        }
        e.preventDefault();
        this.props.generalProfileStore.emailValidation()
        this.props.generalProfileStore.createActValidation()
        const {errors} = this.props.generalProfileStore

        if(!errors.email && !errors.firstName && !errors.lastName && !errors.actName && !errors.lineUpCount && !errors.actTypeId){
            this.setState({ isLoading: true });
            this.props.generalProfileStore
            .submit()
            .then(async response => {
                if(response) {
                    if(this.props.type === "privateHire"){
                        let candidateCustomer = { 
                            userId: currentUser.sub, 
                            email: currentUser.email, 
                            given_name: currentUser.given_name, 
                            family_name: currentUser.family_name, 
                            userType: 5
                        };
                        await agent.Billing.createCustomer(candidateCustomer);
                        await this.props.hgBillingStore.reloadAccessSynch();
                        if(organisationType === "private"){
                            await Promise.all([
                                this.props.profileStore.loadProfilesAfterLogin(),
                            ]).then(() => {
                                this.props.hgBillingStore.reloadAccessSynch();
                                this.props.profileStore.setProfileType(9);
                                this.props.billingStore.setAccess(5);
                            })
                        }else{
                            this.props.hgBillingStore.reloadAccessSynch();
                        }

                    }
                    //case : if a user with existing profile create a new venue profile to accomate which venue type it is
                    if(organisationType === "single"){
                            let candidateCustomer = { 
                                userId: currentUser.sub, 
                                email: currentUser.email, 
                                given_name: currentUser.given_name, 
                                family_name: currentUser.family_name, 
                                userType: 3
                            };
                            await agent.Billing.createCustomer(candidateCustomer);
                            await Promise.all([
                                this.props.profileStore.loadProfilesAfterLogin(),
                                this.props.hgBillingStore.reloadAccessSynch()
                            ]).then(() => {
                                this.props.profileStore.setProfileType(3);
                                this.props.billingStore.setAccess(3);
                            })
                    }else if(organisationType === "group"){
                         if(!this.props.organisationStore.created && !currentProfile?.hasOrganisation){
                            let candidateCustomer = { 
                                userId: currentUser.sub, 
                                given_name: currentUser.given_name, 
                                family_name: currentUser.family_name, 
                                userType: 4
                            };
                            this.props.organisationStore.setType(OrganisationType.Venue.ordinal)
                            this.props.organisationStore.setName("Organisation")
                            this.props.organisationStore.setCreated(true)
                                this.props.organisationStore.submit().then(async () => {
                                    await agent.Billing.createCustomer(candidateCustomer);
                                    // await this.props.billingStore.reloadAccessSynch();
                                    await Promise.all([
                                        this.props.profileStore.loadProfilesAfterLogin(),
                                        this.props.hgBillingStore.reloadAccessSynch()
                                    ]).then(() => {
                                        this.props.profileStore.setProfileType(3);
                                        this.props.billingStore.setAccess(3);
                                    })
                                })
                        }
                       
                       
                      
                    }
                    //------------------

                    if (this.props.type === "act") {
                        this.props.setTitleAct("Edit Act " + this.props.generalProfileStore.actName)
                        this.props.history.push(`/myact/edit/${response.id}/design`);
                    }
                    if (this.props.type === "venue") {
                        var id = response.id;
                        if(response.id && response.id.id){
                            id = response.id.id
                        }
                        if(isSubscribedCustomer){
                            let candidateCustomer = { 
                                userId: currentUser.sub, 
                                email: currentUser.email, 
                                given_name: currentUser.given_name, 
                                family_name: currentUser.family_name, 
                                userType: 3
                            };
                            await agent.Billing.createCustomer(candidateCustomer);
                            this.props.hgBillingStore.reloadAccessSynch();
                        }
                        this.props.history.push(`/venues/${id}/edit/design`);
                    }
                    if (this.props.type !== "act" && this.props.type !== "venue") {
                      
                        const { editProfileActiveStep } = this.props.templateStore;
                        this.props.hgBillingStore.reloadAccessSynch().then(() => {
                            this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
                        })
                        
                    }
                }
                
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        } else if (errors.actName) {   
            window.scrollTo(0, 0);
            this.props.templateStore.openSnackbar("Incomplete fields");
        } else {
            this.setState({ isLoading: false });
        }
    };

    anyError(field) {
        if (!this.props.generalProfileStore.errors) return false;
        if (!this.props.generalProfileStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.generalProfileStore.errors) return null;
        if (!this.props.generalProfileStore.errors[field]) return null;
        return this.props.generalProfileStore.errors[field];
    }

    handleBioDialogOpen = () => {
        this.setState({ bioDialogOpen: true });
    };

    handleBioDialogClose = () => {
        this.setState({ bioDialogOpen: false });
    };

    handleFestivalDescriptionDialogOpen = () => {
        this.setState({ festivalDescriptionDialogOpen: true });
    };

    handleFestivalDescriptionDialogClose = () => {
        this.setState({ festivalDescriptionDialogOpen: false });
    };

    handleGearListDialogOpen = () => {
        this.setState({ gearListDialogOpen: true });
    };

    handleGearListDialogClose = () => {
        this.setState({ gearListDialogOpen: false });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    phoneFormat = props => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                isNumericString={true}
                format="#############"
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
            />
        );
    };

    handleChangeSearchAddress = value => {
        this.props.generalProfileStore.setSearchedAddress(value);
    };

    handleChangeLongitudeLatitude = value => {
        this.props.generalProfileStore.setLongitudeLatitude(value);
    };

    handleChangeProfileVenueType = value => {
        this.props.generalProfileStore.setProfileVenueType(value);
    };

    handleCopy = () => {
        var copyText = document.getElementById("festivalRegistrationUrl");
        copyText.select();
        document.execCommand("copy");
        this.setState({ copyState: true });
        this.props.templateStore.openSnackbar("Url Copied");
        setTimeout(
            function () {
                this.setState({ copyState: false });
            }.bind(this),
            2000
        );
    };

    handleShowEmailChange = e => {
        this.props.generalProfileStore.setShowEmail(e.target.checked);
    };

    handleShowContactPhoneChange = e => {
        this.props.generalProfileStore.setShowContactPhone(e.target.checked);
    };

    render() {
        const {
            actName,
            lineUpCount,
            profileName,
            firstName,
            lastName,
            email,
            street,
            city,
            postal,
            state,
            countryCode,
            bio,
            contactName,
            contactPhone,
            regionId,
            profileProductionType,
            gearList,
            searchedAddress,
            longitude,
            latitude,
            actTypeId,
            profileVenueTypes,
            festivalRegistrationUrl,
            festivalTitle,
            festivalDescription,
            festivalCode,
            showEmail,
            showContactPhone,
        } = this.props.generalProfileStore;
        const { profile } = this.props.profileStore;
        const { regions, level3Label } = this.props.organisationStore;
        const { actTypes } = this.props.actTypeStore;
        const { editProfileActiveStep } = this.props.templateStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading } = this.state;
        const { venueTypes } = this.props.lookupStore;
        let lineUpCountSuggestion = () => {
            let lineUpCounts = [
                { name: "Solo", instrument: 1 },
                { name: "Duo", instrument: 2 },
                { name: "Trio", instrument: 3 },
                { name: "Quartet / 4 Piece", instrument: 4 },
                { name: "Quintet / 5 Piece", instrument: 5 },
                { name: "Sextet / 6 Piece", instrument: 6 }
            ];

            for (var i = 0; i < 93; i++) {
                lineUpCounts.push({ name: i + 7 + " Piece", instrument: i + 7 });
            }
            return lineUpCounts.map(lineUpCount => {
                return {
                    value: lineUpCount.instrument,
                    label: lineUpCount.name
                };
            });
        };

        let countriesSuggestion = () => {
            return Countries.map(country => {
                return {
                    value: country.code,
                    label: country.name,
                    divider: country.divider
                };
            });
        };

        let actTypeSuggestion = () => {
            return actTypes.map(actType => {
                return {
                    value: actType.id,
                    label: actType.name
                };
            });
        };

        let productionTypesSuggestion = [{ value: 1, label: "Sound Engineer" }, { value: 2, label: "Lighting Engineer" }];

        let instrumentDefault = type === "dj" ? "DJ" : null;

        let isConsultantProfile = this.props.match.params.role==="consultant" || profile?.isConsultant ? true : false;

        let formEmailWidth = type === "musician" || type === "agent" || type === "act" || type === "venue" ? 6 : 12;

        let formContactPhoneWidth = type === "musician" || type === "agent" || type === "act" || type === "venue" ? 6 : 12;

        let formContactNameWidth = type === "act" || type === "venue" ? 12 : 6;

        return (
            <div className="Profile-General">
                <form onSubmit={this.handleSubmitForm} style={{ position: "relative" }}>
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        <Grid item xs={12} lg={3}>
                            <div className="sticky-information">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    {type === "venue" ? "Venue Detail" : type === "act" ? "Act Detail" : "Personal Detail"}
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {type === "musician"
                                        ? "Use this section to manage your general info. Be sure to list your instruments/skill sets and genre as this information will be displayed on your public profile page and used in searches on the platform."
                                        : type === "act"
                                            ? "Use this section to manage the general information for your act."
                                            : type === "agent" && !isConsultantProfile
                                                ? "Use this section to input information about your agency and the main point of contact."
                                                : type === "venue"
                                                    ? "Use this section to input key information about your venue."
                                                    : "Use this section to manage your general info."}
                                </Typography>
                                {type !== "venue" &&
                                    type !== "staff" && (
                                        <Fragment>
                                            <Typography>
                                                {type === "musician" || type === "dj" || type === "production" || type === "act" ? (<></>) : (<b>Tip:</b>)}
                                            </Typography>
                                            <Typography className="side-helper-text">
                                                {type === "musician" || type === "dj" || type === "production" || type === "act"
                                                    ? ""
                                                    : "Use the rich text editor to style your bio with heading text, hyper links italics etc."}
                                            </Typography>
                                        </Fragment>
                                    )}
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                {type === "act" && (
                                    <React.Fragment>
                                        <Grid item xs={12} md={4}>
                                            <SelectField
                                                id="lineUpCount"
                                                multi={false}
                                                suggestions={lineUpCountSuggestion()}
                                                onChange={this.handleLineUpCountChange}
                                                value={lineUpCount}
                                                placeholder=" "
                                                label="Line Up"
                                                margin="dense"
                                                error={this.anyError("lineUpCount")}
                                                helperText={this.errorText("lineUpCount")}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <SelectField
                                                id="actTypeId"
                                                multi={false}
                                                suggestions={actTypeSuggestion()}
                                                onChange={this.handleActTypeChange}
                                                value={actTypeId}
                                                placeholder=" "
                                                label="Act Type"
                                                margin="dense"
                                                error={this.anyError("actTypeId")}
                                                helperText={this.errorText("actTypeId")}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="actName"
                                                label="Act Name *"
                                                margin="dense"
                                                fullWidth
                                                value={actName}
                                                onChange={this.handleActNameChange}
                                                error={this.anyError("actName")}
                                                helperText={this.errorText("actName")}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}

                                {type === "agent" && !isConsultantProfile && (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="profileName"
                                            label={(type === "venue" ? "Venue" : "Agency") + " Name"}
                                            margin="dense"
                                            fullWidth
                                            value={
                                                profile && profile.type === ProfileType.Agent.ordinal && profile.isConsultant
                                                    ? profile.profileName
                                                    : profileName
                                            }
                                            onChange={this.handleProfileNameChange}
                                            error={this.anyError("profileName")}
                                            helperText={this.errorText("profileName")}
                                            inputProps={{
                                                readOnly: profile && profile.type === ProfileType.Agent.ordinal && profile.isConsultant
                                            }}
                                        />
                                    </Grid>
                                )}

                                {type === "venue" && (
                                    <Grid item xs={6}>
                                        <TextField
                                            id="profileName"
                                            label={(type === "venue" ? "Venue" : "Agency") + " Name"}
                                            margin="dense"
                                            fullWidth
                                            value={profileName}
                                            onChange={this.handleProfileNameChange}
                                            error={this.anyError("profileName")}
                                            helperText={this.errorText("profileName")}
                                        />
                                    </Grid>
                                )}

                                {type === "venue" && (
                                    <Grid item xs={6}>
                                        <TextField
                                            id="region"
                                            label={level3Label === undefined ? "Region" : level3Label}
                                            margin="dense"
                                            fullWidth
                                            value={regionId | ""}
                                            onChange={this.handleRegionIdChange}
                                            error={this.anyError("region")}
                                            helperText={this.errorText("region")}
                                            select
                                        >
                                            {regions.map(region => {
                                                return (
                                                    <MenuItem value={region.id} key={region.id}>
                                                        {`${region.stateName} - ${region.name}`}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                )}

                                {(type === "musician" ||
                                    type === "agent" ||
                                    type === "staff" ||
                                    type === "production" ||
                                    type === "dj" ||
                                    type === "consultant" ||
                                    type === "privateHire") && (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="firstName"
                                                label="First Name *"
                                                margin="dense"
                                                fullWidth
                                                value={firstName === 'undefined' || firstName === undefined ? '' : firstName}
                                                onChange={this.handleFirstNameChange}
                                                error={this.anyError("firstName")}
                                                helperText={this.errorText("firstName")}
                                            />
                                        </Grid>
                                    )}

                                {(type === "musician" ||
                                    type === "agent" ||
                                    type === "staff" ||
                                    type === "production" ||
                                    type === "dj" ||
                                    type === "consultant" ||
                                    type === "privateHire") && (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="lastName"
                                                label="Last Name *"
                                                margin="dense"
                                                fullWidth
                                                value={lastName === 'undefined' || lastName === undefined ? '' : lastName}
                                                onChange={this.handleLastNameChange}
                                                error={this.anyError("lastName")}
                                                helperText={this.errorText("lastName")}
                                            />
                                        </Grid>
                                    )}
                                {type !== "staff" && (
                                    <Grid item xs={12} md={formEmailWidth}>
                                        <TextField
                                            id="email"
                                            label="Email *"
                                            margin="dense"
                                            fullWidth
                                            value={email}
                                            onChange={this.handleEmailChange}
                                            onBlur={this.handleValidateEmail}
                                            error={this.anyError("email")}
                                            helperText={this.errorText("email")}
                                        />
                                        {type === "musician" || type === "act" || type === "agent" || type === "consultant"? (
                                            <div>
                                                <Switch checked={showEmail} onChange={this.handleShowEmailChange} value="showEmail" color="primary" />{" "}
                                                Show email in my public profile
                                            </div>
                                        ) : null}
                                    </Grid>
                                )}

                                {type !== "staff" && (
                                    <Grid item xs={12} md={formContactPhoneWidth}>
                                        <TextField
                                            id="contactPhone"
                                            label="Contact Phone"
                                            margin="dense"
                                            fullWidth
                                            value={contactPhone}
                                            onChange={this.handleContactPhoneChange}
                                            error={this.anyError("contactPhone")}
                                            helperText={this.errorText("contactPhone")}
                                            // InputProps={{
                                            //     inputComponent: this.phoneFormat
                                            // }}
                                        />
                                        {type === "musician" || type === "act" || type === "agent" || type === "consultant" ? (
                                            <div>
                                                <Switch
                                                    checked={showContactPhone}
                                                    onChange={this.handleShowContactPhoneChange}
                                                    value="showContactPhone"
                                                    color="primary"
                                                />{" "}
                                                Show contact phone in my public profile
                                            </div>
                                        ) : null}
                                    </Grid>
                                )}

                                {type === "venue" &&
                                    <Grid item xs={12}  style={{display:'flex', width:'100%'}} wrap="wrap" className="switch-venue-general">
                                        <div style={{marginRight:'5%'}}>
                                            <Switch
                                            checked={showEmail}
                                            onChange={this.handleShowEmailChange}
                                            value="setShowEmail"
                                            color="primary"
                                            />
                                            Show email on my public profile
                                        </div>
                                        <div>
                                            <Switch
                                            checked={showContactPhone}
                                            onChange={this.handleShowContactPhoneChange}
                                            value="setShowContactPhone"
                                            color="primary"
                                            />
                                            Show contact phone in my public profile
                                        </div>
                                    </Grid>
                                }

                                {type !== "staff" &&
                                    type !== "musician" &&
                                    type !== "agent" &&
                                    type !== "act"  &&
                                    type !== "privateHire" && (
                                        <Grid item xs={12} lg={formContactNameWidth}>
                                            <TextField
                                                id="contactName"
                                                label="Contact Name"
                                                margin="dense"
                                                fullWidth
                                                value={contactName}
                                                onChange={this.handleContactNameChange}
                                                error={this.anyError("contactName")}
                                                helperText={this.errorText("contactName")}
                                            />
                                        </Grid>
                                    )}
                                {type === "venue" && (
                                    <Grid item xs={12}>
                                        <SelectField
                                            id="venueTypes"
                                            placeholder="Select Venue Types"
                                            label="Venue Types"
                                            margin="dense"
                                            multi
                                            suggestions={venueTypes.map(venueType => {
                                                return {
                                                    value: venueType.id,
                                                    label: venueType.name
                                                };
                                            })}
                                            value={profileVenueTypes}
                                            onChange={this.handleChangeProfileVenueType}
                                        />
                                    </Grid>
                                )}

                                {type === "production" && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "baseline"
                                                }}
                                            >
                                                <InputLabel>Gear List</InputLabel>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={this.handleGearListDialogOpen}
                                                    className="button-small"
                                                >
                                                    <FullscreenIcon />
                                                    <Typography>Fullscreen</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {!this.state.gearListDialogOpen &&
                                                    gearList && <RichTextEditor value={gearList} onChange={this.handleGearListChange} />}
                                                {!this.state.gearListDialogOpen &&
                                                    !gearList && <RichTextEditor value={null} onChange={this.handleGearListChange} />}
                                                {this.state.gearListDialogOpen && (
                                                    <div className="rich-text-style">
                                                        <Typography>You are editing in fullscreen mode</Typography>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                {this.state.bioDialogOpen && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "baseline"
                                                }}
                                            >
                                                <InputLabel>Bio</InputLabel>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={this.handleBioDialogOpen}
                                                    className="button-small"
                                                >
                                                    <FullscreenIcon />
                                                    <Typography>Fullscreen</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>You are editing in fullscreen mode</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        { type === "privateHire" ? 
                             <Grid item xs={12} lg={3}>
                             <div className="sticky-information padded">
                                 <Typography variant="headline" component="h3" gutterBottom>
                                     Address
                                 </Typography>
                                 <Typography gutterBottom className="side-helper-text">
                                    Input your address here - this helps us to show acts near you.
                                 </Typography>
                                </div>
                            </Grid>
                            :
                            <Grid item xs={12} lg={3}>
                                <div className="sticky-information padded">
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Address
                                    </Typography>
                                    <Typography gutterBottom className="side-helper-text">
                                        {type === "staff" || type === "venue"
                                            ? "Enter your address here. We will use this address for google maps location and our gig guide app so please make sure you enter the correct one."
                                            : "Enter your address here. We will use this address for your invoices, so please make sure you enter the correct one."}
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        
                        <Grid item xs={12} lg={9}>
                            <hr style={{ marginTop: 0 }} />
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <GooglePlaceAutoComplete
                                        value={searchedAddress === 'null' ? "" : searchedAddress}
                                        label="Search Address"
                                        placeholder="Type venue name or address"
                                        handleChange={this.handleChangeSearchAddress}
                                        handleChangeStreet={this.handleStreetChange}
                                        handleChangeCity={this.handleCityChange}
                                        handleChangePostal={this.handlePostalChange}
                                        handleChangeState={this.handleStateChange}
                                        handleChangeCountry={this.handleCountryChange}
                                        handleChangeLatLng={this.handleChangeLongitudeLatitude}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <TextField
                                        id="street"
                                        label="Street Address"
                                        margin="dense"
                                        fullWidth
                                        value={street}
                                        onChange={this.handleStreetChange}
                                        error={this.anyError("street")}
                                        helperText={this.errorText("street")}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        id="city"
                                        label="Suburb"
                                        margin="dense"
                                        fullWidth
                                        value={city}
                                        onChange={this.handleCityChange}
                                        error={this.anyError("city")}
                                        helperText={this.errorText("city")}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        id="postal"
                                        label="Postal/Zip Code *"
                                        margin="dense"
                                        fullWidth
                                        value={postal}
                                        onChange={this.handlePostalChange}
                                        error={this.anyError("postal")}
                                        helperText={this.errorText("postal")}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        id="state"
                                        label="State"
                                        margin="dense"
                                        fullWidth
                                        value={state}
                                        onChange={this.handleStateChange}
                                        error={this.anyError("state")}
                                        helperText={this.errorText("state")}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={4} className="select-field-country">
                                    <SelectField
                                        id="countries"
                                        multi={false}
                                        suggestions={countriesSuggestion()}
                                        onChange={this.handleCountryChange}
                                        value={countryCode}
                                        placeholder=" "
                                        label="Country *"
                                        margin="dense"
                                        error={this.anyError("country")}
                                        helperText={this.errorText("country")}
                                    />
                                </Grid>

                                {longitude && latitude ? (
                                    <Grid item xs={12} style={{ height: 500 }}>
                                        <GoogleMaps
                                            isMarkerShown
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB7f0cc3w1EyMBqq0kn_fIvf6XPKzi8VWE&libraries=places"
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `400px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            longitude={Number(longitude)}
                                            latitude={Number(latitude)}
                                        />
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        {type !== "agent" &&
                            type !== "staff" &&
                            type !== "venue" &&
                            type !== "consultant" &&
                            type !== "privateHire" && (
                                <Fragment>
                                    <Grid item xs={12} lg={3}>
                                        <div className="sticky-information padded">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                {type === "act"
                                                    ? "Vibes, Genres and Gig Types"
                                                    : type === "musician"
                                                        ? "Skills, Genres and Gig Types"
                                                        : "Skills"}
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                {type === "act"
                                                    ? "Add all your act vibes, genres, and gig types here. These settings will allow people, agents and venues to find your act on the platform."
                                                    : type === "musician"
                                                        ? "Add all your skills, genres and gig types here. These settings will allow people, agents and venues to find you on the platform."
                                                        : "Add all your skills here. These settings will allow people, agents and venues to find you on the platform."}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <hr style={{ marginTop: 0 }} />
                                        <Grid container spacing={8}>
                                            {(type === "act") && (
                                                <Grid item xs={12}>
                                                    <Vibe id="vibes" />
                                                </Grid>
                                            )}
                                            {(type === "musician" || type === "dj") && (
                                                <Grid item xs={12}>
                                                    <Instrument id="instruments" setDefault={instrumentDefault} />
                                                </Grid>
                                            )}

                                            {(type === "musician" || type === "dj" || type === "act") && (
                                                <Grid item xs={12}>
                                                    <Genre id="genres" />
                                                </Grid>
                                            )}

                                            {type === "production" && (
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} lg={12}>
                                                        <SelectField
                                                            id="productionTypes"
                                                            multi={false}
                                                            suggestions={productionTypesSuggestion}
                                                            onChange={this.handleProductionTypeChange}
                                                            value={profileProductionType}
                                                            placeholder=" "
                                                            label="Production Type"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {(type === "act" || type === "musician") && (
                                                <Grid item xs={12}>
                                                    <GigType id="gigTypes" />
                                                </Grid>
                                            )}

                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        <Grid item xs={12} lg={3}>
                            {type !== "staff" &&
                                type !== "consultant" &&
                                type !== "privateHire" && (
                                    <div className="sticky-information padded">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Bio
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            {type === "agent"
                                                ? "A short bio will be displayed on your public profile. You can use this to get your story out to others, and maybe brag a bit about yourself. But make sure to keep it interesting!"
                                                : type === "act"
                                                    ? "A short bio will be displayed on your act public profile. You can use this to get your act story out to your fans, and maybe brag a bit about your act. But make sure to keep it interesting!"
                                                    : type === "musician" || type === "dj" || type === "production"
                                                        ? "A short bio will be displayed on your public profile. You can use this to get your story out to your fans and maybe brag a bit about yourself. But make sure to keep it interesting!"
                                                        : "A short bio will be displayed on your public profile. You can use this to get your story out to others about your venue all the amazing features that your venue offers!"}
                                        </Typography>
                                    </div>
                                )}
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            {type !== "staff" && type !== "consultant" && type !== "privateHire" && <hr style={{ marginTop: 0 }} />}
                            <Grid container spacing={8}>
                                {type !== "staff" &&
                                    type !== "consultant" && type !== "privateHire" &&
                                    !isLoading && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "baseline"
                                                    }}
                                                >
                                                    <InputLabel shrink={true}>Bio</InputLabel>
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        onClick={this.handleBioDialogOpen}
                                                        className="button-small fullscreen-bio"
                                                    >
                                                        <FullscreenIcon style={{ color: "#222" }} />
                                                        <Typography>Fullscreen</Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {!this.state.bioDialogOpen &&
                                                        bio && <RichTextEditor value={bio} onChange={this.handleBioChange} />}
                                                    {!this.state.bioDialogOpen &&
                                                        !bio && <RichTextEditor value={null} onChange={this.handleBioChange} />}
                                                    {this.state.bioDialogOpen && (
                                                        <div className="rich-text-style">
                                                            <Typography>You are editing in fullscreen mode</Typography>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                        {festivalCode && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information padded">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Festival
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            Give a title and a short description about your festival. This will show up in your agency public profile
                                            where musician can signup to perform.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <hr style={{ marginTop: 0 }} />
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                id="festivalTitle"
                                                label="Festival Title"
                                                margin="dense"
                                                fullWidth
                                                value={festivalTitle}
                                                onChange={this.handleFestivalTitleChange}
                                                error={this.anyError("festivalTitle")}
                                                helperText={this.errorText("festivalTitle")}
                                            />
                                        </Grid>
                                        {!isLoading && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={0}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "baseline"
                                                        }}
                                                    >
                                                        <InputLabel shrink={true}>Festival Description</InputLabel>
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            onClick={this.handleFestivalDescriptionDialogOpen}
                                                            className="button-small fullscreen-bio"
                                                        >
                                                            <FullscreenIcon style={{ color: "#222" }} />
                                                            <Typography>Fullscreen</Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {!this.state.festivalDescriptionDialogOpen &&
                                                            festivalDescription && (
                                                                <RichTextEditor
                                                                    value={festivalDescription}
                                                                    onChange={this.handleFestivalDescriptionChange}
                                                                />
                                                            )}
                                                        {!this.state.festivalDescriptionDialogOpen &&
                                                            !festivalDescription && (
                                                                <RichTextEditor value={null} onChange={this.handleFestivalDescriptionChange} />
                                                            )}
                                                        {this.state.festivalDescriptionDialogOpen && (
                                                            <div className="rich-text-style">
                                                                <Typography>You are editing in fullscreen mode</Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {festivalCode ? (
                                            <Grid item xs={12} lg={12}>
                                                <TextField
                                                    id="festivalRegistrationUrl"
                                                    label="Festival Registration Url"
                                                    margin="dense"
                                                    fullWidth
                                                    value={festivalRegistrationUrl}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <Button onClick={this.handleCopy}>Copy</Button>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        <Grid item xs={12} lg={12}>
                            <hr style={{ marginTop: 0 }} />
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <div className="action">
                                        {editProfileActiveStep > 0 && (
                                            <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                                Back
                                            </Button>
                                        )}
                                        <Button variant="contained" color="primary" type="submit" id="saveProfile">
                                            {editProfileActiveStep === steps.length - 1 ? "Finish" : "Save/Next"}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                <Dialog
                    fullScreen
                    open={this.state.bioDialogOpen}
                    onClose={this.handleBioDialogClose}
                    TransitionComponent={this.transition}
                    className="dialog-div"
                >
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Bio
                            </Typography>
                            <Button color="inherit" onClick={this.handleBioDialogClose}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        <RichTextEditor value={bio} onChange={this.handleBioChange} />
                    </div>
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.festivalDescriptionDialogOpen}
                    onClose={this.handleFestivalDescriptionDialogClose}
                    TransitionComponent={this.transition}
                    className="dialog-div"
                >
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Festival Description
                            </Typography>
                            <Button color="inherit" onClick={this.handleFestivalDescriptionDialogClose}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        <RichTextEditor value={festivalDescription} onChange={this.handleFestivalDescriptionChange} />
                    </div>
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.gearListDialogOpen}
                    onClose={this.handleGearListDialogClose}
                    transition={this.transition}
                    className="dialog-div"
                >
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Gear List
                            </Typography>
                            <Button color="inherit" onClick={this.handleGearListDialogClose}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        <RichTextEditor value={gearList} onChange={this.handleGearListChange} />
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default General;
