import { hgStripe } from "./StripeConfig.js";

import { ProfileType } from "../../types/enum";



export function getProductType(userProfileType, plan) {
    switch(userProfileType) {
        case ProfileType.Musician.ordinal: //"entertainerMembershipFree":
            if(plan.id == hgStripe.pricing.entertainerMembershipFree){
                return {
                    planId: hgStripe.products.entertainerMembershipFree,
                    planName: hgStripe.productName.entertainerMembershipFree,
                }
            }else if(plan.id == hgStripe.pricing.entertainerMembershipPro){
                return {
                    planId: hgStripe.products.entertainerMembershipPro,
                    planName: hgStripe.productName.entertainerMembershipPro,
                }
            }
           
        case ProfileType.Agent.ordinal: // "agentMembershipMonthly":
            if(plan.id == hgStripe.pricing.agentMembershipMonthly){
                return {
                    planId: hgStripe.products.agentMembershipMonthly,
                    planName: hgStripe.productName.agentMembershipMonthly,
                }
            }else if(plan.id == hgStripe.pricing.agentMembershipProMonthly){
                return {
                    planId: hgStripe.products.agentMembershipProMonthly,
                    planName: hgStripe.productName.agentMembershipProMonthly,
                }
            }
        case 3: //"singleVenueMembership":
            if(plan.id == hgStripe.pricing.singleVenueMembershipMonth){
                return {
                    planId: hgStripe.products.singleVenueMembershipMonth,
                    planName: hgStripe.productName.singleVenueMembershipMonth,
                }
            }else if(plan.id == hgStripe.pricing.singleVenueProMembershipMonth){
                return {
                    planId: hgStripe.products.singleVenueProMembershipMonth,
                    planName: hgStripe.productName.singleVenueProMembershipMonth,
                }
            }else if(plan.id == hgStripe.pricing.venueGroupManualInvoice){
                return {
                    planId: hgStripe.products.venueGroupManualInvoice,
                    planName: hgStripe.productName.venueGroupManualInvoice,
                }
            }
        default:
            return {
                planId: "",
                planName: "",
            }     

      }   
}

export function  getProductTypeArray(userProfileType){
    switch(userProfileType) {
        case 1: //"entertainerMembershipFree":
            return [{
                planId: config.hgStripe.products.entertainerMembershipFree,
                planName: config.hgStripe.productName.entertainerMembershipFree,
            },{
                planId: config.hgStripe.products.entertainerMembershipPro,
                planName: config.hgStripe.productName.entertainerMembershipPro,
            }
        ]
        case 2: // "agentMembershipMonthly":
            return [{
                planId: config.hgStripe.products.agentMembershipMonthly,
                planName: config.hgStripe.productName.agentMembershipMonthly,
            },{
                planId: config.hgStripe.products.agentMembershipProMonthly,
                planName: config.hgStripe.productName.agentMembershipProMonthly,
            }]
        case 3: //"singleVenueMembership":
                return [{
                    planId: config.hgStripe.products.singleVenueMembershipMonth,
                    planName: config.hgStripe.productName.singleVenueMembershipMonth,
                },{
                    planId: config.hgStripe.products.singleVenueProMembershipMonth,
                    planName: config.hgStripe.productName.singleVenueProMembershipMonth,
                }]
        case 4: //"singleVenueMembership":
                return [{
                    planId: config.hgStripe.products.venueGroupManualInvoice,
                    planName: config.hgStripe.productName.venueGroupManualInvoice,
                }]
        default:
            return [{
                planId: "",
                planName: "",
            }]

      }   
}


export function isMusician(userProfileType) { return userProfileType == ProfileType.Musician.ordinal; }
export function isAgent(userProfileType) { return userProfileType == ProfileType.Agent.ordinal; }
export function isVenueStaff(userProfileType) { return userProfileType == ProfileType.Venue.ordinal; }



export function getPricingIdArray(type, isHarOrg) {
    
    var plan = [];
    switch(type) {
        case 1: 
            plan.push(hgStripe.pricing.entertainerMembershipFree);
            plan.push(hgStripe.pricing.entertainerMembershipPro);
          return plan;
        case 2: 
            plan.push(hgStripe.pricing.agentMembershipMonthly);
            plan.push(hgStripe.pricing.agentMembershipProMonthly);
          return plan;
        case 3:
            if(isHarOrg) {
                plan.push(hgStripe.pricing.venueGroupManualInvoice);
                return plan
            }
            else {
                plan.push(hgStripe.pricing.singleVenueMembershipMonth);
                plan.push(hgStripe.pricing.singleVenueProMembershipMonth);
                return plan;
            }
        case 9:
            plan.push(hgStripe.pricing.privateHirerFeePerGig);
            return plan;
        default:
            return  plan;
      }   
}
export function getPricingId(userType,isHarOrg) {
    switch (userType) {
        case 1:
            return hgStripe.pricing.entertainerMembershipFree;
        case 2:
            return hgStripe.pricing.agentMembershipMonthly;
        case 3:
            if(isHarOrg) return hgStripe.pricing.venueGroupManualInvoice;
            else return hgStripe.pricing.singleVenueMembershipMonth;
        case 9:
            return hgStripe.pricing.privateHirerFeePerGig;
        default:
            return  "";
    }
} 