export const hgStripe = {
    // apiKey: process.env.REACT_APP_STRIPE_API_KEY,
    publishableKey: "pk_test_51JbamdCXPbYmDHXyghJmRyJFOI3njDydQ6gSTnuOGNib0NtmiC6ANWYWcWekP2IvUfeCxjWJfhSAbgNUoiREMJpP00OVMqPrOQ",
    products : {
        privateHirerFeePerGig : process.env.REACT_APP_STRIPE_PLAN_PRIVATE_HIRER_FEE_PER_GIG,
        singleVenueFreeMembershipMonth : process.env.REACT_APP_STRIPE_PLAN_SINGLE_VENUE_MONTHLY_FREE,
        singleVenueMembershipMonth : process.env.REACT_APP_STRIPE_PLAN_SINGLE_VENUE_MEMBERSHIP_MONTH,
        singleVenueProMembershipMonth : process.env.REACT_APP_STRIPE_PLAN_SINGLE_VENUE_PRO_MEMBERSHIP_MONTH,
        entertainerMembershipFree: process.env.REACT_APP_STRIPE_PLAN_ENTERTAINER_BASIC_MEMBERSHIP_FREE,
        singleVenueMembershipPerGigBilledMonthly: process.env.REACT_APP_STRIPE_PLAN_SINGLE_VENUE_MEMBERSHIP_PER_GIG_BILLED_MONTHLY,
        venueGroupManualInvoice : process.env.REACT_APP_STRIPE_PLAN_VENUE_GROUP_MANUAL_INVOICE,
        agentMembershipMonthly : process.env.REACT_APP_STRIPE_PLAN_AGENT_MEMBERSHIP_MONTHLY,
        entertainerMembershipPro : process.env.REACT_APP_STRIPE_PLAN_ENTERTAINER_PRO_MEMBERSHIP_FREE,
        agentMembershipProMonthly : process.env.REACT_APP_STRIPE_PLAN_AGENT_PRO_MEMBERSHIP_MONTHLY

    },
    pricing : {
        privateHirerFeePerGig : process.env.REACT_APP_STRIPE_PRICE_PRIVATE_HIRER_FEE_PER_GIG,
        // single venue
        singleVenueFreeMembershipMonth : process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_MONTHLY_LEGACY,
        singleVenueMonthlyLegacy : process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_MONTHLY_FREE,
        singleVenueMembershipMonth : process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_MEMBERSHIP_MONTH,
        singleVenueProMembershipMonth : process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_PRO_MEMBERSHIP_MONTH,
        singleVenueMembershipPerGigBilledMonthly: process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_MEMBERSHIP_PER_GIG_BILLED_MONTHLY,
        singleVenueProMembershipPerGigBilledMonthly: process.env.REACT_APP_STRIPE_PRICE_SINGLE_VENUE_PRO_MEMBERSHIP_PER_GIG_MONTHLY,
        //venue group
        venueGroupManualInvoice : process.env.REACT_APP_STRIPE_PRICE_VENUE_GROUP_MANUAL_INVOICE,
        //agent
        agentMembershipMonthly : process.env.REACT_APP_STRIPE_PRICE_AGENT_MEMBERSHIP_MONTHLY,
        agentMembershipProMonthly : process.env.REACT_APP_STRIPE_PRICE_AGENT_PRO_MEMBERSHIP_MONTHLY,
        agentMembershipPayAsYouGo : process.env.REACT_APP_STRIPE_PRICE_AGENT_MEMBERSHIP_PAY_AS_YOU_GO,
        agentMembershipProMonthlyPayAsYouGo : process.env.REACT_APP_STRIPE_PRICE_AGENT_MEMBERSHIP_PRO_PAY_AS_YOU_GO,
        //entertainer
        entertainerMembershipPro: process.env.REACT_APP_STRIPE_PRICE_ENTERTAINER_PRO_MEMBERSHIP_FREE,
        entertainerMembershipFree: process.env.REACT_APP_STRIPE_PRICE_ENTERTAINER_BASIC_MEMBERSHIP_FREE,
        entertainerProPlusMembership: process.env.REACT_APP_STRIPE_PRICE_ENTERTAINER_PRO_PLUS_MEMBERSHIP_FREE,
    },
    productName : {
        privateHirerFeePerGig : "Parties & Events Membership",
        singleVenueMembershipMonth : "Single Venue Membership",
        singleVenueProMembershipMonth : "Single Venue Pro Membership",
        entertainerMembershipFree: "Entertainer Basic Membership",
        singleVenueMembershipPerGigBilledMonthly: "Single Venue Membership - Per Gig",
        venueGroupManualInvoice :"Venue Group Membership",
        agentMembershipMonthly : "Agent Membership",
        agentMembershipProMonthly : "Agent Pro Membership",
        entertainerMembershipPro: "Entertainer Pro Membership"

    },
}

