import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Checkbox, Hidden, Typography, DialogContent, DialogActions, Button } from "@material-ui/core";

import { Calendar, momentLocalizer  } from 'react-big-calendar' 
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import Heading from "../Heading";
import BookingCalendarFilter from "./bookingCalendarFilter";
import BookingDialog from "./bookingDialog";
import { withRouter } from "react-router-dom";

import { ProfileType } from "../../types/enum";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CustomCalendarToolbar from "../calendar/custom-toolbar";
import CustomEvent from "../../components/calendar/CustomEvent";
import CustomAgendaEvent from "../../components/calendar/CustomAgendaEvent";

import Loading from "../../components/loadingSpinner/Loading";

import "./BookingCalendar.css";
import ModalContainer from "../modal/ModalContainer";

moment.locale("ko", {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment)

// let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

const formatDate = 'YYYY-MM-DD';
@withRouter
@inject("templateStore", "profileStore", "agentVenueStore", "agentActStore", "bookingRequestStore", "eventStore", "venueActStore", "venueStore", "googleCalendarStore")
@observer
class BookingCalendars extends Component {
    state = {
        open: false,
        venueEvents: [],
        actEvents: [],
        selectedVenue: undefined,
        selectedAct: undefined,
        useVenueActRoster: false,
        isLoading: true,
        preventBooking: false,
        isVenueLoading: true,
        isActLoading: true,
        actId: undefined,
        venueId: undefined,
        currentDate: new Date(),
        startDate: moment().startOf('month').format(formatDate),
        endDate: moment().endOf('month').format(formatDate)
    };

    venuesToolbar = undefined;
    actToolbar = undefined;

    // constructor(props) {
    //     super(props);
    //     this.props.agentActStore.resetData();
    //     this.props.agentVenueStore.resetData();
    //     this.props.venueActStore.resetList();
    //     this.props.venueStore.resetVenues();
    //     this.props.googleCalendarStore.resetGoogleCalendarEvent();
    //     this.state = {
    //         open: false,
    //         venueEvents: [],
    //         actEvents: [],
    //         selectedVenue: undefined,
    //         selectedAct: undefined,
    //         useVenueActRoster: false,
    //         isLoading: true,
    //         preventBooking: false,
    //         isVenueLoading: true,
    //         isActLoading: true,
    //         startDate: moment().startOf('month').format(formatDate),
    //         endDate: moment().endOf('month').format(formatDate)
    //     };
    // }

    componentDidMount() {
        this.props.agentActStore.resetData();
        this.props.agentVenueStore.resetData();
        this.props.venueActStore.resetList();
        this.props.venueStore.resetVenues();
        this.props.googleCalendarStore.resetGoogleCalendarEvent();
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { actId, venueId } = this.props
        this.setState({
            ...this.state,
            actId: actId,
            venueId: venueId
        });

        this.props.agentActStore.resetAgentActs();

        //if agent
        if (currentProfile && currentProfile?.type === ProfileType.Agent.ordinal) {

            Promise.all([
                this.props.agentVenueStore.getAgentVenueSimple(currentProfile?.id), 
                this.props.agentActStore.getAgentActRosterSimple(currentProfile?.id),
                this.props.agentActStore.getAgentMyActs(currentProfile?.id)
            ]).then(() => {
                if (actId && actId != undefined && actId != "undefined") {
                    this.handleSelectAct(parseInt(actId));
                }
                if (venueId && venueId != undefined && actId != "undefined") {
                    this.handleSelectVenue(parseInt(venueId));
                }
                this.setState({
                    ...this.state,
                    isVenueLoading: false,
                    isActLoading: false
                });
            });

            //consultant
        } else if (currentProfile && currentProfile?.isConsultant) {
            Promise.all([this.props.agentVenueStore.getConsultantVenueRoster(currentProfile?.id), this.props.agentActStore.getConsultantActRoster(currentProfile?.id)]).then(() => {
                if (actId && actId != undefined && actId != "undefined") {
                    this.handleSelectAct(parseInt(actId));
                }
                if (venueId && venueId != undefined && actId != "undefined") {
                    this.handleSelectVenue(parseInt(venueId));
                }
                this.setState({
                    ...this.state,
                    isVenueLoading: false,
                    isActLoading: false
                });
            });
        }

        //if staff
        else if (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal) {
            Promise.all([
                this.props.venueStore.getUserBookableVenues().then(venues => {
                    if (venues.length === 0) {
                        this.setState({
                            ...this.state,
                            preventBooking: true
                        });
                    }
                }),
                this.props.venueActStore.getStaffActRosterInvoice(null, true)
            ]).then(() => {
                if (actId && actId != undefined && actId != "undefined") {
                    this.handleSelectAct(parseInt(actId));
                }
                if (venueId && venueId != undefined && actId != "undefined") {
                    this.handleSelectVenue(parseInt(venueId));
                }
                this.setState({
                    ...this.state,
                    isVenueLoading: false,
                    isActLoading: false
                });
            });
        }
    }

    componentWillUnmount() {
        this.props.bookingRequestStore.resetMyBooking();
        this.props.bookingRequestStore.resetMyActBooking();
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClickEvent = event => {
        if (event.type === "availability" || event.type === "google-calendar") {
            return false;
        } else if (event.type === "booking") {
            this.props.bookingRequestStore.getBookingSummary(event.id).finally(() => {
                this.setState({ open: true });
            });
        }
    };

    handleClickSlot = slotInformation => {
        let { selectedAct, selectedVenue } = this.state;
        //if(!selectedVenue || ! selectedAct) return false;

        // if (selectedVenue && !selectedAct) {
        //     this.props.templateStore.openSnackbar("Please Select Act.");
        //     return false;
        // }

        if (!selectedVenue && selectedAct) {
            this.props.templateStore.openSnackbar("Please Select Venue.");
            return false;
        }

        let days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        if (selectedVenue) {
            var agentVenue = this.props.agentVenueStore.agentVenues.filter(agentVenue => agentVenue.venueId === selectedVenue)[0];
            if (agentVenue && !agentVenue[days[moment(slotInformation.start).isoWeekday()]]) {
                this.props.templateStore.openSnackbar("Can't create booking on selected date.");
                return false;
            }
        }

        if (moment(slotInformation.start).startOf("day") < moment().startOf("day")) {
            this.props.templateStore.openSnackbar("Can't create booking on past day.");
            return false;
        }

        this.props.history.push(
            "/booking/new?actId=" + selectedAct + "&venueId=" + selectedVenue + "&dateStart=" + moment(slotInformation.start).format("DD-MM-YYYY")
        );
    };

    handleSelectVenue = value => {
        const { currentProfile } = this.props.profileStore;
        const {startDate, endDate, currentDate} = this.state
        this.setState({
            ...this.state,
            selectedVenue: value,
            isVenueLoading: true,
            isActLoading: true
        });
        if (value) {
            Promise.all([
                this.props.bookingRequestStore.loadMyBookingPagination(value, "venue",startDate,endDate),
                this.props.googleCalendarStore.loadCalendarProfileVenue(value,startDate, endDate),this.props.eventStore.loadEventsByProfileIdVenue(value),
                this.props.venueActStore.getVenueActRosterSimple(value).then(response => {
                    let actIds = response.map(agentAct => agentAct.act.id);
                    
                    if (actIds.length > 0) {
                        this.props.eventStore.loadEventsByProfileIds(actIds);
                        // this.props.bookingRequestStore.loadMyBookingByProfileIds(actIds, "act");
                    }else{
                        this.setState({
                            ...this.state,
                            selectedAct: undefined
                        })
                    }
                })
            ]).then(() => {
                this.setState({
                    ...this.state,
                    isVenueLoading: false,
                    isActLoading: false
                });
            });
        } else {
            if (currentProfile && (currentProfile?.type === ProfileType.Agent.ordinal)) {
                this.props.bookingRequestStore.resetMyBooking()
                if (this.state.selectedAct == undefined) {
                    this.props.bookingRequestStore.resetMyActBooking()
                }
                    this.setState({
                        ...this.state,
                        isVenueLoading: false,
                        isActLoading: false,
                        selectedVenue: undefined
                    });
                // this.props.bookingRequestStore.loadMyBooking(null, "agent").then(() => {
                // });
            } else if (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal) {
                this.props.bookingRequestStore.resetMyBooking()
                if (this.state.selectedAct == undefined) {
                    this.props.bookingRequestStore.resetMyActBooking()
                }
                this.setState({
                    ...this.state,
                    isVenueLoading: false,
                    isActLoading: false,
                    selectedVenue: undefined
                });
                // Promise.all([
                //     this.props.bookingRequestStore.loadMyBooking(null, "staff"),
                //     this.props.venueActStore.getStaffActRoster(null, true).then(response => {
                //         if(response){
                //             let actIds = response.map(agentAct => agentAct.act.id);
                //             this.props.eventStore.loadEventsByProfileIds(actIds);
                //             this.props.bookingRequestStore.loadMyBookingByProfileIds(actIds, "act");
                //         }
                        
                //     })
                // ]).then(() => {
                //     this.setState({
                //         ...this.state,
                //         isVenueLoading: false,
                //         isActLoading: false
                //     });
                // });
            }
        }
    };
    navigateVenue = (startDate,endDate) => {
        const {selectedVenue} = this.state
        
        if (selectedVenue) {
            this.setState({
                ...this.state,
                isVenueLoading: true
            });
            Promise.all([this.props.googleCalendarStore.loadCalendarProfileVenue(selectedVenue,startDate, endDate)
                ,  this.props.eventStore.loadEventsByProfileIdVenue(selectedVenue),
                this.props.bookingRequestStore.loadMyBookingPagination(selectedVenue, "venue",startDate, endDate)]).then(() => {
                    this.setState({
                        ...this.state,
                        isVenueLoading: false
                    });
            });
        } 
    }
    navigateAct = (startDate,endDate) => {
        const {selectedAct} = this.state
        if (selectedAct) {
            this.setState({
                isActLoading: true
            });
            Promise.all([this.props.googleCalendarStore.loadCalendarProfile(selectedAct,startDate, endDate),
                this.props.bookingRequestStore.loadMyBookingRelatedAct(selectedAct,startDate, endDate),  this.props.eventStore.loadEventsByProfileId(selectedAct),
                this.props.bookingRequestStore.loadMyBookingPagination(selectedAct, "act",startDate, endDate)]).then(() => {
                    this.setState({
                        isActLoading: false,
                        startDate: startDate,
                        endDate: endDate,
                    });
            });
        }else{
            this.setState({
                startDate: startDate,
                endDate: endDate
            });
        }
    }
    handleSelectAct = value => {
        const { currentProfile } = this.props.profileStore;
        const {startDate, endDate} = this.state
        this.setState({
            ...this.state,
            selectedAct: value,
            isActLoading: true
        });
        // if(currentProfile.activateGoogleCalendar && currentProfile.googleRefreshToken){
        //     this.props.googleCalendarStore.loadCalendarProfile(currentProfile.id,startDate, endDate)
        // }
        if (value) {
            Promise.all([this.props.bookingRequestStore.loadMyBookingPagination(value, "act",startDate, endDate),this.props.bookingRequestStore.loadMyBookingRelatedAct(value,startDate, endDate),  this.props.eventStore.loadEventsByProfileId(value),   this.props.googleCalendarStore.loadCalendarProfile(value,startDate, endDate)]).then(() => {
                this.setState({
                    ...this.state,
                    isActLoading: false
                });
            });
        } else {
            let actIds = [];
            if (currentProfile && currentProfile?.type === ProfileType.Agent.ordinal) {
                actIds = this.props.agentActStore.agentActs.map(agentAct => agentAct.act.id);
            } else if (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal) {
                actIds = this.props.venueActStore.venueActRoster.map(venueAct => venueAct.act.id);
            }
            this.props.eventStore.resetEvents()
            this.props.bookingRequestStore.resetMyActBooking()
            
            if (this.state.selectedVenue == undefined) {
                this.props.bookingRequestStore.resetMyBooking()
            }

            this.setState({
                ...this.state,
                isVenueLoading: false,
                isActLoading: false,
                selectedAct: undefined
            });

            // Promise.all([
            //     this.props.bookingRequestStore.loadMyBookingByProfileIds(actIds, "act"),
            //     this.props.eventStore.loadEventsByProfileIds(actIds)
            // ]).then(() => {
            //     this.setState({
            //         ...this.state,
            //         isActLoading: false
            //     });
            // });
        }
    };

    changeActRosterSuggestion = (e, checked) => {
        this.setState(
            {
                ...this.state,
                useVenueActRoster: checked,
                selectedAct: undefined
            },
            () => {
                if (checked) {
                    this.props.venueActStore.getVenueActRosterSimple(this.state.selectedVenue).then(() => {
                        let actIds = this.props.venueActStore.venueActRoster.map(venueAct => venueAct.act.id);
                        this.props.eventStore.loadEventsByProfileIds(actIds);
                    });
                } else {
                    Promise.all([
                        this.props.agentActStore.getAgentActRosterSimple(),
                        this.props.agentActStore.getAgentMyActs()
                    ])
                    .then(() => {
                        let actIds = this.props.agentActStore.agentActs.map(agentAct => agentAct.act.id);                        
                        this.props.eventStore.loadEventsByProfileIds(actIds);
                    });
                }
            }
        );
    };

    venueSuggestions = [];

    actSuggestions = [];

    mapVenueSuggestions = () => {
        const { currentProfile } = this.props.profileStore;

        if (currentProfile && (currentProfile?.type === ProfileType.Agent.ordinal)) {

            this.venueSuggestions = this.props.agentVenueStore.agentVenues.filter(x=>x.venue!==null).map(data => {
                    return {
                        value: data.venueId,
                        label: data.venue.fullName
                    };
            });

        
            // TODO: need to check data.act !== undefined
            if (this.state.useVenueActRoster) {
                this.actSuggestions = this.props.venueActStore.venueActRoster.filter(x => x.act.isCompleted).map(data => {
                    return {
                        value: data.act.id,
                        label: data.act.fullName
                    };
                });
            } else {
                this.actSuggestions = this.props.agentActStore.agentActs.filter(x => x.act.isCompleted).map(data => {
                    return {
                        value: data.act.id,
                        label: data.act.fullName
                    };
                });
            }
        } else if (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal) {
            const { venues } = this.props.venueStore;
            if (venues && venues.length > 0) {
                this.venueSuggestions = venues.filter(x=>x.venue!==null).map(data => {
                    return {
                        value: data.id,
                        label: data.fullName
                    };
                });
            }

            this.actSuggestions = this.props.venueActStore.venueActRoster.filter(x => x.act.isCompleted).map(data => {
                return {
                    value: data.act.id,
                    label: data.act.actName
                };
            });
        }

        this.actSuggestions = _.uniqBy(this.actSuggestions, "value")
    };

    checkDisabledDate = date => {
        let { selectedVenue } = this.state;

        let days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        if (selectedVenue) {
            var agentVenue = this.props.agentVenueStore.agentVenues.filter(agentVenue => agentVenue.venueId === selectedVenue)[0];
            if (agentVenue && !agentVenue[days[moment(date).isoWeekday()]]) {
                return true;
            }
        }

        if (moment(date) < moment().startOf("day")) {
            return true;
        }
        return false;
    };

    customDayPropGetter = date => {
        if (this.checkDisabledDate(date)) {
            return {
                className: "disabled-date"
            };
        } else {
            return {};
        }
    };

    goToBack = () => {
        this.venuesToolbar.onNavigate("PREV");
        this.actToolbar.onNavigate("PREV");
    };

    goToNext = () => {
        this.venuesToolbar.onNavigate("NEXT");
        this.actToolbar.onNavigate("NEXT");
    };

    goToCurrent = () => {
        this.venuesToolbar.onNavigate("TODAY");
        this.actToolbar.onNavigate("TODAY");
    };

    handleCalendarOnViewChange = value => {
        this.venuesToolbar.onView(value);
        this.actToolbar.onView(value);
    };

    handleNavigateAct = (newDate, view, action) => {
    
        const typeView = 'month';
        const dateMoment = moment(newDate);
        const startDate = dateMoment.startOf(typeView).format(formatDate);
        const endDate   = dateMoment.endOf(typeView).format(formatDate);
        this.navigateAct(startDate,endDate);
        // this.navigateVenue(startDate,endDate);
        // get agenda data
        // this.getBookingData(startDate,endDate);
    }

    handleNavigateVenue = (newDate, view, action) => {
    
        const typeView = 'month';
        const dateMoment = moment(newDate);
        const startDate = dateMoment.startOf(typeView).format(formatDate);
        const endDate   = dateMoment.endOf(typeView).format(formatDate);
        this.navigateVenue(startDate,endDate);
        // this.navigateAct(startDate,endDate);
        // get agenda data
        // this.getBookingData(startDate,endDate);
    }

  

    handleView = (view) => {
        // only handle agenda view
        // agenda view showing all agenda in current date of month
        const dateMoment = moment(this.state.currentDate);
        const startDate = dateMoment.startOf('month').format(formatDate);
        const endDate   = dateMoment.endOf('month').format(formatDate);
        
        this.setState({
            ...this.state,
            startDate: startDate,
            endDate: endDate,
            isLoading: true
        });
        
        // get agenda data
        // this.getBookingData(startDate, endDate);
    }
    renderLegends = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();

        let legendArrays = [
            { label: "Draft", color: "#64b5f6" },
            { label: "Sent", color: "#1565c0" },
            { label: "Negotiate", color: "#ff0c9c" },
            { label: "In Progress", color: "#1b5e20" },
            { label: "Accepted", color: "#43a047" },
            { label: "Cancelled", color: "#000000" },
            { label: "Unavailable", color: "#696969" }
        ];

        if (
            currentProfile?.type === ProfileType.Agent.ordinal ||
            currentProfile?.type === ProfileType.Staff.ordinal ||
            currentProfile?.type === ProfileType.Venue.ordinal
        ) {
            legendArrays.push({ label: "Declined", color: "#b71c1c" });
            legendArrays.push({ label: "Resent", color: "#b19cd9" });
        }

        return legendArrays.map(x => (
            <Grid item xs={3} lg={1} key={x.label}>
                <Typography variant="caption" color="inherit" style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ backgroundColor: x.color, width: "15px", height: "15px", display: "inline-block", borderRadius: "10px" }}>&nbsp;</span><p style={{ color: "white", marginBottom: 0, marginLeft: "3px" }}>{x.label}</p>
                </Typography>
            </Grid>
        ));
    };



    render() {
        const { myBooking, myActBooking, relatedBookings} = this.props.bookingRequestStore;
        const { selectedAct, selectedVenue, actId, venueId } = this.state;
        const { events,venueEventsList } = this.props.eventStore;
        const { currentProfile } = this.props.profileStore;
        const { googleCalendarEvent,googleCalendarEventVenue } = this.props.googleCalendarStore


        let venueComponents = {
            toolbar: toolbar => {
                this.venuesToolbar = toolbar;
                return (
                    <CustomCalendarToolbar
                        toolbar={this.venuesToolbar}
                        goNextListener={this.goToNext}
                        goBackListener={this.goToBack}
                        goCurrentListener={this.goToCurrent}
                        viewChangeListener={value => this.handleCalendarOnViewChange(value)}
                    />
                );
            },
            event: event => <CustomEvent data={event} />,
            agenda: {
                event: event => <CustomAgendaEvent data={event} clickHandler={() => this.handleClickEvent(event.event)} />
            }
        };

        let actComponents = {
            toolbar: toolbar => {
                this.actToolbar = toolbar;
                return (
                    <CustomCalendarToolbar
                        toolbar={this.actToolbar}
                        goNextListener={this.goToNext}
                        goBackListener={this.goToBack}
                        goCurrentListener={this.goToCurrent}
                        viewChangeListener={value => this.handleCalendarOnViewChange(value)}
                    />
                );
            },
            event: event => <CustomEvent data={event} />,
            agenda: {
                event: event => <CustomAgendaEvent data={event} clickHandler={() => this.handleClickEvent(event.event)} />
            }
        };

        this.mapVenueSuggestions();

        let venueEvents = this.props.bookingRequestStore.generateBookings(myBooking);

        //TODO: should combine act's booking event with act's availability event
        // let combinedActEvents = events;

        let bookingEvents = this.props.bookingRequestStore.generateBookings(myActBooking);
        // if (selectedAct) {
        //     bookingEvents = bookingEvents.map(booking => {
        //         if (booking.actId !== selectedAct) {
        //             booking.type = "availability";
        //             booking.status = "";
        //         }
        //         return booking;
        //     });
        // }

        for (let i = 0; i < events.length; i++) {
            bookingEvents.push(events[i]);
        }
        for (let i = 0; i < venueEventsList.length; i++) {
            venueEvents.push(venueEventsList[i]);
        }
        for (let i = 0; i < googleCalendarEvent.length; i++) {
            bookingEvents.push(googleCalendarEvent[i]);
        }
        for (let i = 0; i < relatedBookings.length; i++) {
            bookingEvents.push(relatedBookings[i]);
        }

        for (let i = 0; i < googleCalendarEventVenue.length; i++) {
            venueEvents.push(googleCalendarEventVenue[i]);
        }
        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Hidden mdUp>
                            <Grid container alignItems="center" style={{ paddingLeft: 4 }}>
                                {this.renderLegends()}
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid container justify="flex-end" alignItems="center">
                                {this.renderLegends()}
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Hidden mdUp>
                            <Heading title="Venues Calendar" bottomAction={true}>
                                <BookingCalendarFilter
                                    className="slim-input"
                                    id="bookingVenue"
                                    suggestions={this.venueSuggestions}
                                    onChange={this.handleSelectVenue}
                                    value={selectedVenue}
                                    placeholder="Select a Venue"
                                />
                            </Heading>
                        </Hidden>
                        <Hidden smDown>
                            <Heading title="Venues Calendar">
                                <BookingCalendarFilter
                                    id="bookingVenue"
                                    suggestions={this.venueSuggestions}
                                    onChange={this.handleSelectVenue}
                                    value={selectedVenue}
                                    placeholder="Select a Venue"
                                />
                            </Heading>
                        </Hidden>

                        <p className="header-info">
                            <small style={{ color: "white" }}>Select a Venue to see its availability</small>
                        </p>
                        <div style={{ position: "relative", minHeight: "200px" }}>
                            <Loading showed={this.state.isVenueLoading} borderRadius="0" />
                            <Calendar
                                localizer={localizer}
                                selectable
                                events={venueEvents}
                                step={30}
                                longPressThreshold={100}
                                defaultDate={new Date()}
                                className="my-calendar"
                                components={venueComponents}
                                onSelectEvent={this.handleClickEvent}
                                onSelectSlot={this.handleClickSlot}
                                dayPropGetter={this.customDayPropGetter}
                                onNavigate={this.handleNavigateVenue}
                                showMultiDayTimes={true}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Hidden mdUp>
                            <Heading title="Acts Calendar" bottomAction={true}>
                                <BookingCalendarFilter
                                    id="bookingAct"
                                    suggestions={this.actSuggestions}
                                    onChange={this.handleSelectAct}
                                    value={selectedAct}
                                    placeholder="Select an Act"
                                />
                            </Heading>
                        </Hidden>
                        <Hidden smDown>
                            <Heading title="Acts Calendar">
                                <BookingCalendarFilter
                                    id="bookingAct"
                                    suggestions={this.actSuggestions}
                                    onChange={this.handleSelectAct}
                                    value={selectedAct}
                                    placeholder="Select an Act"
                                />
                            </Heading>
                        </Hidden>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <p className="heading-info">
                                    <small style={{ color: "white" }}>Select an Act to see their availability</small>
                                </p>
                            </Grid>
                            {currentProfile && currentProfile?.type !== ProfileType.Staff.ordinal ? (
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.useVenueActRoster}
                                                onChange={(e, checked) => this.changeActRosterSuggestion(e, checked)}
                                                value={this.state.useVenueActRoster}
                                                style={{
                                                    float: "right",
                                                    color: this.state.selectedVenue ? "#ff0c9c" : "#777",
                                                    width: "21px",
                                                    height: "21px",
                                                    paddingRight: "8px"
                                                }}
                                                disabled={!this.state.selectedVenue}
                                            />
                                        }
                                        label={<p style={{ color: "white", marginLeft: "3px", marginBottom: 0 }}> Use Venue's Favourite Acts</p>}
                                        className="pull-right"
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <div style={{ position: "relative", minHeight: "200px" }}>
                            <Loading showed={this.state.isActLoading} borderRadius="0" />
                            <Calendar
                                localizer={localizer}
                                selectable
                                events={bookingEvents}
                                // views={allViews}
                                longPressThreshold={100}
                                step={30}
                                defaultDate={new Date()}
                                className="my-calendar"
                                components={actComponents}
                                onSelectEvent={this.handleClickEvent}
                                onSelectSlot={this.handleClickSlot}
                                dayPropGetter={this.customDayPropGetter}
                                showMultiDayTimes={true}
                                onNavigate={this.handleNavigateAct}
                                onView={this.handleView}
                            />
                        </div>
                    </Grid>
                </Grid>
                <BookingDialog open={this.state.open} handleClose={this.handleClose} />

                <ModalContainer
                    title="No Booking Permission"
                    open={this.state.preventBooking}
                    onClose={() => this.props.history.push("/staff")}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth="sm"
                >
                    <DialogContent>
                        <Typography variant="body1">You don't have any permission to access this page.</Typography>
                        <Typography variant="caption">Please click button below to back to dashboard</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.history.push("/staff")} color="primary">
                            Back to Dashboard
                        </Button>
                    </DialogActions>
                </ModalContainer>
            </Fragment>
        );
    }
}

export default BookingCalendars;
