import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

import WidgetTitleBar from "../../components/widget/WidgetTitleBar";
import PerformList from "../../components/widget/PerformList";
import Testimonials from "../../components/widget/Testimonials";
import WidgetSmallTextLink from "../../components/widget/WidgetSmallTextLink";
import WidgetContainer from "../../components/widget/WidgetContainer";
import YoutubeWidget from "../../components/widget/YoutubeWidget";
import VimeoWidget from "../../components/widget/VimeoWidget";
import SoundCloudWidget from "../../components/widget/SoundCloudWidget";
import SpotifyWidget from "../../components/widget/SpotifyWidget";
import SocialButton from "../../components/widget/SocialButton";
import SongList from "../../components/songListTable/SongListProfile";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import classNames from "classnames";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

import Tab from "@material-ui/core/Tab";
import hero2 from "../../components/hero2.jpg";

import "./Profile.css";

import { Typography, Hidden, Paper, Grid, Tabs, Avatar, Button } from "@material-ui/core";
import AddMusicianToGigModal from "../../components/searchPage/modal/AddMusicianToGigModal";
import AddMusicianToActRosterModal from "../../components/searchPage/modal/AddMusicianToActRosterModal";
import { ProfileType } from "../../types/enum";
import image from "../../helper/image";
import MessageModal from "../../components/profile/messageModal/MessageModal";
import qs from "qs";
import agent from "../../agent";
import profileStore from "../../stores/profileStore";
import hg_pro_logo from "../../img/hg_pro_logo.png"

const youtubeReg = /^(http:\/\/|https:\/\/)(youtu.be|www.youtube.com)\/([\w]+)/;
const soundcloudReg = /^(http:\/\/|https:\/\/)(soundcloud.com|w.soundcloud.com)\/([\w]+)/;
const spotifyReg = /^(http:\/\/|https:\/\/)(open.spotify.com)\/([\w]+)/;
const vimeoReg = /^(http:\/\/|https:\/\/)(vimeo.com)\/([\w]+)/;

@inject("profileStore", "templateStore", "bookingRequestStore", "reportStore")
@withRouter
@observer
class MusicianProfile extends Component {
    timeoutValue = null;

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
        this.state = {
            value: 0,
            backgroundImageIndex: 0,
            showMusicianGigInvitationModal: false,
            showMusicianActInvitationModal: false,
            showMessageModal: false,
            instrumentData: [],
            isCurrentUser: false
        };
    }

    componentDidMount = () => {
        const profile = this.props.profile || this.props.profileStore.profile;
        const currentProfile = this.props.profileStore.getCurrentProfile();

        if(currentProfile && currentProfile.userId === profile.userId) {
            this.setState({isCurrentUser: true})
        }

        if (profile && profile.backgroundImages) {
            for (let i = 0; i < profile.backgroundImages.length; i++) {
                // caches images, avoiding white flash between background replacements
                new Image().src = profile.backgroundImages[i].url1920;
            }
            this.changeBackground(0);
        }
        this.props.templateStore.showLoadingScreen();
        
        // this.props.bookingRequestStore.loadUpcomingGigsWithoutAdhoc(profile.id).then(() => {
        this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhocGigGuideEmbed(profile.id).then(() => {

            this.props.templateStore.hideLoadingScreen();
        });

        const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (parsed) {
            if (parsed.contact) {
                this.showMessageModal();
            }
        }

        if (profile && profile.profileInstruments) {

            profileStore.getInstrumentNameByInstrumentProfile(profile.profileInstruments).then(result=>{

                this.setState({
                    ...this.state,
                    instrumentData: result
                });

            })

        }
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    changeBackground = index => {
        const profile = this.props.profile || this.props.profileStore.profile;
        this.setState({ backgroundImageIndex: index });

        this.timeoutValue = setTimeout(() => {
            let newIndex = this.state.backgroundImageIndex + 1 < profile.backgroundImages.length ? this.state.backgroundImageIndex + 1 : 0;
            this.changeBackground(newIndex);
        }, 5000);
    };

    manualChangeBackground = index => () => {
        clearTimeout(this.timeoutValue);
        this.changeBackground(index);
    };

    processUpcomingGigs = upcomingGigs => {
        let result = [];
        upcomingGigs = upcomingGigs.sort((a,b) => (a.dateStart > b.dateStart) ? 1 : ((b.dateStart > a.dateStart) ? -1 : 0))
        for (let i = 0; i < upcomingGigs.length; i++) {
            let dateStart = moment(upcomingGigs[i].dateStart);
            let data = {
                month: dateStart.format("MMMM"),
                date: dateStart.format("D"),
                day: dateStart.format("dddd"),
                venue: upcomingGigs[i].venueName ? upcomingGigs[i].venueName : upcomingGigs[i]?.venue?.profileName ? upcomingGigs[i]?.venue?.profileName : "",
                address: upcomingGigs[i].venueAddress,
                time:
                    moment(upcomingGigs[i].timeStart, "HH:mm:ss").format(this.props.templateStore.getFormat12Hour(upcomingGigs[i].timeStart)) +
                    " - " +
                    moment(upcomingGigs[i].timeEnd, "HH:mm:ss").format(this.props.templateStore.getFormat12Hour(upcomingGigs[i].timeEnd)),
                actName: upcomingGigs[i].actName,
                actUrl: "/profile/" + upcomingGigs[i].actId,
                venueUrl: "/profile/" + upcomingGigs[i].venueId
            };
            result.push(data);
        }
        return result;
    };

    showMusicianGigInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianGigInvitationModal: true
        });
    };

    hideMusicianGigInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianGigInvitationModal: false
        });
    };

    showMusicianActInvitationModal = () => {
        this.setState({
            ...this.state,
            showMusicianActInvitationModal: true
        });
    };

    hideMusicianActInvitationModal = () => {
        this.setState({
            showMusicianActInvitationModal: false
        });
    };

    showMessageModal = () => {
        this.setState({
            showMessageModal: true
        });
    };

    hideMessageModal = () => {
        this.setState({
            showMessageModal: false
        });
    };

    handleButtonMessage = (e) => {
      
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const profile = this.props.profile || this.props.profileStore.profile;
        if(this.state.isCurrentUser) {
            e.preventDefault();
            this.props.history.push('/messages/new');
        } else {
            if (currentProfile) {
                e.preventDefault();
                this.props.history.push({
                    pathname: '/messages/new',
                    state: { profile }
                })
            } else {
                this.setState({showMessageModal: true})
            }
        }
    }

    render() {
        const profile = this.props.profile || this.props.profileStore.profile;
        let { upcomings, embedUpcomingGigs } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const { showMusicianGigInvitationModal, showMusicianActInvitationModal } = this.state;
        let performData = this.processUpcomingGigs(embedUpcomingGigs);

        let widgetSmallTextLinksPlayedAt = {
            title: "Played At",
            datas: []
        };

        if (profile && profile.playedAts) {
            widgetSmallTextLinksPlayedAt.datas = profile.playedAts.map(playedAt => ({
                text: playedAt.name,
                secondaryText: playedAt.suburb + (playedAt.state ? ", " + playedAt.state : "") + (playedAt.country ? ", " + playedAt.country : ""),
                link: "#"
            }));
        }

        let widgetSmallTextLinksPlayedWith = {
            title: "Played With",
            datas: []
        };

        if (profile && profile.playedWiths && profile.showPlayedWith) {
            widgetSmallTextLinksPlayedWith.datas = profile.playedWiths.map(playedWith => ({
                text: playedWith.name,
                secondaryText: playedWith.notes,
                link: "#"
            }));
        }
        
        let widgetSmallTextLinksSkillSet = {
            title: "Skill Set",
            datas: this.state.instrumentData
        };

        let widgetSmallTextLinksCount =
            (widgetSmallTextLinksPlayedAt.datas.length > 0 ? 1 : 0) +
            (widgetSmallTextLinksPlayedWith.datas.length > 0 ? 1 : 0) +
            (widgetSmallTextLinksSkillSet.datas.length > 0 ? 1 : 0);

        let profileContent = (
            <div className="profile-content">
                <Grid container spacing={16}>
                    {profile && profile.videoUrl ? (
                        <Grid item xs={12} lg={profile.musicUrl ? 6 : 12}>
                            <div className="standalone-widget-title">
                                <WidgetTitleBar title={profile && profile.fullName + "'s Video"} showBadge={false} />
                            </div>
                            {profile && profile.videoUrl && profile.videoUrl.match(youtubeReg) && <YoutubeWidget url={profile && profile.videoUrl} />}
                            {profile &&
                                profile.videoUrl &&
                                profile.videoUrl.match(vimeoReg) && <VimeoWidget url={profile && profile.videoUrl} height="400px" />}
                        </Grid>
                    ) : null}

                    {profile && profile.musicUrl ? (
                        <Grid item xs={12} lg={profile.videoUrl ? 6 : 12}>
                            <div className="standalone-widget-title">
                                <WidgetTitleBar title={profile && profile.fullName + "'s Sound"} showBadge={false} />
                               
                            </div>
                            {profile && profile.musicUrl && profile.musicUrl.match(soundcloudReg) && <SoundCloudWidget url={profile && profile.musicUrl} />}
                            {profile &&
                                profile.musicUrl &&
                                profile.musicUrl.match(spotifyReg) && <SpotifyWidget url={profile && profile.musicUrl} />}
                            
                            {/* <SoundCloudWidget url={profile && profile.musicUrl} /> */}
                        </Grid>
                    ) : null}
                    {widgetSmallTextLinksPlayedAt.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksPlayedAt} />
                        </Grid>
                    ) : null}

                    {widgetSmallTextLinksPlayedWith.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksPlayedWith} />
                        </Grid>
                    ) : null}

                    {widgetSmallTextLinksSkillSet.datas.length ? (
                        <Grid item xs={12} lg={12 / widgetSmallTextLinksCount}>
                            <WidgetSmallTextLink data={widgetSmallTextLinksSkillSet} />
                        </Grid>
                    ) : null}

                    {profile && profile.songLists && profile.songLists.length > 0 ? (
                        <Grid item xs={12} lg={12} className="songlist">
                            <WidgetContainer title={profile && profile.fullName + "'s Song List"} showBadge={false}>
                                <SongList songs={profile && profile.songLists} musician={profile && profile.fullName} />
                            </WidgetContainer>
                        </Grid>
                    ) : null}

                    {profile && profile.testimonials && profile.testimonials.length > 0 ? (
                        <Grid item xs={12}>
                            <div className="widget-title-bar-yellow standalone-widget-title">
                                <WidgetTitleBar title="Testimonials" showBadge={false} />
                            </div>

                            <Testimonials testimonials={profile && profile.testimonials} />
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );

        return (
            <div>
                <LoadingSpinner showed={this.props.templateStore.loadingScreenState} />
                <Header forceToggler />
                <Sidebar forceMobile />
                <div className="profile-header">
                    <div className="profile-header-information-container">
                        {profile &&
                            profile.showAvatar && (
                                <div className="profile-header-inner">
                                    <Avatar classes={{ root: "profile-avatar" }} src={image.getProfileImage(profile)} alt={profile.fullName} />
                                </div>
                            )}
                        <div className="profile-header-text profile-header-inner">
                            <Typography type="headline" component="h3">
                                {profile && profile.fullName}  <img style={{display:this.props.isFreePlan ? "none":"inline"}} src={ hg_pro_logo } width="50px"/>
                            </Typography>
                            <Typography component="p">Artist/Entertainer</Typography>
                            
                            <br />
                            {profile && (profile.city || profile.state) ? (
                                <Typography component="p">
                                    <LocationOnIcon classes={{ root: "profile-header-icon" }} />
                                    {profile.city && profile.state
                                        ? profile.city + ", " + profile.state
                                        : profile.city
                                            ? profile.city
                                            : profile.state
                                                ? profile.state
                                                : null}
                                </Typography>
                            ) : null}
                        </div>
                        <div className="profile-header-inner">
                            <SocialButton variant="webSite" urlData={profile && profile.webSite} />
                            <SocialButton variant="facebook" urlData={profile && profile.facebook} />
                            <SocialButton variant="twitter" urlData={profile && profile.twitter} />
                            <SocialButton variant="bandcamp" urlData={profile && profile.bandcamp} />
                            <SocialButton variant="soundcloud" urlData={profile && profile.soundcloud} />
                            <SocialButton variant="mixcloud" urlData={profile && profile.mixcloud} />
                            <SocialButton variant="youtube" urlData={profile && profile.youtube} />
                            <SocialButton variant="instagram" urlData={profile && profile.instagram} />
                            <SocialButton variant="googlePlus" urlData={profile && profile.googlePlus} />
                            <SocialButton variant="linkedIn" urlData={profile && profile.linkedIn} />
                            <SocialButton variant="iTunes" urlData={profile && profile.iTunes} />
                            <SocialButton variant="spotify" urlData={profile && profile.spotify} />
                        </div>

                        <div className="profile-header-inner">
                            {currentProfile &&
                                currentProfile.type === ProfileType.Musician.ordinal &&
                                currentProfile.id !== profile.id && (
                                    <Fragment>
                                        <Button
                                            variant="raised"
                                            color="primary"
                                            className="actionButton"
                                            onClick={this.showMusicianActInvitationModal}
                                        >
                                            Invite to Act
                                        </Button>
                                        <Button
                                            variant="raised"
                                            color="primary"
                                            className="actionButton"
                                            onClick={this.showMusicianGigInvitationModal}
                                        >
                                            Invite to Gig
                                        </Button>
                                        <AddMusicianToGigModal
                                            open={showMusicianGigInvitationModal}
                                            onClose={this.hideMusicianGigInvitationModal}
                                            profileName={profile.fullName}
                                            profileImage={image.getProfileImage(profile)}
                                            profile={profile}
                                        />
                                        <AddMusicianToActRosterModal
                                            open={showMusicianActInvitationModal}
                                            onClose={this.hideMusicianActInvitationModal}
                                            profileName={profile.fullName}
                                            profileImage={image.getProfileImage(profile)}
                                            profile={profile}
                                        />
                                    </Fragment>
                                )}
                            {currentProfile && !this.state.isCurrentUser ?
                                <Button variant="raised" color="primary" className="actionButton" onClick={(e) => {
                                        this.handleButtonMessage(e)
                                        }} >
                                    Message
                                </Button>
                                : ""
                            }
                           
                            <MessageModal profile={profile} open={this.state.showMessageModal} closeHandler={this.hideMessageModal} />
                        </div>

                        {profile &&
                            profile.backgroundImages &&
                            profile.backgroundImages.length > 1 && (
                                <div className="profile-header-inner-slider">
                                    {profile.backgroundImages.map((backgroundImage, i) => {
                                        return (
                                            <div
                                                key={i}
                                                index={i}
                                                className={classNames(
                                                    "background-image-control",
                                                    this.state.backgroundImageIndex === i ? "active" : "inactive"
                                                )}
                                                onClick={this.manualChangeBackground(i)}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                    {profile &&
                        profile.backgroundImages && (
                            <React.Fragment>
                                {profile.backgroundImages.map((backgroundImage, i) => {
                                    return (
                                        <div
                                            className={classNames(
                                                "profile-header-background-image",
                                                this.state.backgroundImageIndex === i ? "active" : "inactive"
                                            )}
                                            style={{ backgroundImage: "url('" + backgroundImage.url1920 + "')" }}
                                            key={i}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )}

                    {(!profile || !profile.backgroundImages || profile.backgroundImages.length === 0) && (
                        <React.Fragment>
                            <div
                                className={classNames("profile-header-background-image", "active")}
                                style={{ backgroundImage: `url(${hero2})` }}
                                key={0}
                            />
                        </React.Fragment>
                    )}
                </div>

                <div className="profile-body">
                    <Hidden smDown>
                        <div className="profile-sidebar">
                            <Grid container spacing={16}>
                                {performData &&
                                    performData.length > 0 && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <div className="border-bottom-grid">
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                                {profile && profile.fullName}'s Live Gigs
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ height:"100%", marginBottom: "50px" }}>
                                                <PerformList performData={performData} />
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                <Grid item xs={12}>
                                    <div className="border-bottom-grid">
                                        <Typography type="headline" component="h4" className="profile-sidebar-title">
                                            {profile && profile.fullName}'s Bio
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className="profile-bio-content">
                                    <Typography component="div">
                                        <div className="bio-section" dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />{" "}
                                    </Typography>
                                </Grid>
                                
                                {
                                    profile.showContactPhone || profile.showEmail
                                    ?   <Grid item xs={12}>
                                            <div className="border-bottom-grid">
                                                <Typography type="headline" component="h4" className="profile-sidebar-title">
                                                    Contact
                                                </Typography>
                                            </div>
                                        </Grid>
                                    :   null
                                }
                                {
                                    profile.showContactPhone || profile.showEmail
                                    ?   <Grid item xs={12} className="profile-sidebar-contact">
                                            <Grid container spacing={8}>
                                                {
                                                    profile.showContactPhone
                                                    ?   <Grid item xs={12}>
                                                            <Typography component="div">
                                                                <PhoneIcon />
                                                                {profile.showContactPhone && profile.contactPhone !== '' ? profile.contactPhone : '-'}
                                                            </Typography>
                                                        </Grid>
                                                    :   null
                                                }
                                                {
                                                    profile.showEmail
                                                    ?   <Grid item xs={12}>
                                                            <Typography component="div">
                                                                <EmailIcon />
                                                                {profile.showEmail && profile.email !== '' ? profile.email : '-'}
                                                            </Typography>
                                                        </Grid>
                                                    :   null
                                                }
                                            </Grid>
                                        </Grid>
                                    :   null
                                }
                            </Grid>
                        </div>

                        {profileContent}
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Paper style={{ width: "100%", backgroundColor: "#ff0c9c" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        centered
                                        fullWidth
                                    >
                                        <Tab icon={<PersonIcon />} label="Profile" />
                                        <Tab icon={<QueueMusicIcon />} label="Live Gigs" />
                                        <Tab icon={<ImportContactsIcon />} label="Bio" />
                                    </Tabs>
                                </Paper>
                            </Grid>

                            {this.state.value === 0 && (
                                <Grid item xs={12}>
                                    {profileContent}
                                </Grid>
                            )}
                            {this.state.value === 1 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Live Gigs"} showBadge={false}>
                                            <PerformList performData={performData} />
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                            {this.state.value === 2 && (
                                <Grid item xs={12}>
                                    <div className="profile-content">
                                        <WidgetContainer title={profile && profile.fullName + "'s Bio"} showBadge={false} style={{backgroundColor:"#333"}}>
                                            <Typography component="p">
                                                <div className="bio-section"  dangerouslySetInnerHTML={{ __html: profile && profile.bio }} />{" "}
                                            </Typography>
                                        </WidgetContainer>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </div>
            </div>
        );
    }
}

export default MusicianProfile;
