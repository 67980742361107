import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, Grid, Typography, ListItem} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "../HgBilling.css";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter, Link } from "react-router-dom";
import { hgStripe } from "../StripeConfig.js";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("userStore", "hgBillingStore", "profileStore", "authStore", "organisationStore")
@withRouter
@observer
class AgentBilling extends Component{
    constructor(props){
        super(props);
    };


    state = {
        toggleModal: false
    };

    selectPlan = (value,type, isReturn) => {
        if(isReturn) return;
        this.props.selectPlan(value,type);
    }

    selecFreePlan = () => {
        this.props.selecFreePlan();
    }

    handleOpenModal = (isReturn) => {
        if(isReturn) return;
        this.setState({ toggleModal: true });
    }
    handleCloseModal = () => {
        this.setState({
            ...this.state,
            toggleModal: false
        });
        
    };

    render(){
        const {billingAccess} = this.props.hgBillingStore
        var isAgentFree  = !billingAccess ? true:false || billingAccess?.isFreePlan
        var priceIdExisted = billingAccess?.priceId ? true : false
        var isEssential = billingAccess?.isBasicPlan && !priceIdExisted
        var isProPlan = billingAccess?.isProPlan && !priceIdExisted
        var isAgentEssentialMonthly = billingAccess?.priceId === hgStripe.pricing.agentMembershipMonthly 
        var isAgentEssentialPerGigs = billingAccess?.priceId === hgStripe.pricing.agentMembershipPayAsYouGo
        var isAgentProMonthly = billingAccess?.priceId === hgStripe.pricing.agentMembershipProMonthly
        var isAgentProPerGigs = billingAccess?.priceId === hgStripe.pricing.agentMembershipProMonthlyPayAsYouGo
        return (
           <Fragment>
                    <Grid container className="billingHg">
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isAgentFree? "pink-background" : ""}>
                                <th>Free</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                   <ul className="billingBullet">
                                        <li>Unlimited venues</li>
                                        <li>Up to 8 bookings per month</li>
                                        <li>Unlimited consultants</li>
                                        <li>Integrated worksheet & contracts</li>
                                        <li>Venue roster & calendar management</li>
                                        <li>Act roster management including venue favourites</li>
                                        <li>Cancellation workflows</li>
                                        <li>Automated venue and artist invoicing</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <td style={{textAlign:"center"}}>
                                    <Button className={isAgentFree ? "buttonHgBilling" : ""}  style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.handleOpenModal(isAgentFree)}>
                                        {isAgentFree ? "Current Plan" : "Select Plan"}
                                    </Button>
                                </td>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                        


                            <tr className={ isEssential || isAgentEssentialMonthly || isAgentEssentialPerGigs? "pink-background" : ""}>
                                <th>Essentials</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Free plus:
                                    <ul className="billingBullet">
                                    <li>Unlimited bookings</li>
                                        <li>Unlimited consultants</li>
                                        <li>Automated gig promotion</li>
                                        <li>Social post management for both live events and general social posts </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $15 per gig
                                        </td>
                                        <td>
                                        {!isAgentEssentialMonthly && isEssential ? "Free" :"$159 per month"}  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button className={isAgentEssentialPerGigs ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.agentMembershipPayAsYouGo, 0, isAgentEssentialPerGigs)}>
                                            {isAgentEssentialPerGigs  ? "Current Plan" : "Select Plan"}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className={isAgentEssentialMonthly ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.agentMembershipMonthly, 1, isAgentEssentialMonthly)}>
                                            {isAgentEssentialMonthly  ? "Current Plan" : isEssential ? "Current Plan Free" :"Select Plan"}
                                            </Button>
                                           
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isProPlan || isAgentProMonthly || isAgentProPerGigs? "pink-background" : ""}>
                                <th>Pro</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Essentials plus:
                                    <ul className="billingBullet">
                                        <li>Manage social posts to venue social channels</li>
                                        <li>Accounting Package Integrations including XERO & MYOB (Quickbooks coming soon)</li>                                       
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                    <td className="hgBillingBorderRight">
                                            $19 per gig
                                        </td>
                                        <td>
                                        {!isAgentProMonthly && isProPlan ? "Free" :"$199 per month"} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button className={isAgentProPerGigs ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.agentMembershipProMonthlyPayAsYouGo, 0,isAgentProPerGigs)}>
                                                {isAgentProPerGigs  ? "Current Plan" : "Select Plan"}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className={isAgentProMonthly ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.agentMembershipProMonthly, 1,isAgentProMonthly)}>
                                            {isAgentProMonthly ? "Current Plan" : isProPlan ? "Current Plan Free" :"Select Plan"}
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                               
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        {/* <table className="hgBillingTable">
                            <tr>
                                <th>Pro Plus</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Pro plus:
                                    <ul className="billingBullet">
                                        <li>Auto Schedule to book any time period of entertainment with one click. (coming soon)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $25 per gig
                                        </td>
                                        <td>
                                            $250 per gig
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary">
                                                Select Plan
                                            </Button>
                                        </td>
                                        <td>
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary">
                                                Select Plan
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table> */}
                        </Grid>
                    </Grid>
                    <ConfirmationModal
                    open={this.state.toggleModal}
                    title={"Select Free Plan"}
                    message={`Are you sure you want to select the free plan?`}
                    closeHandler={this.handleOpenModal}
                    confirmationHandler={this.selecFreePlan}
                    declineHandler={this.handleCloseModal}
                    confirmationLabel="Confirm"
                />
           </Fragment>
        );
    }
}

export default withStyles(styles) (AgentBilling);
