import React, { Component } from "react";

import { observer, inject } from "mobx-react";

import { Collapse, List, Typography } from "@material-ui/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Badge from "@material-ui/core/Badge";
import Icon from "@material-ui/core/Icon";

import classNames from "classnames";
import _ from "lodash";
import { Link } from "react-router-dom";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import "./SidebarMenu.css";
import { ProfileType } from "../../types/enum";
import hgBillingStore from "../../stores/hgBillingStore";

@inject("templateStore", "profileStore", "billingStore", "userStore", "hgBillingStore")
@observer
class SidebarMenu extends Component {
    state = { open: this.props.templateStore.openSubMenu, hasAccess : true};

    constructor(props){
        super(props);
    }

    handleOpen = () => {
        this.setState({ open: !this.state.open });
    };

    componentDidMount() {
        // this.props.billingStore.loadAccess();
        // this.props.hgBillingStore.loadAccess();
        if (this.props.data.subMenu && this.props.data.subMenu.length > 0) {
            var activeSidebar = this.props.data.subMenu.find(data => {
                if (data) {
                    return data.key === this.props.templateStore.getActiveSideBarKey();
                }
                return false;
            });

            if (activeSidebar && activeSidebar.active) {
                this.setState({ open: true });
                this.props.templateStore.setOpenSubMenu(true)
            } else {
                if(this.props.data.name == "Manage") {
                    this.setState({open: false});
                    this.props.templateStore.setOpenSubMenu(false)
                }
            }
        }
        this.checkBillingAccess();
    }

    getDisabledSidebarMenu = (currentProfile, currentUser) => {
        const { billingStore,  profileStore } = this.props;
        if(hgBillingStore.billingAccess?.lock){
            return true
        }
        // if((billingStore.billingAccess?.isSubscriptionRequired || billingStore.billingAccess?.isNeedToUpdatePayment) && !billingStore.billingAccess?.isBasicPlan) return true;
        if (currentProfile != undefined) {

            if ((currentProfile.isCompleted || currentProfile.isCompleted == 1) && parseInt(currentUser.status) == 50) {
                // if(currentProfile.type === ProfileType.PrivateHire.ordinal  && currentUser.status === 40){
                //     return true; //for develop purpose only;
                // }else{
                //     return false;
                // }
                return false;
                
            } else {
                return true;
            }
        }
        return true;
    }

    renderSubMenu = menus => {
        const { currentProfile } = this.props.profileStore;
        const { currentUser } = this.props.userStore;
        return menus.filter(x => (x !== undefined || x !== null) && x).map((menu, index) => {
            return (
                <ListItem
                    key={index}
                    disabled={this.getDisabledSidebarMenu(currentProfile,currentUser)}
                    button
                    divider={true}
                    dense={true}
                    classes={{ divider: "sidebar-divider" }}
                    className={menu.active ? "sidebar-active" : "sidebar-nonactive"}
                    style={{ paddingLeft: "48px" }}
                    component={Link}
                    to={menu.link}
                >
                    <ListItemIcon className="sidebar-icon-root">
                        {menu.icon ? (
                            <Icon className="sidebar-icon-container">
                                <i className={classNames(menu.icon, "sidebar-icon")} />
                            </Icon>
                        ) : menu.muiIcon ? (
                            <Icon className="sidebar-icon-container">{menu.muiIcon}</Icon>
                        ) : null}
                    </ListItemIcon>
                    <ListItemText primary={menu.name} />
                    {menu.showBadge ? (
                        <Badge badgeContent={menu.badgeCount} className="sidebar-badge" children>
                            <i />
                        </Badge>
                    ) : null}
                </ListItem>
            );
        });
    };

    checkBillingAccess = async () => {
        const { billingStore,  profileStore } = this.props;
        
        let currentProfile = profileStore.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        if (currentProfile.typeString == "musician" || currentProfile.typeString == "agent" || currentProfile.typeString == "staff") {
            if(billingStore.billingAccess){
                this.setState({ hasAccess: !(billingStore.billingAccess.isSubscriptionRequired || billingStore.billingAccess.isNeedToUpdatePayment)});
            }
        }
    }

    render() {
        const { currentProfile } = this.props.profileStore;
        const { currentUser } = this.props.userStore;
        
        return (
            <span>
                <ListItem
                    button
                    disabled={this.getDisabledSidebarMenu(currentProfile, currentUser)}
                    divider={true}
                    dense={true}
                    classes={{ divider: "sidebar-divider" }}
                    className={this.props.data.active ? "sidebar-active" : ""}
                    onClick={this.props.data.subMenu && !_.isEmpty(this.props.data.subMenu) ? this.handleOpen : undefined}
                    component={this.props.data.subMenu && !_.isEmpty(this.props.data.subMenu) ? "li" : this.props.data.self ? "a" : Link}
                    to={this.props.data.subMenu && !_.isEmpty(this.props.data.subMenu) ? "#" : this.props.data.link ? this.props.data.link : "#"}
                    target={this.props.data.self ? "_blank" : ""}
                    href={this.props.data.self ? this.props.data.link : ""}
                >
                    <ListItemIcon className="sidebar-icon-root">
                        {this.props.data.icon ? (
                            <Icon className="sidebar-icon-container">
                                <i className={classNames(this.props.data.icon, "sidebar-icon")} style={{color:this.props.data.color}}/>
                            </Icon>
                        ) : this.props.data.muiIcon ? (
                            <Icon className="sidebar-icon-container">{this.props.data.muiIcon}</Icon>
                        ) : null}
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography style={{color:this.props.data.color}}>
                            {this.props.data.name}
                        </Typography>} />
                    {this.props.data.showBadge ? (
                        <Badge badgeContent={this.props.data.badgeCount} className="sidebar-badge" children>
                            <i />
                        </Badge>
                    ) : null}
                    {this.props.data.subMenu && !_.isEmpty(this.props.data.subMenu) ? this.state.open ? <ExpandLess /> : <ExpandMore /> : ""}
                </ListItem>
                {this.props.data.subMenu && !_.isEmpty(this.props.data.subMenu) ? (
                    <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {this.renderSubMenu(this.props.data.subMenu)}
                        </List>
                    </Collapse>
                ) : (
                    ""
                )}
            </span>
        );
    }
}

export default SidebarMenu;
