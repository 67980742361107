import { observable, action, runInAction} from "mobx";
import agent from "../agent";
import CacheHelper from "../helper/cache";

class HGBillingStore {
    @observable billingAccess;
    @observable customer;
    @observable billingPlans;
    @observable card;
    @observable subscription;
    @observable invoices;
    @observable billingInformation;
    @observable paymentAccesses;
    @observable planIdMusician = "plan_E5fDQFVsTISNZt";
    @observable planIdAgent = "plan_E5fIfwzh9z4QPJ";
    @observable planIdVenue = "plan_E5fItgGn3ijmLF";
    @observable temporaryAccess = false;
    @observable paymentMethods = []
    @observable defaultPayment = ""
    @observable subscriptions = []

    postCheckout(priceId, type){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.postCheckout({priceId:priceId, type: type}, currentProfile?.id)
        .then(
            action(response => {
                return response;
            })
        );
    }

    action
    cancelSubscription(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.cancelSubscription(currentProfile?.type)
        .then(
            action(response => {
                return response;
            })
        );
    }

    addPaymentMethod(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.addPaymentMethod()
        .then(
            action(response => {
                return response;
            })
        );
    }

    @action
    async reloadAccessSynch(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        let user = CacheHelper.getUser();
        if(currentProfile.type == 1 || currentProfile.type == 2 || currentProfile.type == 3 || currentProfile.type == 9){
            let profileType = currentProfile.type;
            if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
            let billingAccess = await agent.hgBilling.getCustomerAccess(profileType)
           
            if (billingAccess){
                runInAction(() => {
                    this.billingAccess = billingAccess;
                })
              
                CacheHelper.setLicense(billingAccess);
            }
            return this.billingAccess;
        }
    }
    async loadAccess(){
       
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        let user = CacheHelper.getUser();
        if(currentProfile.type == 1 || currentProfile.type == 2 || currentProfile.type == 3 || currentProfile.type == 9){
            if(this.billingAccess == undefined){
                let license = CacheHelper.getLicense();
                if(license == null || license == undefined){
                  
                    let profileType = currentProfile.type;
                    if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
                    await agent.hgBilling.getCustomerAccess(profileType)
                    .then(
                        action(billingAccess => {
                            if (billingAccess){
                                this.billingAccess = billingAccess;
                                CacheHelper.setLicense(billingAccess);
                            }
                            return billingAccess;
                        })
                    );
                }else {
                    this.billingAccess = license;
                }
            }
            return this.billingAccess;
        }
    }
    getPaymentMethod(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.getPaymentMethod()
        .then(
            action(response => {
                this.paymentMethods = response?.cards?.data
                this.defaultPayment = response.defaultPM
                return response;
            })
        );
    }

    setDefaultPaymentMethod(pmId){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.setAsDefaultPaymentMethod({paymentId: pmId})
        .then(
            action(response => {
                this.getPaymentMethod()
                return response;
            })
        );
    }
    
    deletePaymentMethod(pmId){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) return;
        
        return agent.hgBilling.deletePaymentMethod({paymentId: pmId})
        .then(
            action(response => {
                this.getPaymentMethod()
                return response;
            })
        );
    }
    getProfileAccess(profileId){
        if(!profileId) return
        return agent.hgBilling.getProfileAccess(profileId)
        .then(
            action(response => {
                return response;
            })
        );
    }
    getCustomerAccess(){
        let currentProfile = CacheHelper.getCurrentProfile();
        if(currentProfile == null || currentProfile == undefined) {
            return Promise.resolve();
        }
        let profileType = currentProfile.type;
        if(profileType == 3 && currentProfile.isOwnOrganization) profileType = 4;
        return agent.hgBilling.getCustomerAccess(profileType)
        .then(
            action(response => {
                this.billingAccess = response
                return response;

            })
        );
    }
    

    updateSubscriptionPostCheckout(priceId, type){
        return agent.hgBilling.updateSubscriptionPostCheckout({priceId:priceId, type: type})
        .then(
            action(response => {
                return response;
            })
        );
    }
}

export default new HGBillingStore();