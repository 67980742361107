import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import { Button, Grid, Card, Menu, MenuItem} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import  Loading from "../../components/loadingSpinner/Loading";

@inject("hgBillingStore")
@withRouter
@observer
class PaymentDetails extends Component {
    state = {
        actionAnchor: [],
        isLoading: false
    };
    componentDidMount() {
        this.setState({isLoading: true });
        this.props.hgBillingStore.getPaymentMethod().then((response) => {
            this.setState({isLoading: false });
        })
      }
    addCard = () => {
        this.props.hgBillingStore.addPaymentMethod().then((response) => {
            console.log(response.url)
            window.open(response.url, '_system')
        })
    }
    setDefaultPaymentMethod = (id) => {
        this.setState({isLoading: true });
        this.props.hgBillingStore.setDefaultPaymentMethod(id).then((response) => {
            this.unsetActionAchnor()
            this.setState({isLoading: false });
        })
    }
    
    deleteCard = (id) => {
        this.setState({isLoading: true });
        this.props.hgBillingStore.deletePaymentMethod(id).then((response) => {
            this.props.hgBillingStore.reloadAccessSynch().then(() => {
                this.unsetActionAchnor()
                this.setState({isLoading: false });
            })
           
        })
    }

    setActionAnchor = (element, agentVenueId) => {
        var tempAnchor = [];
        tempAnchor[agentVenueId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = agentVenueId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    cardLogo = (brand) => {
        let icon;
        let color;
        switch (brand) {
          case 'visa':
            icon = "fa-brands fa-cc-visa visa-icon"
            color = "#1a1f71"
            break;
          case 'mastercard':
            icon = "fa-brands fa-cc-mastercard"
            color = "#FF5959"
            break;
          case 'amex':
            icon = "fa-brands fa-cc-amex amex-icon";
            color = "#0072B2"
            break;
          case 'discover':
            icon = "fa-brands fa-cc-discover";
            color = "#F00000"
            break;
          default:
            icon = "fa-solid fa-credit-card"
            color = "black"
        }
      
        return {icon:icon, color: color}
      };
    renderCards = (data,defaultPayment) => {
        var logo = this.cardLogo(data?.card?.brand)
       return (
       <Card className="cardPayment" style={{marginTop:20,"height":100,"width":350}}>
        {data.id === defaultPayment ? <div class="cardPaymentText">Default</div> : null }
            <table className="tablePayment">
                <tr>
                    <td>
                     <i class={logo.icon} style={{color:logo.color,marginLeft:10,fontSize:40,verticalAlign: "middle"}}></i>
                    </td>
                    <td>
                        <tr>
                        <p>{`**** ${data?.card?.last4}`}</p>
                        </tr>
                        <tr>
                        <p style={{fontSize:12}}>{`${data?.billing_details?.name}`}</p>
                        </tr>
                        <tr>
                        <p style={{fontSize:12}}>Expired at {`${data?.card?.exp_month}/${data?.card?.exp_year}`}</p>
                        </tr>
                    </td>
                    <td>
                    <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, data?.id)}>
                        <i class="fa-solid fa-ellipsis-vertical fa-xl"></i>
                    </Button>
                    <Menu
                            open={this.state.actionAnchor[data?.id] != null}
                            onClose={() => this.unsetActionAchnor(data?.id)}
                            anchorEl={this.state.actionAnchor[data?.id]}
                        >
                            {data.id != defaultPayment ? <MenuItem 
                             button
                             className="booking-details"
                             onClick={() => this.setDefaultPaymentMethod(data?.id)}
                            >Set as Default</MenuItem> : null}
                            <MenuItem 
                             button
                             className="booking-details"
                             onClick={() => this.deleteCard(data?.id)}
                            >Delete Card</MenuItem>
                           
                       </Menu>
                    </td>
                </tr>
            </table>
           
            </Card>
       )
    }
   
    render() {
        const {paymentMethods,defaultPayment} = this.props.hgBillingStore
        return (
            <Fragment>
                <Loading showed={this.state.isLoading}/>
                <Grid style={{margin:50}} container spacing={24} md={12}>
                    <Grid md={6}>
                        <h3>
                        <i class="fa fa-credit-card" aria-hidden="true"></i> Payment Methods
                        </h3>
                        <h5>Manage your payment cards</h5>
                        <Button variant="contained" color="primary" onClick={this.addCard}>
                         Add Card
                        </Button>
                    </Grid>
                    <Grid md={6}>
                        {paymentMethods.map(data => (
                            this.renderCards(data,defaultPayment)
                        ))}
                    </Grid>
                   
                 </Grid>
                    
            </Fragment>
        );
    }
}

export default PaymentDetails;