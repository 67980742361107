import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { inject, observer } from "mobx-react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import myobIcon from "../../../img/myob_logo.png";
import { Grid, Button, Tooltip,Divider } from "@material-ui/core";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js"
import "../AccountIntegration.css";
const MYOB_KEY = process.env.REACT_APP_MYOB_KEY;
const MYOB_REDIRECT_URL = process.env.REACT_APP_MYOB_REDIRECT_URL;

const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";
const ENABLE_MYOB = process.env.REACT_APP_ENABLE_MYOB === "true";
import Select from "react-select";
import { ProfileType } from "../../../types/enum.js";
import { withRouter } from "react-router-dom";

@inject("userStore", "profileStore", "templateStore","hgBillingStore", "commonStore","myobStore")
@withRouter
@observer
class MYOBComponent extends Component {
    state = {
        selectedOption: "",
        options: [],
        showCalendar: false,
        googleCalendarActive: false,
        showUnlinkModal: false,
        showSubmitCalendar: false,
        panelMYOBExpanded: false,
        panelXEROExpanded: false,
        panelGoogleExpanded: false,
        myobActive:false,
        myobAccountName: "",
        showStep3Myob: false,
        showMyobDisconnectModal: false,
        selectCompanyPhase: false,
        selectedMYOBCompany : "",
        selectedMYOBOptions: [],
        showStep3XERO: false,
        XEROActive : false
    };
    
    componentDidMount = () => {
        var options = [];
        var selectedOptions = "";
        const { myobToken, companyUrl } = this.props.myobStore;
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        if(currentProfile && currentProfile?.activateMYOBAccess && (this.props.hgBillingStore.billingAccess?.isProPlan)){   
            if(!currentProfile?.myobCompanyUri){    
                this.props.myobStore.getMYOBListCompany(currentProfile?.id).then(result => {
                    this.setState({
                        ...this.state,
                        selectCompanyPhase: true,
                        selectedMYOBOptions : this.props.myobStore.companyUrl,
                        selectedMYOBCompany : currentProfile?.myobCompanyUri,
                        myobActive:true,
                        myobAccountName: currentProfile?.myobCompanyName,
                        showStep3Myob: true,
                        panelMYOBExpanded: true
                    });   
                })
               
            }else{
                var options = []
                options.push({ value: currentProfile?.myobCompanyUri, label: currentProfile?.myobCompanyName })
                this.setState({
                    ...this.state,
                    selectCompanyPhase: false,
                    selectedMYOBOptions : options,
                    selectedMYOBCompany : currentProfile?.myobCompanyUri,
                    myobActive:true,

                    myobAccountName: currentProfile?.myobCompanyName,
                    showStep3Myob: true,
                    panelMYOBExpanded: false
                });
            }
            // if(myobToken || !(currentProfile?.outGoingInvoiceMYOB &&
            //     currentProfile?.inGoingInvoiceMYOB)){
            //     this.setState({
            //         ...this.state,
            //         myobActive:true,
            //         myobAccountName: currentProfile?.myobCompanyName,
            //         showStep3Myob: true
            //     });
            // }else{
            //     this.setState({
            //         ...this.state,
            //         myobActive:true,
            //         myobAccountName: currentProfile?.myobCompanyName,
            //         showStep3Myob: true
            //     });
            // }
         
        }else{
            this.setState({
                ...this.state,
                myobActive:false,
                myobAccountName: "",
                showStep3Myob: false
            });
        }
        
        // this.props.userStore.getQuickBooks();
    };

    expandMYOB = () => {
        const {panelMYOBExpanded} =this.state
        if(this.props.hgBillingStore.billingAccess?.isProPlan){
            this.setState({
                ...this.state,
                panelMYOBExpanded: !panelMYOBExpanded,
            });
        }else{
            this.props.redirectMembership();
        }
       
    }

    expandXERO = () => {
        const {panelXEROExpanded} =this.state
        if(this.props.hgBillingStore.billingAccess?.isProPlan){
            this.setState({
                ...this.state,
                panelXEROExpanded: !panelXEROExpanded,
            });
        }else{
            this.props.redirectMembership();
        }
       
    }
    handleConnectXero = async () => {
        await xero_agent.auth.connect().then((result) => {
            console.log(result)
        })
    }
    handleOpenMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: true });
    handleCloseMYOBModal = () => this.setState({ ...this.state, showMyobDisconnectModal: false });
    handleDisconnectMYOB = e => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        this.props.handleLoading(true)
        this.props.myobStore.disconnect(currentProfile?.id).finally(() => {
            this.props.profileStore.loadProfiles().finally(() => {
                this.props.handleLoading(false)
                this.setState({
                    ...this.state,
                    showMyobDisconnectModal: false,
                    showStep3Myob: false,
                    myobActive: false
                });
            })
         
        });
    }
    handleChangeMYOBCompany= selectedOption => {
        this.setState({ selectedMYOBCompany : selectedOption.value });
    };
    handleSelectCompany = () => {
        const { selectedMYOBOptions, selectedMYOBCompany  } = this.state
        this.props.handleLoading(true)
        let currentProfile = this.props.profileStore.getCurrentProfile();
        if(!currentProfile?.hasOrganisation || currentProfile?.isOwnOrganization){
           
        }else{
            currentProfile = currentProfile?.organisationOwner
            // this.setState({
            //     myobActive: currentProfile?.organisationOwner?.activateMYOBAccess,
            //     myobAccount: currentProfile?.organisationOwner?.myobCompanyName
            // });
        }
        var selectedOptions = selectedMYOBOptions.filter(x=>x.value === selectedMYOBCompany)[0]

        this.props.myobStore
        .handleSelectCompany({ profileId:currentProfile?.id,myobCompanyUri: selectedOptions.value, myobCompanyName: selectedOptions.label})
        .finally(response => {
                this.props.profileStore.loadProfiles().finally(() => {
                    this.props.handleLoading(false)
                    this.setState({
                        selectCompanyPhase: false,
                        selectedMYOBOptions : [selectedOptions],
                        selectedMYOBCompany : selectedOptions.value
                    });
                })
        });
    }

    render() {
        const { currentUser } = this.props.userStore;
        const { currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        const { panelMYOBExpanded,panelXEROExpanded,showStep3XERO,XEROActive, myobActive,showStep3Myob,selectCompanyPhase,  selectedMYOBOptions, selectedMYOBCompany } = this.state;

        let hasSubscribe = false;
        // if (token && currentProfile && jwtDecode(token).scope.filter(x => x === currentProfile?.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
            ((currentProfile?.type != ProfileType.Staff.ordinal) || ((currentProfile?.type === ProfileType.Staff.ordinal && (!currentProfile?.hasOrganisation || currentProfile?.allowInvoices ))))  && ENABLE_MYOB &&
           (
               <Grid container style={{marginTop: 20}}spacing={0}>
                   <Grid item md={12}>
                       <ExpansionPanel expanded={panelMYOBExpanded} onChange={this.expandMYOB}>
                           <ExpansionPanelSummary expandIcon={this.props.hgBillingStore.billingAccess?.isProPlan ? 
                           <ExpandMoreIcon /> : null}>
                                   <Grid style={{display: panelMYOBExpanded ? "none" :"inline"}}container spacing={8}>
                                   <Grid item md={3} xs={12}  spacing={0}>
                                           <div style={{float: "left",marginRight:"20px",marginTop:"15px",marginBottom:"15px"}}>                                                          <img
                                                   src={myobIcon}
                                                   alt="MYOB"
                                                   width="100px"
                                               />
                                           </div>
                                   </Grid>
                                   {/* <Grid item md={9} xs={12}  spacing={0}>
                                           <div  style={{float: "left", textAlign: "left"}}>
                                               <h3>MYOB Account</h3>
                                           </div>
                                   </Grid> */}
                               </Grid>
                           </ExpansionPanelSummary>
                           <ExpansionPanelDetails>
                                   <Fragment>
                                       <Grid container spacing={8}>
                                           <Grid item md={5} xs={12}  spacing={0}
                                               direction="column"
                                               alignItems="center"
                                               justify="center" style={{ textAlign: "left", borderRight:"solid 1px"}}>
                                           <div class="row" style={{margin: "10px" }}>
                                               <div style={{float: "left",width: "30%",height:"100%",verticalAlign:"middle"}}>                                                          <img
                                                       src={myobIcon}
                                                       alt="Google Calendar"
                                                       width="100px"
                                                   />
                                               </div>
                                           </div>
                                               
                                               <Typography gutterBottom>
                                                   Two way integration between your Hot Giggity invoices and your myob accounting package. For detailed instruction,watch this how to video
                                               </Typography>
                                           </Grid>
                                           { showStep3Myob ?
                                               <Grid item md={7} xs={12}  container
                                               spacing={0}
                                               direction="column"
                                               alignItems="center"
                                               justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                                   
                                                   <Grid container spacing={2}>
                                                   
                                                       <Grid item md={6}>
                                                           <Typography gutterBottom>Step 1. Connect Hot Giggity to your MYOB account</Typography>
                                                           {
                                                                       myobActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected</Typography> :
                                                                       <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: MYOB not connected</Typography>
                                                           }
                                                           
                                                       </Grid>
                                                       
                                                       <Grid item md={6} style={{textAlign:"center"}}>
                                                           {
                                                               myobActive ? 
                                                               <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenMYOBModal} >
                                                                   disconnect
                                                               </Button> :
                                                               <Button
                                                                   variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary"
                                                                   target="_blank"
                                                                   href={`https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_KEY}&redirect_uri=${MYOB_REDIRECT_URL}&response_type=code&scope=CompanyFile la.global&state=${currentUser.id
                                                                       }`}
                                                                   disabled={!hasSubscribe}
                                                               >
                                                                   Connect MYOB Account
                                                               </Button>
                                                           }
                                                               

                                                       </Grid>

                                                       <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                           <Divider orientation="vertical" flexItem/>
                                                       </Grid>

                                                       {
                                                           !selectCompanyPhase ?
                                                           (
                                                               <Grid container spacing={2}>
                                                                   <Grid item md={6} xs={12}>
                                                                   <Typography gutterBottom>Step 2. Select Company Profile</Typography>
                                                                   <Select value={selectedMYOBCompany} onChange={this.handleChangeMYOBCompany} options={selectedMYOBOptions} disabled />
                                                                   </Grid>
                                                                   <Grid item md={6} xs={12} style={{textAlign:"center"}}>
                                                                       {/* <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleSelectCompany} >
                                                                           CONNECT ACCOUNT
                                                                       </Button> */}
                                                                   </Grid>
                                                                   <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                       <Divider orientation="vertical" flexItem/>
                                                                   </Grid>
                                                                   <Grid item md={6}>
                                                                   <Typography gutterBottom>Step 3. Complete your MYOB set up</Typography>
                                                                   <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>Note You must complete the set up process to use this feature</Typography> 
                                                                   </Grid>
                                                                   <Grid item md={6} style={{textAlign:"center"}}>
                                                                       <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={() => this.props.history.push("/myobSetting")} >
                                                                           Manage MYOB
                                                                       </Button> 
                                                                   </Grid>

                                                                   <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                       <Divider orientation="vertical" flexItem/>
                                                                   </Grid>
                                                               </Grid>
                                                           ) :
                                                           (
                                                               <Grid container spacing={2}>
                                                                   <Grid item md={6} xs={12}>
                                                                   <Typography gutterBottom>Step 2. Select Company Profile</Typography>
                                                                   <Select value={selectedMYOBCompany} onChange={this.handleChangeMYOBCompany} options={selectedMYOBOptions} />
                                                                   </Grid>
                                                                   <Grid item md={6} xs={12} style={{textAlign:"center"}}>
                                                                       <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleSelectCompany} >
                                                                           CONNECT ACCOUNT
                                                                       </Button>
                                                                   </Grid>
                                                                   <Grid item xs={12} style={{marginTop:"25px",marginBottom:"25px"}}> 
                                                                       <Divider orientation="vertical" flexItem/>
                                                                   </Grid>
                                                               </Grid>
                                                           )
                                                       
                                                       }

                                                       
                                                   </Grid>

                                                   
                                               </Grid>
                                                       
                                               : 
                                               <Grid item md={7} xs={12}  container
                                               spacing={0}
                                               direction="column"
                                               alignItems="center"
                                               justify="center" style={{paddingLeft:"15px",textAlign: "left",verticalAlign:"middle" }}>
                                               
                                                   <Grid container spacing={2}>
                                               
                                                       <Grid item md={6}>
                                                           <Typography gutterBottom>Step 1. Connect Hot Giggity to your MYOB account</Typography>
                                                           {
                                                                       myobActive ?  <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: Successfully connected to (myobaccountname)</Typography> :
                                                                       <Typography style={{color:"grey",fontSize: "0.725rem"}}gutterBottom>STATUS: MYOB not connected</Typography>
                                                           }
                                                       
                                                       </Grid>
                                                       <Grid item md={6} style={{textAlign:"center"}}>
                                                           {
                                                               myobActive ? 
                                                               <Button variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary" onClick={this.handleOpenMYOBModal} >
                                                                   disconnect
                                                               </Button> :
                                                               <Button
                                                                   variant="contained" style={{ marginTop: 25, marginLeft: 10 }} color="primary"
                                                                   target="_blank"
                                                                   href={`https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_KEY}&redirect_uri=${MYOB_REDIRECT_URL}&response_type=code&scope=CompanyFile la.global&state=${currentUser?.id
                                                                       }`}
                                                                   disabled={!hasSubscribe}
                                                               >
                                                                   Connect MYOB Account
                                                               </Button>
                                                           }
                                                           

                                                       </Grid>


                                                       
                                                   </Grid>

                                               
                                               </Grid>
                                               
                                           }
                                           
                                       </Grid>

                                       <ConfirmationModal
                                            open={this.state.showMyobDisconnectModal}
                                            title="Are you sure you want to disconnect your MYOB account?"
                                            message="By disconnecting your account, you will no longer be able to sync your customer invoices and supplier bills between Hot Giggity and MYOB. </br>
                                            The synchronisation data in Hot Giggity will also be deleted. </br>
                                            Do you want to continue?"
                                            closeHandler={this.handleCloseMYOBModal}
                                            confirmationHandler={this.handleDisconnectMYOB}
                                            declineHandler={this.handleCloseMYOBModal}
                                            confirmationLabel="Disconnect"
                                            declineLabel="Cancel"
                                            maxWidth="sm"
                                        />
                                   </Fragment>
                           </ExpansionPanelDetails>
                       </ExpansionPanel>
                   </Grid>
               </Grid>
           ) 
        )
    }
}

export default MYOBComponent;
