import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button,Grid,Menu, MenuItem } from "@material-ui/core";
import ConfirmationModal from "../../modal/ConfirmationModal";

import ResponsiveTable from "../../table/ResponsiveTable";

import Heading from "../../Heading";

import { ProfileType } from "../../../types/enum";
import VenueRequestModal from "./VenueRequestModal";
import VenueInvitationModal from "./VenueInvitationModal";

import VenueActModal from "./VenueActModal";

import AgentActInvitationModal from "./AgentActInvitationModal";
import TableRowProfile from "../../util/TableRowProfile";

import { ProfileRelationType } from "../../../types/enum";
import image from "../../../helper/image";

@inject("agentVenueStore", "templateStore", "venueActStore", "userInvitationStore", "profileStore")
@observer
class AgentVenueTable extends Component {
    state = {
        venueRequestModalState: false,
        deleteModalState: false,
        venueInvitationModalState: false,
        venueActModalState: false,
        actInvitationModalState: false,
        request: undefined,
        agentVenue: undefined,
        venueName: "this venue",
        deleteRequestModalState: false,
        deleteInvitationModalState: false,
        actionAnchor: [],
    };

    columns = [
        { key: "venue", label: "Venue", width: "30%" },
        { key: "status", label: "Status", width: "10%" },
        { key: "bookingDay", label: "Booking Days", width: "30%" },
        { key: "action", label: "", width: "30%" }
    ];

    rowData = [];
    pendingRowData = [];

    constructor(props) {
        super(props);
        this.props.userInvitationStore.resetInvitations();
    }

    componentDidMount() {
        this.props.agentVenueStore.resetData();
        this.props.templateStore.showLoadingScreen();
        const currentProfile = this.props.profileStore.getCurrentProfile()
        this.setState(
            {
                ...this.state
            },
            () => {
                this.rowData = [];
                this.props.profileStore.loadProfiles().then(() => {

                    if(currentProfile?.isConsultant){
                        Promise.all([
                            this.props.agentVenueStore.getAgentVenueRequest(),
                            this.props.agentVenueStore.getConsultantVenueRosterV2(currentProfile?.id,10,1),
                            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal, currentProfile?.id)
                        ]).then(() => {
                            this.props.templateStore.hideLoadingScreen();
                        });
                    }else{
                        Promise.all([
                            this.props.agentVenueStore.getAgentVenueRequest(),
                            this.props.agentVenueStore.getMyVenueRosterV2(currentProfile?.id, 10, 1),
                            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal, currentProfile?.id)
                        ]).then(() => {
                            this.props.templateStore.hideLoadingScreen();
                        });
                    }
                })}
        );
    }

    loadMoreAgentVenues = (page) => {
        const currentProfile = this.props.profileStore.getCurrentProfile()
        if(currentProfile?.isConsultant){
            this.props.agentVenueStore.getConsultantVenueRosterV2(currentProfile?.id, 10, page).then(() =>{
                this.props.templateStore.hideLoadingScreen();
                this.props.agentVenueStore.setAgentVenuePage(page)
            })
        }else{
            this.props.agentVenueStore.getMyVenueRosterV2(currentProfile?.id, 10, page).then(() =>{
                this.props.templateStore.hideLoadingScreen();
                this.props.agentVenueStore.setAgentVenuePage(page)
            })
        }
       
    }
    handleShowMore = e => {
        var page = this.props.agentVenueStore.agentVenuePage + 1;
        this.props.templateStore.showLoadingScreen();
        
        this.loadMoreAgentVenues(page)
    };
    mapBookingDay = data => {
        var bookingDay = "";
        if(data) {
            if (data.monday) {
                bookingDay += "Mon, ";
            }
            if (data.tuesday) {
                bookingDay += "Tue, ";
            }
            if (data.wednesday) {
                bookingDay += "Wed, ";
            }
            if (data.thursday) {
                bookingDay += "Thu, ";
            }
            if (data.friday) {
                bookingDay += "Fri, ";
            }
            if (data.saturday) {
                bookingDay += "Sat, ";
            }
            if (data.sunday) {
                bookingDay += "Sun, ";
            }
        }
        return bookingDay.substr(0, bookingDay.length - 2);
    };


    mapInvitationToRowData = () => {
        this.pendingRowData = [];
        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};
            row.id = "i" + data.id;
            row.data = data;

            row.style = {
                backgroundColor: "#EEE"
            };
            var jsonBookingDay = JSON.parse(data.additionalData);

            row.bookingDay = this.mapBookingDay(jsonBookingDay);

            row.venue = <TableRowProfile name={data.invitedVenue} />;

            row.status = "Invited";

            row.action = (
                <Button size="small" color="primary" onClick={() => this.openDeleteInvitationModal(data)}>
                    Remove
                </Button>
            );
            this.pendingRowData.push(row);
        });

        return Promise.resolve();
    };

    mapPendingToRowData = () => {
        this.props.agentVenueStore.pendingAgentVenues.forEach(data => {
            // console.log("DATA", data)
            var row = {};
            if(data && data.primaryProfile && data.rules) {

                var venue = data.primaryProfile && data.primaryProfile.type === ProfileType.Venue.ordinal ? data.primaryProfile : data.secondaryProfile;
    
                row.id = "p" + data.id;
    
                row.data = data;
    
                row.style = {
                    backgroundColor: "#EEE"
                };
    
                var jsonBookingDay = JSON.parse(data.rules);
    
                row.bookingDay = this.mapBookingDay(jsonBookingDay);
    
                row.venue = <TableRowProfile profileId = { venue.id } name={venue.fullName} avatarUrl={image.getProfileImage(venue)}/>;
    
                row.status = "Pending";
    
                row.action =
                    data.primaryProfile.type !== ProfileType.Agent.ordinal ? (
                        <Fragment>
                            <Button color="primary" size="small" id="btn-venue-request" onClick={() => this.openVenueRequest(data)}>
                                View Request
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Button color="primary" size="small" id="btn-remove-request" onClick={() => this.openDeleteRequestModal(data)}>
                                Remove Request
                            </Button>
                        </Fragment>
                    );
            }

            this.pendingRowData.push(row);
        });
    };

    setActionAnchor = (element, agentVenueId) => {
        var tempAnchor = [];
        tempAnchor[agentVenueId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = agentVenueId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    redirectVenueFavorite = data => {
        agentVenue
    }
    mapApprovedToRowData = () => {
        this.rowData = [];
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.agentVenueStore.agentVenues.forEach(data => {
            var row = {};

            var venue = data.venue;

            row.id = "a" + data.id;

            row.data = data;

            row.bookingDay = this.mapBookingDay(data);

            row.venue = venue && <TableRowProfile profileId = { venue.id } name={venue.fullName} avatarUrl={image.getProfileImage(venue)}/>;

            row.status = "Accepted";

            row.action = (
                // <Fragment>
                //     {data.manage ? (
                //         <Button color="primary" size="small" onClick={() => this.openActModal(data)}>
                //             Manage Venue Favourites
                //         </Button>
                //     ) : (
                //         ""
                //     )}
                //     {data.agentId === currentProfile?.id ? (
                //         <Button color="primary" size="small" onClick={() => this.openDeleteModal(data)}>
                //             Remove
                //         </Button>
                //     ) : (
                //         ""
                //     )}
                // </Fragment>

                <Fragment>
                     <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, data.id)}>
                     <i class="fa-solid fa-ellipsis-vertical fa-xl"></i>
                                    </Button>
                                    {data.id && (
                                        <Menu
                                            open={this.state.actionAnchor[data.id] != null}
                                            onClose={() => this.unsetActionAchnor(data.id)}
                                            anchorEl={this.state.actionAnchor[data.id]}
                                        >
                                            <MenuItem 
                                             button
                                             className="booking-details"
                                             onClick={() => this.props.createBooking(venue?.id)}
                                            >Create Booking</MenuItem>
                                           
                                           
                                            {data.manage ? (
                                                <MenuItem onClick={() => this.props.redirectVenueFavourite(data)}>Manage Venue’s Favorite Acts</MenuItem>
                                            ) : (
                                                ""
                                            )}
                                            {data.agentId === currentProfile?.id ? (
                                                <MenuItem onClick={() => this.openDeleteModal(data)}>Remove</MenuItem>
                                            ) : (
                                                ""
                                            )}
                                       </Menu>
                                    )}
                </Fragment>
            );

            this.rowData.push(row);
        });
    };

    //event

    openInvitationModal = () => {
        this.props.agentVenueStore.resetInvitationField();
        this.setState({
            ...this.state,
            venueInvitationModalState: true
        });
    };

    closeInvitationModal = () => {
        this.setState({
            ...this.state,
            venueInvitationModalState: false
        });
    };

    openDeleteModal = agentVenue => {
        this.setState({
            ...this.state,
            deleteModalState: true,
            agentVenue: agentVenue,
            venueName: agentVenue.venue.profileName
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteModalState: false,
            agentVenue: undefined
        });
    };

    handleDelete = () => {
        this.props.agentVenueStore.deleteAgentVenue(this.state.agentVenue.id).then(() => {
            this.props.templateStore.openSnackbar("Venue relation has been deleted.");
            this.closeDeleteModal();
        });
    };

    openDeleteRequestModal = request => {
        this.setState({
            ...this.state,
            deleteRequestModalState: true,
            request: request,
            venueName: request.secondaryProfile.fullName
        });
    };

    closeDeleteRequestModal = () => {
        this.setState({
            ...this.state,
            deleteRequestModalState: false,
            request: undefined
        });
    };

    handleDeleteRequest = () => {
        this.props.agentVenueStore.removeAgentVenueRequest(this.state.request.id).then(() => {
            this.props.templateStore.openSnackbar(`Venue relation request has been deleted.`);
            this.closeDeleteRequestModal();
        });
    };

    openVenueRequest = request => {
        this.setState({
            ...this.state,
            venueRequestModalState: true,
            request: request
        });
    };

    closeVenueRequest = () => {
        this.setState({
            ...this.state,
            venueRequestModalState: false,
            request: undefined
        });
    };

    openActModal = agentVenue => {
        this.setState({
            ...this.state,
            venueActModalState: true,
            agentVenue: agentVenue
        });
    };

    closeActModal = () => {
        this.setState({
            ...this.state,
            venueActModalState: false,
            agentVenue: undefined
        });
    };

    openAddActModal = () => {
        this.setState(
            {
                ...this.state,
                venueActModalState: false,
                actInvitationModalState: true
            },
            () => {
                this.props.venueActStore.clearInviteData();
            }
        );
    };

    closeAddActModal = () => {
        this.setState({
            ...this.state,
            actInvitationModalState: false,
            venueActModalState: true
        });
    };

    openDeleteInvitationModal = invitation => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: true,
            request: invitation,
            venueName: invitation.invitedVenue
        });
    };

    closeDeleteInvitationModal = () => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: false,
            request: undefined,
            venueName: "this venue"
        });
    };

    handleDeleteInvitation = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.userInvitationStore.deleteInvitation(this.state.request).then(() => {
            this.props.templateStore.hideLoadingScreen();
            this.props.templateStore.openSnackbar(`Invitation for ${this.state.venueName} has been removed.`);
            this.closeDeleteInvitationModal();
        });
    };

    render() {
        this.mapInvitationToRowData().then(this.mapPendingToRowData());
        this.mapApprovedToRowData();
        var messageRemoveVenue = `<p>Are you sure you want to delete the request to manage bookings at <b>${this.state.venueName}</b>?</p>`
        var messageRemoveVenueAndBooking = `<p>Are you sure you want to remove <b>${this.state.venueName}</b>? Your current bookings at <b>${this.state.venueName}</b> will be cancelled.</p>`
        
        return (
            <Fragment>
                <Heading title="My Venue Roster">
                    <Button color="primary" variant='contained' onClick={this.openInvitationModal} className="pull-right">
                        Add Venue
                    </Button>
                </Heading>
                {this.pendingRowData.length > 0 ? (
                    <Fragment>
                        <ResponsiveTable
                            columns={this.columns}
                            rows={this.pendingRowData}
                            showToolbar={true}
                            title={"Pending Venues"}
                            pagination={this.pendingRowData.length > 5}
                        />
                        <br />
                    </Fragment>
                ) : (
                    ""
                )}
                <div style={{backgroundColor:"white", paddingBottom:15}}>
                <ResponsiveTable columns={this.columns} rows={this.rowData} />
                <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                    <Grid container direction="row" justify="center" alignItems="center">
                        {this.props.agentVenueStore.isMoreAgentVenues ? (
                            <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                Show More
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
                </div>

               
                <VenueActModal
                    open={this.state.venueActModalState}
                    onClose={this.closeActModal}
                    openAddActModal={this.openAddActModal}
                    venue={this.state.agentVenue ? this.state.agentVenue.venue : undefined}
                    manage={this.state.agentVenue ? this.state.agentVenue.manage : false}
                />

                <AgentActInvitationModal
                    open={this.state.actInvitationModalState}
                    onClose={this.closeAddActModal}
                    venue={this.state.agentVenue ? this.state.agentVenue.venue : undefined}
                />

                <VenueInvitationModal open={this.state.venueInvitationModalState} onClose={this.closeInvitationModal} />

                <VenueRequestModal open={this.state.venueRequestModalState} onClose={this.closeVenueRequest} request={this.state.request} />

                <ConfirmationModal
                    open={this.state.deleteInvitationModalState}
                    title={"Delete Venue Request"}
                    message={messageRemoveVenue}
                    closeHandler={this.closeDeleteInvitationModal}
                    confirmationHandler={this.handleDeleteInvitation}
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    open={this.state.deleteModalState}
                    title={"Delete Venue"}
                    message={messageRemoveVenueAndBooking}
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDelete}
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    open={this.state.deleteRequestModalState}
                    title={"Withdraw venue management request confirmation"}
                    message={"Are you sure you would like to withdraw your request to manage this venue?"}
                    closeHandler={this.closeDeleteRequestModal}
                    confirmationHandler={this.handleDeleteRequest}
                    confirmationLabel="Delete"
                />
            </Fragment>
        );
    }
}

export default AgentVenueTable;
