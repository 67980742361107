import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../img/hotgiggity-logo.png";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import moment from "moment";
import Grid from '@material-ui/core/Grid';

import "./EmbedGig.css";


import { ProfileType } from "../../types/enum";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const maxText = 500;
const blankBackground = "/hero2.jpg";

@inject("profileStore", "bookingRequestStore", "reportStore", "hgBillingStore")
@withRouter
@observer
class Embed extends Component {
    state = {
        profile: null,
        finishLoading: false, 
        performData: null,
        isFreePlan: false
    };

    constructor(props) {
        super(props);
        this.props.reportStore.clearDateRangeFilter();
    }

    componentDidMount() {

        this.loadProfile();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadProfile();
        }
    }

    loadProfile = () => {
        const param = this.props.match.params.id;

        this.props.reportStore.clearDateRangeFilter();
        // this.props.reportStore.setFilterType("week");
        this.props.hgBillingStore.getProfileAccess(param).then((access) => {
           
                this.props.profileStore.getPublicProfileById(param).then(result => {
                    if(!access || access?.isFreePlan){    
                        this.setState({
                            ...this.state, 
                            isFreePlan: true,
                            finishLoading: true,
                            profile: result
                        })
                    }else{
                        this.setState({
                            ...this.state, 
                            profile: result
                        })
                        
                        this.props.bookingRequestStore.loadEntertainmentUpcomingGigsWithoutAdhocGigGuideEmbed(result.id).then((upcomings) => {
                            this.setState({
                                upcomings: upcomings, 
                                finishLoading: true,
                                profile: result, 
                                isFreePlan: false,
                                performData: this.processUpcomingGigs(upcomings)
                            });
                        });
                    }
                   
                })
            }
        )
    };

    processUpcomingGigs = upcomingGigs => {
        let result = [];
        if (upcomingGigs && upcomingGigs.length > 0)
        {
            upcomingGigs = upcomingGigs.sort((a,b) => (a.dateStart > b.dateStart) ? 1 : ((b.dateStart > a.dateStart) ? -1 : 0))
            for (let i = 0; i < upcomingGigs.length; i++) {
                let dateStart = moment(upcomingGigs[i].dateStart);

                let data = {
                    eventId: upcomingGigs[i].id, 
                    year: dateStart.format("YYYY"), 
                    month: dateStart.format("MMMM"),
                    date: dateStart.format("D"),
                    day: dateStart.format("dddd"),
                    actId: upcomingGigs[i].act?.id,
                    venueId: upcomingGigs[i].venue?.id,
                    time:
                        moment(upcomingGigs[i].timeStart, "HH:mm:ss.SSS").format("h.mma") +
                        " - " +
                        moment(upcomingGigs[i].timeEnd, "HH:mm:ss.SSS").format("h.mma"),
                    title: upcomingGigs[i].title ? upcomingGigs[i].title : upcomingGigs[i].act.actName + '@' + upcomingGigs[i].venueName, 
                    venueName: upcomingGigs[i].venueName,
                    venueAddress: upcomingGigs[i].venueAddress,
                    venueType: upcomingGigs[i].venue && upcomingGigs[i].venue.profileVenueTypes && upcomingGigs[i].venue.profileVenueTypes.map(profileVenueType => profileVenueType.venueType.name).join(', '), 
                    venueCover: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImagesNew ? upcomingGigs[i].venue.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    venueCoverAlt: upcomingGigs[i].venue && upcomingGigs[i].venue.backgroundImages && upcomingGigs[i].venue.backgroundImages.length > 0 ? upcomingGigs[i].venue.backgroundImages[0].fileName : "Venue Cover", 
                    venueLatitude: upcomingGigs[i].venue && upcomingGigs[i].venue.latitude ? upcomingGigs[i].venue.latitude : 0, 
                    venueLongitude: upcomingGigs[i].venue && upcomingGigs[i].venue.longitude ? upcomingGigs[i].venue.longitude : 0, 
                    venueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? upcomingGigs[i].venue.bio : '', 
                    shortVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio ? this.truncateText(upcomingGigs[i].venue.bio) : '', 
                    expandableVenueBio: upcomingGigs[i].venue && upcomingGigs[i].venue.bio && upcomingGigs[i].venue.bio.length > maxText ? true : false, 
                    expandVenueBio: false, 
                    actName: upcomingGigs[i].act.actName,
                    actGenres: upcomingGigs[i].act && upcomingGigs[i].act.profileGenres && upcomingGigs[i].act.profileGenres.map(profileGenre => profileGenre.genre.name).join(', '), 
                    actCover: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImagesNew ? upcomingGigs[i].act.backgroundImagesNew.url960 : BASE_URL + "hero2.jpg", 
                    actCoverAlt: upcomingGigs[i].act && upcomingGigs[i].act.backgroundImages && upcomingGigs[i].act.backgroundImages.length > 0 ? upcomingGigs[i].act.backgroundImages[0].fileName : "Act Cover", 
                    actBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? upcomingGigs[i].act.bio : '',
                    shortActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio ? this.truncateText(upcomingGigs[i].act.bio) : '', 
                    expandableActBio: upcomingGigs[i].act && upcomingGigs[i].act.bio && upcomingGigs[i].act.bio.length > maxText ? true : false, 
                    expandActBio: false
                };
                    
                result.push(data);
                
            }

        }

        return result
    };

    truncateText = text => {
        if(text.length > maxText) {
            return text.substring(0, maxText) + '...';
        }
        else {
            return text;
        }
    }

    manipulatePerformData = (index, type) => {
        const { performData } = this.state;
        let tempData = performData;

        if(type === 'act') {
            tempData[index].expandActBio = !tempData[index].expandActBio;
        }
        else {
            tempData[index].expandVenueBio = !tempData[index].expandVenueBio;
        }

        this.setState({
            ...this.state, 
            performData: tempData
        })
    }

    render() {
        const { profile, performData, isFreePlan} = this.state;

        const queryString = this.props.location.search;
        const params = new URLSearchParams(queryString);
        // const headerBgColor = params.get("headerBgColor") ? "#" + params.get("headerBgColor") : "#262626";
        // const headerFtColor = params.get("headerFtColor") ? "#" + params.get("headerFtColor") : "#fff";
        // const dateBgColor = params.get("dateBgColor") ? "#" + params.get("dateBgColor") : "#ff0c9c";
        // const dateFtColor = params.get("dateFtColor") ? "#" + params.get("dateFtColor") : "#000000";
        const tableBgColor = params.get("tableBgColor") ? "#" + params.get("tableBgColor") : "#ffff";
        const tableFtColor = params.get("tableFtColor") ? "#" + params.get("tableFtColor") : "#000000";
        const hideToolbar = params.get("hideToolbar") || false;
        // const hideOrderUber = params.get("hideOrderUber") || false;
        // const hideShareButton = params.get("hideShareButton") || false;
        const toolbarState = JSON.parse(hideToolbar);
        let user = "";

        if (profile) {
            user =
                profile.typeString === "act"
                    ? profile.actName
                    : profile.typeString === "agent"
                        ? profile.agencyName || profile.profileName
                        : profile.typeString === "venue"
                            ? profile.profileName
                            : profile.firstName + " " + profile.lastName;
        }

        return (
            <Fragment>
                <Paper>
                    {!toolbarState && (
                        <Fragment>
                            <Hidden smUp>
                            <Toolbar className="header-embed">
                                    <Grid item xs={6}>

                                    <Typography variant="headline">{user + "'s Upcoming Gigs"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                    <div style={{ textAlign: "right", float:"right" }}>
                                        <Typography variant="caption" style={{ float: "left", paddingTop: 14, paddingRight: 5 }}>
                                            Powered By
                                            <img style={{marginLeft:5}} src={Logo} alt="hotgiggity logo" width="30%" />
                                        </Typography>
                                    </div>
                                    </Grid>
                                </Toolbar>
                            </Hidden>
                            <Hidden xsDown>
                                <Toolbar className="header-embed">
                                    <Grid item xs={6}>

                                    <Typography variant="headline">{user + "'s Upcoming Gigs"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                    <div style={{ textAlign: "right", float:"right" }}>
                                        <Typography variant="caption" style={{ float: "left", paddingTop: 14, paddingRight: 5 }}>
                                            Powered By
                                            <img style={{marginLeft:5}} src={Logo} alt="hotgiggity logo" width="30%" />
                                        </Typography>
                                    </div>
                                    </Grid>
                                </Toolbar>
                            </Hidden>
                        </Fragment>

                    )}
                    <div style={{display:isFreePlan? "none" : "block"}} className={!toolbarState ? "gig-table-container" : ""}>
                        <Table>
                            {/* <TableHead>
                                <TableRow style={{ backgroundColor: headerBgColor }}>
                                    <TableCell className="gig-column">
                                        <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                            Cover
                                        </Typography>
                                    </TableCell>
                                    {profile &&
                                        profile.typeString !== "act" && (
                                            <TableCell className="gig-column">
                                                <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                                    Act
                                                </Typography>
                                            </TableCell>
                                        )}
                                    {profile &&
                                        profile.typeString !== "venue" && (
                                            <TableCell className="gig-column">
                                                <Typography align="center" style={{ color: headerFtColor }} variant="subheading">
                                                    Venue
                                                </Typography>
                                            </TableCell>
                                        )}
                                </TableRow>
                            </TableHead> */}

                            <TableBody>
                                {performData && performData.map((data, index) => {
                                    if(profile?.type === ProfileType.Act.ordinal || profile?.type == ProfileType.Musician.ordinal) {
                                        return (
                                            <TableRow key={index} style={{ verticalAlign: 'top' }}>
                                                <TableCell className="gig-column" style={{ width: '40%', backgroundColor: tableBgColor }}>
                                                    {/* <Card onClick={() => this.props.history.push(`/live/gigs/${data.eventId}`)}> */}
                                                    <Card onClick={() => this.props.history.push(`/profile/${profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal?  data.venueId : data.actId}`)}>
                                                    
                                                        <CardActionArea>
                                                            <CardMedia
                                                                title={data.venueCoverAlt}
                                                                component="img"
                                                                className="imageStyleDetailInfo"
                                                                image={data.venueCover ? data.venueCover : blankBackground}
                                                            />
                                                        </CardActionArea>
                                                    </Card>
                                                </TableCell>
                                                <TableCell className="gig-column" style={{ backgroundColor: tableBgColor }}>
                                                    <div style={{ padding: 10 }}>
                                                        {/* <Typography variant="h5" gutterBottom style={{ color: tableFtColor, display: 'inline', borderBottom: 'solid 1px ' + tableFtColor, cursor: 'pointer' }} onClick={() => this.props.history.push(`/live/gigs/${data.eventId}`) }> */}
                                                        <Typography variant="h5" gutterBottom style={{ color: tableFtColor, display: 'inline', borderBottom: 'solid 1px ' + tableFtColor, cursor: 'pointer' }} onClick={() => this.props.history.push(`/profile/${profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ?  data.venueId : data.actId}`) }>
                                                            {profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal? data.venueName :data.actName}
                                                        </Typography>
                                                        <Typography component="p" gutterBottom style={{ fontStyle: 'italic', fontSize: 'small', color: tableFtColor }}>
                                                            {data.venueType}
                                                        </Typography>
                                                        <Typography variant="body1" style={{ color: tableFtColor}}>
                                                            {`${data.day}, ${data.date} ${data.month} ${data.year} ${data.time}`} <a target="_blank" href={`https://maps.google.com/?q=${data.venueLatitude},${data.venueLongitude}`}>(Get Directions)</a>
                                                        </Typography>
                                                        {/* <Typography variant="h6" gutterBottom style={{ color: tableFtColor }}>
                                                            {data.venueName} <a target="_blank" href={`https://maps.google.com/?q=${data.venueLatitude},${data.venueLongitude}`}>(Map)</a>
                                                        </Typography> */}
                                                        <Typography component="div" style={{ color: tableFtColor }} dangerouslySetInnerHTML={{ 
                                                                __html: data.expandableVenueBio 
                                                                    ? data.expandVenueBio
                                                                        ? data.venueBio
                                                                        : data.shortVenueBio
                                                                    : data.venueBio 
                                                            }} 
                                                        />
                                                        { 
                                                            data.expandableVenueBio 
                                                                ? 
                                                                    (
                                                                        <Typography component="a" variant="body1" onClick={() => this.manipulatePerformData(index, 'venue')} style={{ cursor: 'pointer', color: '#337ab7' }}>
                                                                            {!data.expandVenueBio ? "Show More" : "Show Less"}
                                                                        </Typography>
                                                                    )
                                                                : null
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                    else {
                                        return (
                                            <TableRow key={index} style={{ verticalAlign: 'top' }}>
                                                <TableCell className="gig-column" style={{ width: '40%', backgroundColor: tableBgColor }}>
                                                    {/* <Card onClick={() => this.props.history.push(`/live/gigs/${data.eventId}`)}> */}
                                                    <Card onClick={() => this.props.history.push(`/profile/${profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ?  data.venueId : data.actId}`)}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                title={data.actCoverAlt}
                                                                component="img"
                                                                className="imageStyleDetailInfo"
                                                                image={data.actCover}
                                                            />
                                                        </CardActionArea>
                                                    </Card>
                                                </TableCell>
                                                <TableCell className="gig-column" style={{ backgroundColor: tableBgColor }}>
                                                    <div style={{ padding: 10 }}>
                                                        {/* <Typography variant="h5" gutterBottom style={{ color: tableFtColor, display: 'inline', borderBottom: 'solid 1px ' + tableFtColor, cursor: 'pointer' }} onClick={() => this.props.history.push(`/live/gigs/${data.eventId}`)}> */}
                                                        <Typography variant="h5" gutterBottom style={{ color: tableFtColor, display: 'inline', borderBottom: 'solid 1px ' + tableFtColor, cursor: 'pointer' }} onClick={() => this.props.history.push(`/profile/${profile?.type === ProfileType.Musician.ordinal || profile?.type === ProfileType.Act.ordinal ?  data.venueId : data.actId}`)}>
                                                            {data.actName}
                                                        </Typography>
                                                        <Typography component="p" gutterBottom style={{ fontStyle: 'italic', fontSize: 'small', color: tableFtColor }}>
                                                            {data.actGenres}
                                                        </Typography>
                                                        <Typography variant="body1" gutterBottom style={{ color: tableFtColor}}>
                                                            {`${data.day}, ${data.date} ${data.month} ${data.year} ${data.time}`} <a target="_blank" href={`https://maps.google.com/?q=${data.venueLatitude},${data.venueLongitude}`}>(Get Directions)</a>
                                                        </Typography>
                                                        {/* <Typography variant="h6" gutterBottom style={{ color: tableFtColor }}>
                                                            {data.venueName} <a target="_blank" href={`https://maps.google.com/?q=${data.venueLatitude},${data.venueLongitude}`}>(Map)</a>
                                                        </Typography> */}
                                                        <Typography component="div" style={{ color: tableFtColor }} dangerouslySetInnerHTML={{ 
                                                                __html: data.expandableActBio 
                                                                    ? data.expandActBio
                                                                        ? data.actBio
                                                                        : data.shortActBio
                                                                    : data.actBio 
                                                            }} 
                                                        />
                                                        { 
                                                            data.expandableActBio 
                                                                ? 
                                                                    (
                                                                        <Typography component="a" variant="body1" onClick={() => this.manipulatePerformData(index, 'act')} style={{ cursor: 'pointer', color: '#337ab7' }}>
                                                                            {!data.expandActBio ? "Show More" : "Show Less"}
                                                                        </Typography>
                                                                    )
                                                                : null
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <div style={{height:"100%", display:isFreePlan? "block": "none" }} className={!toolbarState ? "gig-table-container" : ""}>
                    <h2>This feature is only available with the Hot Giggity Pro Plan.</h2>   
                    </div>
                </Paper>
                {toolbarState && (
                    <div className="embed-footer">
                        <span style={{ right: "-60px", position: "inherit", width: "10%", marginRight: 98 }}>
                            <Typography variant="caption" style={{ float: "left", paddingTop: 7, marginLeft: 45, paddingRight: 5 }}>
                                Powered By
                            </Typography>
                            <img alt="hotgiggity logo" src={Logo} width="40%" style={{ float: "right", marginLeft:5 }} />
                        </span>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default compose(withWidth())(Embed);
