import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import MainContainer from "../../components/MainContainer";
import PrivateBookingForm from "../../components/adhocBooking/PrivateBookingForm";
import Heading from "../../components/Heading";
import Breadcrumb from "../../components/Breadcrumb";
import { Paper, Step, StepButton, Hidden, Stepper } from "@material-ui/core";
import { ProfileType, BookingStatusType, BookingType } from "../../types/enum";
import Negotiate from "../../components/booking/Negotiate";
import BookingSetlist from "../../components/booking/BookingSetlist";
import BookingLineup from "../../components/booking/BookingLineup";
import "../stepper.css";
import BookingStatusBadge from "../../components/booking/BookingStatusBadge";
import History from "../../components/booking/History";
import BookingNotifyClient from "../../components/booking/BookingNotifyClient";
import BookingNotFound from "../profile/bookingInvalidAccess";
import SocialPostsDetailComponent from "../GigPromotion/SocialPostsDetailComponent"

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("profileStore", "bookingRequestStore", "templateStore", "hgBillingStore")
@observer
class PrivateBooking extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Create Act Booking/Invoice" }];

    constructor(props) {
        super(props);
        this.props.templateStore.setEditBookingStep(0);
    }

    componentDidMount() {
        
        /*if(this.props.match.params.id) {
            this.props.bookingRequestStore.initializeEditBooking(this.props.match.params.id);
        } else*/
        this.props.bookingRequestStore.resetBooking()
        if (!this.props.match.params.id) {

            let params = queryString.parse(this.props.location.search);
            this.props.bookingRequestStore.setPrivateBookingHeadingFromCalendar(moment(params.date));
            this.props.bookingRequestStore.setBooking("dateStart", moment(params.date, "DD-MM-YYYY"));
 
            this.props.bookingRequestStore.initializeBooking(params.act, undefined, BookingType.Adhoc.ordinal).then(() => {
                this.props.bookingRequestStore.setBooking("dateStart", moment(params.date, "DD-MM-YYYY"));
                this.props.bookingRequestStore.setBooking("actId", params.act);
                this.props.bookingRequestStore.setBooking("type", BookingType.Adhoc.ordinal);
                this.props.bookingRequestStore.setBooking("sharePermission", false);
                this.props.bookingRequestStore.setBooking("shareUpdate", false);
                this.props.bookingRequestStore.setBooking("forceAct", params.forceAct);
                this.props.bookingRequestStore.setBooking("clientEmail", params.email);
                this.props.bookingRequestStore.setBooking("clientFirstName", params.firstName);
                this.props.bookingRequestStore.setBooking("clientLastName", params.lastName);
                this.props.bookingRequestStore.setBooking("clientPhone", params.phone);
                if (params.timeStart) this.props.bookingRequestStore.setBooking("timeStart", params.timeStart);
                if (params.timeEnd) this.props.bookingRequestStore.setBooking("timeEnd", params.timeEnd);
                if(params && params.isPrivate && params.isPrivate==="1"){
                    if(this.props.bookingRequestStore.privateHireInitialData){
                        const currentData = this.props.bookingRequestStore.privateHireInitialData
                        if(currentData){
                            this.props.bookingRequestStore.setBooking("title", currentData.eventType);
                            this.props.bookingRequestStore.setBooking("clientFirstName", currentData.fromFirstName);
                            this.props.bookingRequestStore.setBooking("clientLastName", currentData.fromLastName);
                            this.props.bookingRequestStore.setBooking("clientEmail", currentData.fromEmail);
                            this.props.bookingRequestStore.setBooking("clientPhone", currentData.fromPhone);
                            this.props.bookingRequestStore.setBooking("timeStart", currentData.timeStart);
                            this.props.bookingRequestStore.setBooking("timeEnd", currentData.timeEnd);
                            this.props.bookingRequestStore.setBooking("dateStart", currentData.eventDate);
                            // this.props.bookingRequestStore.setBooking("depositDueDate", moment());
                            // this.props.bookingRequestStore.setBooking("balanceDueDate", currentData.eventDate);
                            this.props.bookingRequestStore.setBooking("venueAddress", currentData.address);

                            this.props.bookingRequestStore.setPrivateBookingHeadingFromCalendar(moment(params.date));
                        }
                    }
                    this.props.bookingRequestStore.setBooking("privateHireId", params.privatehireid);
                    this.props.bookingRequestStore.setIsPrivateHire(true);
                  
                }
           
                // {moment(currentData.eventDate).format("ddd, DD-MM-YYYY")} {moment(currentData.timeStart, "HH:mm").format("h.mma")} - {moment(currentData.timeEnd, "HH:mm").format("h.mma")}</td>
                                                              
                //this.props.bookingRequestStore.setBooking("clientPhone", params.phone);
            });
        }
       
    }

    shouldComponentUpdate(nextProps) {
        if (this.props && nextProps && this.props.bookingRequestStore.booking !== nextProps.bookingRequestStore.booking) {
            return true;
        }

        if (
            this.props &&
            nextProps &&
            (this.props.match.params.id !== nextProps.match.params.id ||
                this.props.match.params.bookingRequestId !== nextProps.match.params.bookingRequestId)
        ) {
            if (nextProps.match.params.id) {
                this.props.bookingRequestStore.initializeEditBooking(nextProps.match.params.id, nextProps.match.params.bookingRequestId);
            } else {
                let params = queryString.parse(nextProps.location.search);
                this.props.bookingRequestStore.initializeBooking(params.act, undefined, BookingType.Adhoc.ordinal).then(() => {
                    this.props.bookingRequestStore.setBooking("dateStart", moment(params.date, "DD-MM-YYYY"));
                    this.props.bookingRequestStore.setBooking("actId", params.act);
                });
            }
            return true;
        }

        if (this.props && nextProps && this.props.match.params.step !== nextProps.match.params.step) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.props.match && this.props.match.params && this.props.match.params.step) {
            const currentProfile = this.props.profileStore.getCurrentProfile();
            const { booking } = this.props.bookingRequestStore;
            const { step } = this.props.match.params;
            var isOwner = booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id
            let onLineup = false;
            if (booking.lineups && booking?.lineups?.length && booking.lineups[0] !== undefined){
                if (booking?.lineups?.find(x => x.profileId == currentProfile?.id)){
                    onLineup = true;
                }
            }

            if (booking.isAdmin) {
                if (
                    currentProfile?.type === ProfileType.Musician.ordinal &&
                    (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
                ) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "negotiate":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "lineup":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "setlist":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(5);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "lineup":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "notifyclient":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "setlist":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(5);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal && isOwner) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "lineup":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "notifyclient":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "setlist":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "negotiate":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "notifyclient":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                }
            }
            else {
                if (
                    currentProfile?.type === ProfileType.Musician.ordinal &&
                    (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal && !onLineup)
                ) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "lineup":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "setlist":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(5);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "lineup":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "setlist":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "negotiate":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal) {
                    switch (step) {
                        case "edit":
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                        case "negotiate":
                            this.props.templateStore.setEditBookingStep(1);
                            break;
                        case "promote":
                            this.props.templateStore.setEditBookingStep(2);
                            break;
                        case "notifyclient":
                            this.props.templateStore.setEditBookingStep(3);
                            break;
                        case "history":
                            this.props.templateStore.setEditBookingStep(4);
                            break;
                        default:
                            this.props.templateStore.setEditBookingStep(0);
                            break;
                    }
                }else {
                    this.props.templateStore.setEditBookingStep(0);
                }
            }
        } else {
            this.props.templateStore.setEditBookingStep(0);
        }
    }

    getSteps = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { booking } = this.props.bookingRequestStore;
        var isOwner = booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id
        if (booking.isAdmin) {
            if (
                currentProfile?.type === ProfileType.Musician.ordinal &&
                (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
            ) {
                // return ["Booking Details", "Negotiate/Message", "Line-up" , "Promote", "Notify Client", "Set List", "History"];
                return ["Booking Details", "Negotiate/Message", "Line-up" , "Promote", "Set List", "History"];
            } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                return ["Booking Details", "Line-up" , "Promote", "Notify Client", "Set List", "History"];
            } else if (currentProfile?.type === ProfileType.Agent.ordinal && isOwner) {
                if (booking.createdById == currentProfile?.id) {
                    return ["Booking Details", "Line-up" , "Promote",  "Notify Client", "Set List", "History"];
                } else {
                    return ["Booking Details", "Line-up" , "Promote", "Set List", "History"];
                }
            } else if(currentProfile?.type === ProfileType.PrivateHire.ordinal) {
                return ["Booking Details"];
            }else if (currentProfile?.type === ProfileType.Agent.ordinal) {
                return ["Booking Details", "Negotiate/Message", "Promote", "Notify Client", "History"];
            } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                return ["Booking Details", "Negotiate/Message", "Notify Client", "History"];
            }
        }
        else {
            if (
                currentProfile?.type === ProfileType.Musician.ordinal &&
                (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
            ) {
                return ["Booking Details",  "Line-up" , "Promote", "Set List", "History"];
            } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                return ["Booking Details", "Line-up" , "Promote", "Set List", "History"];
            } else if(currentProfile?.type === ProfileType.PrivateHire.ordinal){
                return ["Booking Details"];
            } else if (currentProfile?.type === ProfileType.Agent.ordinal ) {
                return ["Booking Details", "Negotiate/Message", "Promote", "Notify Client", "History"];
            } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                return ["Booking Details", "Negotiate/Message", "History"];
            }
        }
    };

    redirectNotifyClient = () => {
        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
    }
    handleStepClick = step => () => {
        const { booking } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        var isOwner = booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id
        if (booking.isAdmin) {
            if (
                currentProfile?.type === ProfileType.Musician.ordinal &&
                (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
            ) {
                switch (step) {
                    case 0:
                        this.props.history.replace(
                            `/privateBooking/${this.props.match.params.id}/edit${this.props.match.params.bookingRequestId ? `/${this.props.match.params.bookingRequestId}` : ""
                            }`
                        );
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/negotiate`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/lineup`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/setlist`);
                        break;
                    case 5:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(
                            `/privateBooking/${this.props.match.params.id}/edit${this.props.match.params.bookingRequestId ? `/${this.props.match.params.bookingRequestId}` : ""
                            }`
                        );
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/lineup`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/setlist`);
                        break;
                    case 5:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Agent.ordinal && isOwner) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/lineup`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/setlist`);
                        break;
                    case 5:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Agent.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/negotiate`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/negotiate`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            }
        }
        else {
            if (
                currentProfile?.type === ProfileType.Musician.ordinal &&
                (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
            ) {
                switch (step) {
                    case 0:
                        this.props.history.replace(
                            `/privateBooking/${this.props.match.params.id}/edit${this.props.match.params.bookingRequestId ? `/${this.props.match.params.bookingRequestId}` : ""
                            }`
                        );
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/lineup`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/setlist`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(
                            `/privateBooking/${this.props.match.params.id}/edit${this.props.match.params.bookingRequestId ? `/${this.props.match.params.bookingRequestId}` : ""
                            }`
                        );
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/lineup`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/setlist`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Agent.ordinal ) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/negotiate`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/promote`);
                        break;
                    case 3:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/notifyclient`);
                        break;
                    case 4:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    case 1:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/negotiate`);
                        break;
                    case 2:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/history`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            }
            else if (currentProfile?.type === ProfileType.PrivateHire.ordinal) {
                switch (step) {
                    case 0:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                    default:
                        this.props.history.replace(`/privateBooking/${this.props.match.params.id}/edit`);
                        break;
                }
            }
        }

        this.setState({
            ...this.state,
            privateBookingStep: step
        });
    };

    redirectPromote = () => {
        const bookingId = this.props.match.params.id;
        this.props.history.replace(`/privateBooking/${bookingId}/promote`);
    }

    getStepContent(stepIndex) {
        const { booking,isPrivateHire } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        var isOwner = booking.act && booking.act.actAdminId && booking.act.actAdminId === currentProfile?.id
        const readOnly =
            booking &&
            booking.id &&
            booking.id !== 0 &&
            ((booking.createdById !== currentProfile?.id && !booking.canUpdate) ||
                booking.status === BookingStatusType.Cancelled.ordinal ||
                booking.status === BookingStatusType.Completed.ordinal ||
                booking.status === BookingStatusType.Declined.ordinal ||
                moment(booking.dateStart) < moment().startOf("day"));
        if (currentProfile) {
            if (booking.isAdmin) {
                if (
                    currentProfile?.type === ProfileType.Musician.ordinal &&
                    (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
                ) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm readOnly={readOnly} />;
                        case 1:
                            return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <BookingLineup type="musician" redirectActionPromote={this.redirectPromote} redirectAction={this.redirectNotifyClient} bookingId={this.props.match.params.id} />;
                        case 3:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 4:
                            // return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} />;
                            return <BookingSetlist type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote} readOnly={readOnly} />;
                        case 5:
                            // return <BookingSetlist type="musician" bookingId={this.props.match.params.id} readOnly={readOnly} />;
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <BookingLineup type="musician" redirectActionPromote={this.redirectPromote} redirectAction={this.redirectNotifyClient}  bookingId={this.props.match.params.id} />;
                        case 2:
                                return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote}/>;
                        case 4:
                            return <BookingSetlist type="musician" bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 5:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal && isOwner) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <BookingLineup type="musician" redirectActionPromote={this.redirectPromote} redirectAction={this.redirectNotifyClient}  bookingId={this.props.match.params.id} />;
                        case 2:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote}/>;
                        case 4:
                            return <BookingSetlist type="musician" bookingId={this.props.match.params.id}/>;
                        case 5:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote}/>;
                        case 4:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote}/>;
                        case 3:
                            return <History bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                }
            }
            else {
                if (
                    currentProfile?.type === ProfileType.Musician.ordinal &&
                    (booking && booking.createdBy && booking.createdBy.type !== ProfileType.Musician.ordinal)
                ) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm readOnly={readOnly} />;
                        case 1:
                            return <BookingLineup redirectActionPromote={this.redirectPromote}  type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingSetlist type="musician" bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 4:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <BookingLineup redirectActionPromote={this.redirectPromote}  type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingSetlist type="musician" bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 4:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                } else if (currentProfile?.type === ProfileType.Agent.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <SocialPostsDetailComponent bookingId={this.props.match.params.id} />;
                        case 3:
                            return <BookingNotifyClient type="musician" bookingId={this.props.match.params.id} redirectActionPromote={this.redirectPromote}/>;
                        case 4:
                            return <History type="musician" bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    } 
                } else if(currentProfile?.type === ProfileType.PrivateHire.ordinal){
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm isPrivateHire={isPrivateHire} bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        default:
                            return "On development";
                    } 
                } else if (currentProfile?.type === ProfileType.Staff.ordinal) {
                    switch (stepIndex) {
                        case 0:
                            return <PrivateBookingForm bookingId={this.props.match.params.id} readOnly={readOnly} />;
                        case 1:
                            return <Negotiate type="musician" bookingId={this.props.match.params.id} />;
                        case 2:
                            return <History bookingId={this.props.match.params.id} />;
                        default:
                            return "On development";
                    }
                }
            }
        }
    }

    getBookingStatus = booking => {
        const { currentProfile } = this.props.profileStore;
        if (booking) {
            if (typeof booking.status === "undefined") {
                return "Draft";
            }
            if (booking.type === BookingType.Adhoc.ordinal) {
                if (booking.status === BookingStatusType.Resent.ordinal && booking.bookingRequest) {
                    return "Draft";
                }
                if (booking.status === BookingStatusType.New.ordinal && !booking.notifyClient && booking.createdById === currentProfile?.id) {
                    return "Draft";
                }
                // if (booking.status === BookingStatusType.Accepted.ordinal && !booking.notifyClient) {
                //     return "Draft";
                // }
                return BOOKING_STATUS[booking.status];
            }

            if (booking.status === BookingStatusType.Resent.ordinal && booking.bookingRequest) {
                return "Draft";
            } else {
                return BOOKING_STATUS[booking.status];
            }
        } else {
            return "Draft";
        }
    };

    getPrivateBookingTitle = () => {
        const { booking } = this.props.bookingRequestStore;
        if (booking) {
            var bookingTitle = booking.title !== null && booking.title !== undefined ? booking.title : booking.tempTitle;
            return `Entertainer Booking - ${bookingTitle} ${moment(booking.dateStart).format("DD-MM-YYYY")} ${moment(booking.timeStart).format(
                "HH:mm"
            )} - ${moment(booking.timeEnd).format("HH:mm")}`;
        } else {
            return "Entertainer Booking";
        }
    };

    render() {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { editBookingStep } = this.props.templateStore;
        const { booking, privateBookingHeading, isLoading,isPrivateHire } = this.props.bookingRequestStore;
        let { isAdmin, myLineup, cancelledBy, bookingRequests, createdById } = booking;
        let newActOwnerBooking = booking && booking.status === BookingStatusType.Resent.ordinal && bookingRequests && (bookingRequests.findIndex(x => x.act.userId === currentProfile?.userId)) >= 0;        
        let resentBooking = cancelledBy?.userId === currentProfile?.userId && bookingRequests?.length > 0 && bookingRequests[0].act.userId !== currentProfile?.userId ? true : false;
        const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;
        const isMusician = currentProfile?.type === ProfileType.Musician.ordinal;
        const steps = this.getSteps();
        // let isBookingAdmin = isAdmin || createdById === currentProfile?.id;
        if (!this.props.match.params.id) {
            if(!this.props.bookingRequestStore.limitBookingFeature)  {
                this.props.history.replace(`/`);
            }
        }
        const canClickStepper =
            booking &&
            booking.id &&
            booking.id !== 0 &&
            booking.status !== BookingStatusType.New.ordinal &&
            booking.status !== BookingStatusType.Cancelled.ordinal &&
            booking.status !== BookingStatusType.Declined.ordinal &&
            booking.status !== BookingStatusType.Resent.ordinal;
        return (
            <Fragment>
                <Header />
                <Sidebar />
                {(booking && booking.id && (!isAdmin && !myLineup && !(booking.privateHireId && booking.privateHireId === currentProfile?.id && currentProfile?.type === ProfileType.PrivateHire.ordinal)) || resentBooking) && (!newActOwnerBooking ) && !isLoading ? (
                        <MainContainer>
                            <BookingNotFound />
                        </MainContainer>
                ) : (
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title={privateBookingHeading}>
                        <div style={{ display: "inline-block" }}>
                            <BookingStatusBadge
                                cancelledBy={ (booking && booking.cancelledBy ? booking.cancelledBy.type : 0)}
                                status={this.getBookingStatus(booking)}
                            />
                        </div>
                    </Heading>
                    {currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.PrivateHire.ordinal || (booking && booking.id && booking.id !== 0) ? (
                        <Fragment>
                            {(booking && booking.id && booking.id !== 0) &&
                                <Fragment>
                                    <Hidden smDown>
                                        <Stepper activeStep={editBookingStep} alternativeLabel={false} nonLinear={true} id="stepper-private-booking">
                                            {steps?.map((label, index) => {
                                                return (
                                                    <Step key={label} disabled={!(canClickStepper || index === 0 || label === "History")}>
                                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Hidden>
                                    <Hidden mdUp xsDown>
                                        <Stepper activeStep={editBookingStep} alternativeLabel={true} nonLinear={true}>
                                            {steps?.map((label, index) => {
                                                return (
                                                    <Step key={label} disabled={!(canClickStepper || index === 0 || label === "History")}>
                                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Hidden>
                                    <Hidden smUp>
                                        <Stepper activeStep={editBookingStep} alternativeLabel={false} className="stepperXs" nonLinear={true}>
                                            {steps?.map((label, index) => {
                                                return (
                                                    <Step key={label} disabled={!(canClickStepper || index === 0 || label === "History")}>
                                                        <StepButton onClick={this.handleStepClick(index)} />
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Hidden>
                                </Fragment>
                            }
                            <Paper className="stepperContent">{this.getStepContent(editBookingStep)}</Paper>
                        </Fragment>
                    ) : currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal ? (
                        <Paper style={{ padding: "24px", backgroundColor: "#fff" }}>
                            <PrivateBookingForm />
                        </Paper>
                    ) : (
                        ""
                    )}
                </MainContainer>
                )}
            </Fragment>
        );
    }
}

export default PrivateBooking;
