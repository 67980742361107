import React, { Fragment } from "react";
import { inject } from "mobx-react";
import { Tooltip } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import InboxIcon from "@material-ui/icons/Inbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withRouter, Redirect } from "react-router-dom";
import facebook from "../../img/facebook-box.svg";
import FacebookLogin from "react-facebook-login";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

import jwtDecode from "jwt-decode";
import { ProfileType } from "../../types/enum";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

@inject("facebookStore", "profileStore", "templateStore", "commonStore")
class FacebookPageDialog extends React.Component {
    state = {
        open: false,
        openAdd: false,
        openConfirmModal: false,
        redirectToPaymentPage: false
    };

    handleFacebookShare = response => {
        if (response.status !== "unknown") {
            this.props.templateStore.showLoadingScreen();
            this.props.facebookStore.getPages(response.id, response.accessToken).then(res => {
                this.props.templateStore.hideLoadingScreen();
                this.props.facebookStore.setPages(res);
                this.setState({ open: true });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleOpenModal = () => this.setState({ openConfirmModal: true });

    handleCloseModal = () => this.setState({ openConfirmModal: false });

    handleConfirmDisconnect = () => {
        const { profile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.facebookStore.disconnectFacebookPage(profile.id).then(resp => {
            this.props.templateStore.hideLoadingScreen();
            this.props.profileStore.setFacebook(null, null, null, null);
            this.handleCloseModal();
            this.props.templateStore.openSnackbar("This profile has been disconnected to your Facebook page");
        });
    };

    handleListItemClick = page => {
        const { profile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.facebookStore.setFacebookPage(profile.id, page.id, page.access_token, page.name, page.category).then(resp => {
            this.props.templateStore.hideLoadingScreen();
            this.props.profileStore.setFacebook(page.id, page.access_token, page.name, page.category);
            if(profile.type === ProfileType.Agent.ordinal || profile.type === ProfileType.Musician.ordinal){
                this.props.profileStore.loadProfiles();
            }else if(profile.type === ProfileType.Venue.ordinal){
                this.props.refresh()
            }

            this.handleClose();
            this.props.templateStore.openSnackbar("This profile has been connected to your Facebook page");
        });
    };

    handlePayment = () => {
        this.setState({
            redirectToPaymentPage: true
        });
    };

    render() {
        if (this.state.redirectToPaymentPage) return <Redirect to="../../../billing/payment" />;
        const { pages } = this.props.facebookStore;
        const { profile, currentProfile } = this.props.profileStore;
        const { token } = this.props.commonStore;
        let hasSubscribe = false;
        if (token && currentProfile && jwtDecode(token).scope.includes(x => x === currentProfile.typeString).length > 0) hasSubscribe = true;

        if (!ENABLE_SUBSCRIPTION) {
            hasSubscribe = true;
        }
        return (
            <Fragment>
                <Tooltip title={hasSubscribe ? "Share to your Facebook Page" : "Upgrade to enable Facebook integration"}>
                    {profile && profile.facebookPageId ? (
                        <Button className="fb-share" onClick={this.handleOpenModal}>
                            <img src={facebook} alt="fb" className="logo-fb" />&nbsp;&nbsp;{profile.facebookAccessToken ? `Connected to ${profile.facebookPageName}` : "Reconnect your Facebook account"}
                        </Button>
                    ) : !hasSubscribe ? (
                        <Button className="fb-share-disabled" onClick={this.handlePayment}>
                            <img src={facebook} alt="fb" className="logo-fb" />&nbsp;&nbsp;CONNECT TO FACEBOOK
                        </Button>
                    ) : (
                        <div>
                        <FacebookLogin
                            appId={FACEBOOK_APP_ID.toString()}
                            callback={this.handleFacebookShare.bind(this)}
                            scope="pages_show_list, pages_read_engagement, pages_manage_posts, public_profile,publish_stream ,business_management"
                            cssClass="fb-share-social"
                            textButton="&nbsp;&nbsp; CONNECT TO FACEBOOK"
                            icon={<img src={facebook} alt="fb" className="logo-fb" />}
                        />
                        </div>
                    )}
                </Tooltip>

                <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open && !this.props.isMYOBRedirect} className="dialog-div" color="primary">
                    <DialogTitle className="facebook-dialog-title"><span className="facebook-dialog-text-title">Select the Facebook Page to Connect to HG</span></DialogTitle>
                    <div>
                        <List>
                            {pages.map(page => (
                                <ListItem button onClick={() => this.handleListItemClick(page)} key={page.id}>
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={page.name} secondary={page.category} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Dialog>

                <ConfirmationModal
                    open={this.state.openConfirmModal}
                    message="Disconnect this facebook page account?"
                    closeHandler={this.handleCloseModal}
                    confirmationHandler={this.handleConfirmDisconnect}
                    declineHandler={this.handleCloseModal}
                    confirmationLabel="Disconnect Facebook"
                    maxWidth="sm"
                />
            </Fragment>
        );
    }
}

export default withMobileDialog()(withRouter(FacebookPageDialog));
