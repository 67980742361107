import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, Grid, Typography, ListItem} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "../HgBilling.css";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter, Link } from "react-router-dom";
import { hgStripe } from "../StripeConfig.js";


const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("userStore", "profileStore",  "hgBillingStore")
@withRouter
@observer
class VenueGroupBilling extends Component{
    constructor(props){
        super(props);
    };


    state = {

    };

    selectPlan = (value,type, isReturn) => {
        if(isReturn) return;
        this.props.selectPlan(value,type);
    }


    render(){
        const {billingAccess} = this.props.hgBillingStore
        var isProPlan = billingAccess?.isProPlan
        var isFreePlan = !billingAccess ? true:false || billingAccess?.isFreePlan
        var isBasicPlan = billingAccess?.isBasicPlan
        var currentProfile = this.props.profileStore.getCurrentProfile();
        return (
           <Fragment>
                    <Grid container className="billingHg">
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isFreePlan? "pink-background" : ""}>
                                <th>Free</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                    <ul className="billingBullet">
                                        <li>Create & manage your venue profile</li>
                                        <li>Up to 8 bookings per month</li>
                                        <li>Unlimited staff with role-based permissions</li>
                                        <li>Act roster management incl. venue favourites</li>
                                        <li>Act availability visibility</li>
                                        <li>Integrated worksheets & contracts</li>
                                        <li>Cancellation workflows</li>
                                        <li>Automated artist invoicing</li>
                                        <li>Spend vs budget reporting</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                            <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            POA
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:"center"}}>
                                            <Button className={isFreePlan ? "buttonHgBilling" : ""}  style={{height:30,width:120, fontSize:8, borderRadius:20}} variant="contained" color="primary" 
                                            onClick={() => window.open(`mailto:sales@hotgiggity.com?subject=${encodeURIComponent("Downgrade to Free Plan")}`, '_self')}>
                                                {isFreePlan ? "Current Plan" : "Contact HG Sales"}
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                               
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isBasicPlan ? "pink-background" : ""}>
                                <th>Essentials</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Free plus:
                                    <ul className="billingBullet">
                                        <li>Unlimited bookings</li>
                                        <li>Invite agents to manage your entertainment</li>
                                        <li>Automated gig promotion - website & screens</li>
                                        <li>Social post management for both live events and general social posts </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            POA
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:"center"}}>
                                            <Button className={isBasicPlan ? "buttonHgBilling" : ""}  style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" 
                                            onClick={() => window.open(`mailto:sales@hotgiggity.com?subject=${encodeURIComponent("Upgrade to Essential Plan")}`, '_self')}>
                                                {isBasicPlan ? "Current Plan" : "Contact HG Sales"}
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <thead className={ isProPlan ? "pink-background" : ""}>
                            <tr >
                                <th>Pro</th>
                            </tr>
                            </thead>
                           
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Essentials plus:
                                    <ul className="billingBullet">
                                        <li>Accounting Package Integrations including XERO & MYOB (Quickbooks coming soon)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            POA
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:"center"}}>
                                            <Button className={isProPlan ? "buttonHgBilling" : ""}  style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" 
                                            onClick={() => window.open(`mailto:sales@hotgiggity.com?subject=${encodeURIComponent("Upgrade to Pro Plan")}`, '_self')}>
                                                {isProPlan ? "Current Plan" : "Contact HG Sales"}
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={3} className="billingHgCell">
                        {/* <table className="hgBillingTable">
                            <tr>
                                <th>Pro Plus</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Pro plus:
                                    <ul className="billingBullet">
                                        <li>Auto Schedule to book any time period of entertainment with one click. (coming soon)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $25 per gig
                                        </td>
                                        <td>
                                            $189 per gig
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(5)}>
                                                Select Plan
                                            </Button>
                                        </td>
                                        <td>
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(6)}>
                                                Select Plan
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table> */}
                        </Grid>
                    </Grid>
           </Fragment>
        );
    }
}

export default withStyles(styles) (VenueGroupBilling);
